import { Tabs } from 'antd';
import { styled, css } from 'styled-components';

import { TTabVariant } from './types';

const cssDefaultVariant = css`
    .ant-tabs-nav {
        &::before {
            border-bottom: 1px solid #fff;
            opacity: 0.4;
        }
    }

    .ant-tabs-ink-bar {
        border-bottom: 2px solid #fff;
    }

    .ant-tabs-tab {
        > div {
            font-weight: 400;
            font-size: 16px;
            line-height: 20px;
            color: #fff !important;
        }

        &.ant-tabs-tab-active {
            pointer-events: none;
            border-bottom: 2px solid #fff;

            > div {
                font-weight: 500;
                font-size: 20px;
                line-height: 24px;
                color: #fff !important;
            }
        }

        .ant-tabs-tab-btn {
            white-space: pre-wrap;
            text-align: center;
        }
    }
`;

const cssGreenVariant = css`
    .ant-tabs-nav {
        &::before {
            border-bottom: none;
        }
    }

    .ant-tabs-tab-active {
        border-bottom: 2px solid ${({ theme }) => theme.color.primary};
        padding: 0 4px;
    }
`;

export const Wrapper = styled(Tabs)<{ variant: TTabVariant }>`
    ${(props) => {
        if (props.variant === 'DEFAULT') {
            return cssDefaultVariant;
        }
        if (props.variant === 'GREEN') {
            return cssGreenVariant;
        }
    }};
`;
