import { MessageFormatElement } from '@formatjs/icu-messageformat-parser';
import { ConfigProvider } from 'antd';
import type { AppProps } from 'next/app';
import { useRouter } from 'next/router';
import React, { useEffect, useMemo } from 'react';
import { IntlProvider } from 'react-intl';
import { ThemeProvider } from 'styled-components';

import { wrapperReduxToolkit } from '@/store';
import { GlobalStyle } from '@/styles/global.styled';
import { theme, fontFamily } from '@/styles/theme';

import { useUtmParams } from '@/shared/hooks/use-utm-params';

import { ModalsModule } from './components/modals';
import { RouteChangeSpinner } from './components/route-change-spinner';
import LangKaz from './locales/kk/common.json';
import LangRus from './locales/ru/common.json';

const MyApp = (props: AppProps) => {
    const { Component, pageProps } = props;

    const { defaultLocale, locale } = useRouter();

    const { setUtmParams } = useUtmParams();

    const messages = useMemo(() => {
        switch (locale) {
            case 'ru':
                return LangRus;
            case 'kk':
                return LangKaz;
            default:
                return LangRus;
        }
    }, [locale]);

    useEffect(() => {
        setUtmParams();
    }, []);

    return (
        <ThemeProvider theme={theme}>
            <ConfigProvider
                theme={{
                    token: {
                        colorPrimary: theme.color.primary,
                        fontFamily,
                    },
                }}
            >
                <GlobalStyle />
                <RouteChangeSpinner />

                <IntlProvider
                    defaultLocale={defaultLocale}
                    locale={locale as string}
                    messages={messages as Record<string, string> | Record<string, MessageFormatElement[]>}
                    /*
                     * TODO: добавить логирование ошибок переводов
                     *  после соответствующих доработок API
                     */
                    onError={() => null}
                >
                    <Component {...pageProps} />
                    <ModalsModule />
                </IntlProvider>
            </ConfigProvider>
        </ThemeProvider>
    );
};

export const App = wrapperReduxToolkit.withRedux(MyApp);
