type TRemovePropsFromObject<T> = {
    obj: T;
    props: string[];
};

export const removePropsFromObject = <T extends Record<string, unknown>>(p: TRemovePropsFromObject<T>): T => {
    const { obj, props } = p;

    if (!obj || !props) {
        return {} as T;
    }

    const target = {} as typeof obj;

    for (const i in obj) {
        if (props.indexOf(i) >= 0) {
            continue;
        }

        if (!Object.prototype.hasOwnProperty.call(obj, i)) {
            continue;
        }

        target[i] = obj[i];
    }
    return target;
};
