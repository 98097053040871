import cn from 'classnames';
import Image from 'next/image';
import React, { FC } from 'react';

import { useCalculationResultOverpaymentCalculator } from '../../hooks/use-calculation-result-overpayment-calculator';

import * as Styled from './styled';

type TProps = {
    hasMobile?: boolean;
};

export const CalculationInfo: FC<TProps> = ({ hasMobile }) => {
    const { titleLeft, titleLeftSub, titleRight, titleRightSub } = useCalculationResultOverpaymentCalculator();

    return (
        <Styled.Wrapper className={cn({ mobile: hasMobile })}>
            {titleLeft && (
                <>
                    <div className="left-block">
                        <Image alt="firework image" height={37} src="/icons/firework.png" width={36} />

                        <div className="text">
                            <p> {titleLeft} </p>
                            <p>{titleLeftSub}</p>
                        </div>
                    </div>
                </>
            )}

            <div className="right-block">
                <p>{titleRight}</p>

                <p>{titleRightSub}</p>
            </div>
        </Styled.Wrapper>
    );
};
