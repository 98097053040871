import React from 'react';

import { TSvgIcon } from '@/shared/types/types';

const CalendarIcon: TSvgIcon = (props) => {
    const { height = 28, width = 28 } = props;

    return (
        <svg fill="none" height={height} viewBox="0 0 28 28" width={width} xmlns="http://www.w3.org/2000/svg">
            <rect fill="#42CE99" fillOpacity="0.1" height={height} rx="4" width={width} />

            <path
                d="M17.5556 5V8.6M10.4444 5V8.6M6 12.2H22M7.77778 6.8H20.2222C21.2041 6.8 22 7.60589 22 8.6V21.2C22 22.1941 21.2041 23 20.2222 23H7.77778C6.79594 23 6 22.1941 6 21.2V8.6C6 7.60589 6.79594 6.8 7.77778 6.8Z"
                stroke="#14AE51"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="2"
            />
        </svg>
    );
};

export default CalendarIcon;
