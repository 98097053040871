import React, { FC, ReactElement, useEffect } from 'react';

import { TSizeModal, TTypeModal } from '@/store/reducers/modal/types';

import { CloseIcon } from '@/shared/icons/close-icon';

import * as Styled from './styled';

type TProps = {
    content: ReactElement;
    onClose: () => void;
    sizeModal: TSizeModal;
    title: string | ReactElement | null;
    type: TTypeModal;
};

export const ModalContent: FC<TProps> = (props) => {
    const { content, onClose, sizeModal, title, type } = props;

    useEffect(() => {
        document.body.style.overflowY = 'hidden';

        return () => {
            document.body.style.overflowY = 'auto';
        };
    }, []);

    if (type === 'SideBar') {
        return (
            <>
                <Styled.OverlayWrapper onClick={onClose} />

                <Styled.SideBarWrapper>
                    <Styled.SideBarHeadWrapper>
                        <div>{title}</div>

                        <div>
                            <div onClick={onClose}>
                                <CloseIcon color="gray" height={20} width={20} />
                            </div>
                        </div>
                    </Styled.SideBarHeadWrapper>
                    <Styled.SideBarContentWrapper>{content}</Styled.SideBarContentWrapper>
                </Styled.SideBarWrapper>
            </>
        );
    }

    return (
        <>
            <Styled.OverlayWrapper onClick={onClose} />

            <Styled.ModalWrapper $sizeModal={sizeModal} $type={type}>
                <Styled.ModalTitleWrapper type={type}>
                    <div>{title}</div>

                    <div>
                        <div onClick={onClose}>
                            <CloseIcon color="gray" height={20} width={20} />
                        </div>
                    </div>
                </Styled.ModalTitleWrapper>

                <Styled.ModalContentWrapper>{content}</Styled.ModalContentWrapper>
            </Styled.ModalWrapper>
        </>
    );
};
