/**
 *  Cookies.get('key'); or  Cookies.get('key', { path: '/path', domain: '.yourdomain.com' }) -- client side
 *  Cookies.get('key', { req, res }); or  Cookies.get('key', { req, res, path: '/path', domain: '.yourdomain.com' }) - server side
 *
 *  Cookies.set('key', 'value'); or Cookies.set('key', 'value', { path: '/path', domain: '.yourdomain.com' }) - client side
 *  Cookies.se('key', 'value', { req, res }); or Cookies.se('key', 'value', { req, res, path: '/path', domain: '.yourdomain.com' }) - server side
 *
 *  Cookies.remove('key'); - client side
 *  Cookies.remove('key', { req, res }); - server side
 *
 *  Cookies.has('key); - client side
 *  Cookies.has('key', { req, res }); - server side
 */
import { getCookie, hasCookie, deleteCookie, setCookie } from 'cookies-next';
import { OptionsType } from 'cookies-next/lib/types';

import { customCaptureException } from '@/shared/lib/custom-capture-exception';
import { isJSON } from '@/shared/lib/json';

export const Cookies = {
    get: <T>(key: string, options?: OptionsType | undefined): T => {
        const value = getCookie(key, options);
        return isJSON(value as string) ? JSON.parse(value as string) : value;
    },

    has: (key: string, options?: OptionsType | undefined): boolean => {
        return hasCookie(key, options);
    },

    remove: (key: string | string[], options?: OptionsType | undefined): void => {
        try {
            const keys = Array.isArray(key) ? key : [key];
            keys.forEach((k) => deleteCookie(k, options));
        } catch (err) {
            customCaptureException(err, 'Cookies');
        }
    },
    // eslint-disable-next-line max-params
    set: <T>(key: string, value: T, options?: OptionsType | undefined): void => {
        setCookie(key, JSON.stringify(value), options);
    },
};
