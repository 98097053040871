import { styled } from 'styled-components';

import { mq } from '@/styles/media.styled';

export const Wrapper = styled.div`
    width: 100%;
    margin-top: 108px;

    .map-section-title {
        font-weight: 500;
        font-size: ${({ theme }) => theme.fontSize.xl};
        line-height: 120%;
    }

    .map-section {
        width: 100%;
        height: 100%;
        margin-top: 32px;
    }

    ${mq.lt.md} {
        margin-top: 59px;
    }
`;
