import React, { useEffect, useState } from 'react';

import { useAppDispatch, useAppSelector } from '@/store';

import TengeIcon from '@/shared/icons/tenge-icon';
import { formatCurrency } from '@/shared/lib/currency';

import { EPawnshopCategoryType } from '../../constants';
import { overpaymentCalculator } from '../../lib/overpayment-calculator';
import { setSelectedAmountOverpaymentCalculator } from '../../store/slice';
import { SliderInput } from '../slider-input';

type TProps = {
    type: EPawnshopCategoryType;
};

export const SumInput: React.FC<TProps> = () => {
    const { data: dataCalculator, products } = useAppSelector((state) => state.estimateForm.overpaymentCalculator);

    const [inputValue, setInputValue] = useState(`${formatCurrency(dataCalculator.selectAmount)} ₸`);
    const dispatch = useAppDispatch();

    const handleChange = (value: string | number) => {
        const _value = overpaymentCalculator.prepareSelectedAmount(+value, products);
        setInputValue(`${formatCurrency(_value)}`);
        dispatch(setSelectedAmountOverpaymentCalculator(_value));
    };
    useEffect(() => {
        setInputValue(`${formatCurrency(dataCalculator.selectAmount)}`);
    }, [dataCalculator.selectAmount]);

    return (
        <SliderInput
            addonMaxValue="₸"
            addonMinValue="₸"
            icon={<TengeIcon />}
            inputValue={inputValue}
            label="Сумма"
            maxValue={dataCalculator.maxAmount}
            minValue={dataCalculator.minAmount}
            onChangeInput={handleChange}
            onChangeSlider={handleChange}
            sliderValue={dataCalculator.selectAmount}
            step={dataCalculator.stepAmount}
        />
    );
};
