import { DefaultTheme } from 'styled-components';

export const fontFamily = "'Roboto', sans-serif";

export const theme: DefaultTheme = {
    background: { mainBackground: '#F6F8FAFF' },
    color: {
        accent: '#FD397A',
        dark: '#1F2547',
        disabled: '#d3d5db',
        error: '#FD397A',
        grey: '#818C99',
        neutral100: '#F5F5F5',
        neutral200: '#F6F8FA',
        neutral300: '#A9ADBC',
        neutral400: '#ECECF0',
        neutral500: '#818C99',
        neutral600: '#5f6670',
        primary: '#14AE51',
        secondary: '#FFFFFF',
    },
    fontSize: {
        lg: '1.25rem',
        md: '1rem',
        sm: '0.875rem',
        xl: '1.5rem',
        xs: '0.75rem',
        xxl: '2rem',
        xxxl: '2.625rem',
        xxxxl: '3.25rem',
    },
};
