import { styled } from 'styled-components';

import { mq } from '@/styles/media.styled';

export const Wrapper = styled.div`
    .title {
        font-weight: 500;
        font-size: 16px;
        line-height: 120%;
        color: #333;
        margin-bottom: 8px;
    }

    ${mq.lt.md} {
        height: 377px;
        overflow: auto;
    }
`;

export const StyledOfficeListItemBlockWrapper = styled.div`
    display: flex;
    flex-direction: column;
    gap: 12px;

    .item {
        .work,
        .link,
        .name {
            font-style: normal;
            font-weight: 400;
            line-height: 120%;
        }

        .name {
            cursor: pointer;
            font-size: 14px;
            color: #14ae51;
        }

        .work {
            width: 132px;
            font-size: 12px;
            color: #333;
        }

        .link {
            cursor: pointer;
            margin-top: 8px;
            font-size: 12px;
            text-decoration-line: underline;
            color: #14ae51;
        }
    }
`;
