import { ComponentProps, FC, ReactElement } from 'react';

import * as Styled from './styled';
import { TStyledCheckboxSizeElement } from './types';

type TProps = Omit<ComponentProps<'input'>, 'size'> & {
    disabled?: boolean;
    isReversLabel?: boolean;
    label: string | ReactElement;
    name: string;
    size?: TStyledCheckboxSizeElement;
};

export const Checkbox: FC<TProps> = ({
    checked,
    disabled = false,
    // isReversLabel = false,
    label,
    name,
    onChange,
    size = 'md',
}) => {
    return (
        <Styled.Wrapper size={size}>
            <div>
                <input
                    autoComplete="off"
                    checked={checked}
                    disabled={disabled}
                    id={name}
                    name={name}
                    onChange={onChange}
                    type="checkbox"
                />
                <label className="checkmark" htmlFor={name} />
            </div>

            <div>
                <label htmlFor={name}>{label}</label>
            </div>
        </Styled.Wrapper>
    );
};
