import { TabsProps } from 'antd';
import React, { FC } from 'react';

import * as Styled from './styled';
import { TTabVariant } from './types';

export type { TTabItems } from './types';

type TProps = TabsProps & {
    variant?: TTabVariant;
};

/**
 * Важно:
 *
 * При использовании табов пушим значение таба в url query и контролируем состояние табов именно через url query,
 * а уже затем дублируем в useState - это откроет дополнительные возможности для SEO
 */
export const TabsAntd: FC<TProps> = (props) => {
    const { centered = false, defaultActiveKey = '1', tabPosition = 'top', variant = 'DEFAULT', ...rest } = props;

    return (
        <Styled.Wrapper
            animated={false}
            centered={centered}
            defaultActiveKey={defaultActiveKey}
            tabPosition={tabPosition}
            variant={variant}
            {...rest}
        />
    );
};
