import { AnyAction, configureStore } from '@reduxjs/toolkit';
import { createWrapper, HYDRATE } from 'next-redux-wrapper';

import { loadPawnshopCategories } from '@/features/estimate-form';

import { TLangSite } from '@/shared/constants/settings';

import { rootReducer } from './reducers';
import { loadSettings } from './reducers/settings-site/action-creators';
import { TSettingsSiteState } from './reducers/settings-site/types';

/* eslint-disable-next-line @typescript-eslint/no-explicit-any */
const reducer = (state: any, action: AnyAction) => {
    if (action.type === HYDRATE) {
        return {
            ...state,
            ...action.payload,
        };
    }

    return rootReducer(state, action);
};
const storeForType = configureStore({ reducer: rootReducer });

export const initStore = configureStore({
    devTools: true,
    middleware: (getDefaultMiddleware) => [...getDefaultMiddleware({ serializableCheck: false })],
    reducer,
});

/* eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types */
export const makeStore = () => initStore;

export type TRootStateType = ReturnType<typeof storeForType.getState>;

export type TStore = ReturnType<typeof makeStore>;

export type TRootState = ReturnType<TStore['getState']>;

export type TAppDispatch = TStore['dispatch'];

export const wrapperReduxToolkit = createWrapper(makeStore, { debug: false });

/**
 * Загрузка основных настроек сайта
 */

type TReturnLoadSettings = {
    settings: TSettingsSiteState;
};

// eslint-disable-next-line max-params
export const loadDefaultSettingsSite = async (
    getState: TRootState,
    dispatch: TAppDispatch,
    lang: TLangSite,
): Promise<TReturnLoadSettings> => {
    if (typeof window === 'undefined') {
        await dispatch(loadSettings(lang));
        await dispatch(loadPawnshopCategories());
    }

    if (!getState().settings.loaded) {
        await dispatch(loadSettings(lang));
    }
    if (!getState().estimateForm.loaded) {
        await dispatch(loadPawnshopCategories());
    }

    return { settings: getState().settings };
};
