import React, { FC, useState } from 'react';

import { useTranslate } from '@/shared/hooks/use-translate';
import { HideContentByBreakPoint } from '@/shared/ui/hide-by-breakpoint';
import { ShowContentByBreakPoint } from '@/shared/ui/show-by-breakpoint';
import { TabsAntd, TTabItems } from '@/shared/ui/tabs-antd';

import { TOffice } from '../../types';
import { OfficeList } from '../office-list';
import { OfficeMap } from '../office-map';

import * as Styled from './styled';

type TProps = {
    center?: TCoordinates;
    offices: TOffice[];
    zoom?: number;
};

export const OfficeTab: FC<TProps> = (props) => {
    const { center, offices, zoom } = props;
    const [activeTab, setActiveTab] = useState('map');
    const [selectPointMap, setSelectPointMap] = useState<TCoordinates>(center as TCoordinates);
    const [selectZoomMap, setSelectZoomMap] = useState(zoom as number);
    const { t } = useTranslate();

    const handleSelectPointMap = (coordinates: TCoordinates) => {
        setSelectPointMap(coordinates);
        setSelectZoomMap(15);
        setActiveTab('map');
    };

    const itemsTabs: TTabItems = [
        {
            children: <OfficeMap offices={offices} selectPointMap={selectPointMap} zoom={selectZoomMap} />,
            key: 'map',
            label: t('На карте'),
        },
        {
            children: <OfficeList offices={offices} onSelectPointMap={handleSelectPointMap} />,
            key: 'list',
            label: t('Списком'),
        },
    ];

    return (
        <Styled.Wrapper>
            <HideContentByBreakPoint showIf="lg">
                <div className="desktop-map">
                    <OfficeMap offices={offices} selectPointMap={selectPointMap} zoom={selectZoomMap} />
                    <OfficeList offices={offices} onSelectPointMap={handleSelectPointMap} />
                </div>
            </HideContentByBreakPoint>
            <ShowContentByBreakPoint showIf="lg">
                <div className="mobile-map">
                    <TabsAntd
                        activeKey={activeTab}
                        items={itemsTabs}
                        onChange={(active) => setActiveTab(active)}
                        variant="GREEN"
                    />
                </div>
            </ShowContentByBreakPoint>
        </Styled.Wrapper>
    );
};
