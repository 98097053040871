import { AxiosInstance } from 'axios';

import { axiosInstance } from '../axios/axios-instance';
import { TokenService, onResponseError, onResponseSuccess } from '../token-service/token-service';
import { TAxiosClient, TResponseAndRequest } from '../types';

export class AxiosBase implements TAxiosClient {
    public readonly axiosInstance: AxiosInstance;

    constructor(baseUrl: string) {
        this.axiosInstance = axiosInstance(baseUrl);
    }
    use(): AxiosInstance {
        return this.axiosInstance;
    }

    setInterceptors(context?: TResponseAndRequest): void {
        this.axiosInstance.interceptors.request.clear();
        this.axiosInstance.interceptors.response.clear();

        this.axiosInstance.interceptors.request.use((config) => {
            const token = TokenService?.getAccessToken(context);
            if (config.headers && token) {
                config.headers.Authorization = `Bearer ${token}`;
            }
            return config;
        });
        this.axiosInstance.interceptors.response.use(
            onResponseSuccess,
            onResponseError(this.axiosInstance, {
                req: context?.req,
                res: context?.res,
            }),
        );
    }
}
