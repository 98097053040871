export const mq = {
    //Больше или равна
    gt: {
        lg: '@media screen and (min-width: 1200px)',
        md: '@media screen and (min-width: 992px)',
        sm: '@media screen and (min-width: 768px)',
        xl: '@media screen and (min-width: 1441px)',
        xs: '@media screen and (min-width: 576px)',
    },

    lg: '@media screen and (min-width: 993px) and (max-width: 1200px)',

    //Меньше или равна
    lt: {
        lg: '@media screen and (max-width: 991px)',
        md: '@media screen and (max-width: 767px)',
        sm: '@media screen and (max-width: 575px)',
        xl: '@media screen and (max-width: 1199px)',
        xs: '@media screen and (max-width: 479px)',
        xxl: '@media screen and (max-width: 1280px)',
        xxxl: '@media screen and (max-width: 1440px)',
    },

    md: '@media screen and (min-width: 769px) and (max-width: 992px)',

    sm: '@media screen and (min-width: 577px) and (max-width: 768px)',

    xl: '@media screen and (min-width: 1201px) and (max-width: 1600px)',

    xs: '@media screen and (max-width: 576px)',

    xxl: '@media screen and (min-width: 1601px) and (max-width: 5000px)',
};
