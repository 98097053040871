import React, { useEffect, useState } from 'react';

import { useAppDispatch, useAppSelector } from '@/store';

import { useTranslate } from '@/shared/hooks/use-translate';
import CalendarIcon from '@/shared/icons/calendar-icon';
import { formatCurrency } from '@/shared/lib/currency';

import { EPawnshopCategoryType } from '../../constants';
import { overpaymentCalculator } from '../../lib/overpayment-calculator';
import { setSelectedTermOverpaymentCalculator } from '../../store/slice';
import { SliderInput } from '../slider-input';

type TProps = {
    type: EPawnshopCategoryType;
};

export const TermInput: React.FC<TProps> = () => {
    const { data: dataCalculator } = useAppSelector((state) => state.estimateForm.overpaymentCalculator);
    const { t } = useTranslate();
    const dispatch = useAppDispatch();

    const [inputValue, setInputValue] = useState(
        `${formatCurrency(dataCalculator.minTerm)} ${overpaymentCalculator.numWordTerm(dataCalculator.minTerm)}`,
    );

    const handleChange = (value: number | string) => {
        setInputValue(`${formatCurrency(value)} ${overpaymentCalculator.numWordTerm(+value)}`);
        dispatch(setSelectedTermOverpaymentCalculator(+value));
    };

    useEffect(() => {
        setInputValue(
            `${formatCurrency(dataCalculator.selectTerm)} ${overpaymentCalculator.numWordTerm(
                dataCalculator.selectTerm,
            )}`,
        );
    }, [dataCalculator.selectTerm]);

    return (
        <SliderInput
            addonMaxValue={t(overpaymentCalculator.numWordTerm(dataCalculator.maxTerm))}
            addonMinValue={t(overpaymentCalculator.numWordTerm(dataCalculator.minTerm))}
            icon={<CalendarIcon />}
            inputValue={inputValue}
            label="Срок"
            maxValue={dataCalculator.maxTerm}
            minValue={dataCalculator.minTerm}
            onChangeInput={handleChange}
            onChangeSlider={handleChange}
            sliderValue={dataCalculator.selectTerm}
            step={dataCalculator.stepTerm}
        />
    );
};
