import { Row, Col, Space, Typography } from 'antd';
import React, { FC, LegacyRef } from 'react';
import { useTheme } from 'styled-components';

import { ResultWrapper } from '@/features/estimate-form';

import { useTranslate } from '@/shared/hooks/use-translate';
import { Spinner } from '@/shared/ui/spinner';

import { SPACE_STYLE_COMMON, textHelpResultEstimate, EPawnshopCategoryType } from '../../constants';

const { Paragraph } = Typography;

type TProps = {
    myRef: LegacyRef<HTMLDivElement>;
    type: EPawnshopCategoryType;
};

export const ResultLoading: FC<TProps> = ({ myRef, type }) => {
    const { t } = useTranslate();
    const theme = useTheme();

    return (
        <div ref={myRef}>
            <Space direction="vertical" size="large" style={SPACE_STYLE_COMMON}>
                <Typography.Title
                    level={3}
                    style={{
                        color: theme.color.grey,
                        fontSize: '24px',
                        fontWeight: 500,
                        margin: 0,
                    }}
                >
                    {t(textHelpResultEstimate[type as EPawnshopCategoryType]?.titleLoading)}
                </Typography.Title>

                <ResultWrapper>
                    <Row className="CollateralValuationCalcResultLoading" justify="center">
                        <Col>
                            <Space direction="vertical">
                                <Paragraph className="loading-message">
                                    {t('Это займет не больше 10 секунд 🤗')}
                                </Paragraph>
                                <Spinner />
                            </Space>
                        </Col>
                    </Row>
                </ResultWrapper>
            </Space>
        </div>
    );
};
