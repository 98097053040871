import { Row, Space, Typography } from 'antd';
import { useRouter } from 'next/router';
import React, { FC, LegacyRef } from 'react';

import { useAppDispatch } from '@/store';

import { useTranslate } from '@/shared/hooks/use-translate';
import { LinkBrandButton } from '@/shared/ui/link-brand-button';

import {
    MAIN_PAGE_CALC_SECTION_ID,
    SPACE_STYLE_COMMON,
    ECalculationEstimateStatus,
    ESubmitStatusEstimateForm,
} from '../../constants';
import { setStatusEstimateForm, setSubmitStatusEstimateForm } from '../../store/slice';
import { ResultWrapper } from '../result-wrapper';

const { Paragraph } = Typography;

type TProps = {
    myRef: LegacyRef<HTMLDivElement>;
};

export const ResultError: FC<TProps> = ({ myRef }) => {
    const { t } = useTranslate();
    const router = useRouter();
    const dispatch = useAppDispatch();
    const hideBlockError = () => {
        if (router.pathname !== '/') {
            router.push('/#office-map');
        }

        // задержка на скрытие блока, чтобы при скроле к якорю
        // не происходил прыжок на высоту этого скрываемого блока
        setTimeout(() => {
            dispatch(setSubmitStatusEstimateForm(ESubmitStatusEstimateForm.await));
            dispatch(setStatusEstimateForm(ECalculationEstimateStatus.EMPTY));
        }, 1000);
    };

    return (
        <div ref={myRef}>
            <Space direction="vertical" size="large" style={SPACE_STYLE_COMMON}>
                <ResultWrapper>
                    <Row className="CollateralValuationCalcResultError" justify="center">
                        <Space direction="vertical" size="large">
                            <Space align="center" direction="vertical">
                                <Typography.Title
                                    level={4}
                                    style={{
                                        margin: 0,
                                        textAlign: 'center',
                                    }}
                                >
                                    {t('Произошла ошибка...')}
                                </Typography.Title>

                                <Paragraph className="error-message">
                                    {t('Мы уже ей занимаемся,\n а Вы можете попробовать еще раз')}
                                </Paragraph>
                            </Space>

                            <div className="button">
                                <LinkBrandButton
                                    duration={500}
                                    offset={-100}
                                    onClick={hideBlockError}
                                    smooth={true}
                                    spy={true}
                                    to={MAIN_PAGE_CALC_SECTION_ID}
                                >
                                    <span className="brand-button-link-title">{t('Попробовать еще раз')}</span>
                                </LinkBrandButton>
                            </div>
                        </Space>
                    </Row>
                </ResultWrapper>
            </Space>
        </div>
    );
};
