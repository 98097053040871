import React, { FC } from 'react';
import { Element } from 'react-scroll';

import { useAppSelector } from '@/store';

import { DEFAULT_COORDINATES, MAIN_PAGE_MAP_BRANCH_SECTION_ID } from '@/shared/constants/settings';
import { useTranslate } from '@/shared/hooks/use-translate';

import { OfficeTab } from '../office-tab';

import * as Styled from './styled';

export const DepartmentsInfoBlock: FC = () => {
    const offices = useAppSelector((state) => state.settings.offices);
    const { t } = useTranslate();

    return (
        <Styled.Wrapper>
            <Element
                className="map-section-title"
                id={MAIN_PAGE_MAP_BRANCH_SECTION_ID}
                name={MAIN_PAGE_MAP_BRANCH_SECTION_ID}
            >
                {t('Наши отделения в Казахстане')}
            </Element>

            <div className="map-section">
                <OfficeTab center={DEFAULT_COORDINATES} offices={offices} />
            </div>
        </Styled.Wrapper>
    );
};
