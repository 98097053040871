import { styled, keyframes } from 'styled-components';

const animation = keyframes`
    0% {
        stroke-dasharray: 1 98;
        stroke-dashoffset: -105;
    }
    50% {
        stroke-dasharray: 80 10;
        stroke-dashoffset: -160;
    }
    100% {
        stroke-dasharray: 1 98;
        stroke-dashoffset: -300;
    }
    `;

export const Wrapper = styled.div`
    width: 150px;
    height: 150px;

    #spinner {
        transform-origin: center;
        animation-name: ${animation};
        animation-duration: 1.2s;
        animation-timing-function: cubic-bezier();
        animation-iteration-count: infinite;
    }
`;
