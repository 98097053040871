import { styled } from 'styled-components';

import { mq } from '@/styles/media.styled';

export const Wrapper = styled.div`
    .desktop-map {
        width: 100%;
        position: relative;
        display: flex;
        background: #fff;
        box-shadow: 0 4px 12px rgb(0 0 0 / 8%);
        border-radius: 12px;

        /* stylelint-disable selector-type-no-unknown */
        ymaps {
            border-radius: 12px 0 0 12px;
        }

        .office-list-block {
            background-color: #fff;
            padding: 24px;
            width: 415px;
            min-width: 415px;
            height: 582px;
            overflow: auto;
            border-radius: 0 12px 12px 0;
            ${mq.lt.xl} {
                width: 310px;
                min-width: 310px;
            }
        }
    }

    .mobile-map {
        background: #fff;
        box-shadow: 0 4px 12px rgb(0 0 0 / 8%);
        border-radius: 12px;
        width: 100%;
        position: relative;

        .ant-tabs-nav {
            padding-left: 16px;
            margin-bottom: 0;
        }

        .office-list-block {
            padding-left: 12px;
            padding-right: 12px;
            margin-top: 23px;
        }
    }
`;
