import React, { FC } from 'react';

import { useTranslate } from '@/shared/hooks/use-translate';

import { TOffice } from '../../types';

import * as Styled from './styled';

type TProps = {
    items: TOffice[];
    onSelectPointMap: (coordinates: TCoordinates) => void;
};

export const OfficeListItem: FC<TProps> = ({ items, onSelectPointMap }) => {
    const { t } = useTranslate();

    return (
        <Styled.Wrapper>
            {items.map((item, index) => {
                return (
                    <div className="item" key={`${index}-${item.city}`}>
                        <div className="name" onClick={() => onSelectPointMap(item.location)}>
                            {item.address}
                        </div>

                        <div className="work">{item.workTime}</div>

                        <div className="link" onClick={() => onSelectPointMap(item.location)}>
                            {t('Показать на карте')}
                        </div>
                    </div>
                );
            })}
        </Styled.Wrapper>
    );
};
