import { AxiosRequestConfig } from 'axios';

import { querify } from '@/shared/lib/querify';

import { apiService } from './api-service';
import { TAxiosClient, TResponseAndRequest } from './types';

export type TReadParams<P> = {
    baseURL?: string;
    headers?: { [key: string]: string };
    params?: P & TWithOrder;
};

export type TReadParamsProperties = {
    [key: string]:
        | string[]
        | string
        | number
        | boolean
        | undefined
        | null
        | number[]
        | { [key: string]: number | string }
        | { [key: string]: number | string | boolean | null | undefined }
        | { [key: string]: number | string | boolean | null | undefined }[];
};

type TReadOneParams<P> = {
    baseURL?: string;
    headers?: { [key: string]: string };
    identifier: string | number;
    params?: P & TWithOrder;
};

type TCreateParams<P> = {
    data: P;
    headers?: { [key: string]: string };
};

type TUpdateParams<P> = {
    data: P;
    headers?: { [key: string]: string };
    identifier: string | number;
};

type TDeleteParams<P> = {
    headers?: { [key: string]: string };
    identifier: string | number;
    params?: AxiosRequestConfig<P>;
};

type TCommandParams<P> = {
    baseURL?: string;
    command: string;
    data?: P;
    headers?: { [key: string]: string };
    method?: 'GET' | 'POST' | 'PATCH';
    params?: P;
};

type TWithOrder = {
    order?: Record<string, 'desc' | 'asc'>;
};

// eslint-disable-next-line max-params
export const ResourceService = (entity: string, axiosClient: TAxiosClient, context?: TResponseAndRequest) => {
    axiosClient.setInterceptors(context);
    return {
        command: <T, P = undefined>(params: TCommandParams<P>): Promise<{ data: T; statusCode: number }> => {
            const { baseURL, command, data, headers, method = 'POST', params: p } = params;

            const cmd = `/${entity}/${command}`;
            if (method === 'POST') {
                return apiService(axiosClient).post<T, P>(cmd, data, headers);
            }
            const query = p ? querify<P>(p) : '';
            const uri = `${cmd}${query}`;
            return apiService(axiosClient).get<T>(uri, headers, baseURL);
        },

        create: <T, P>(params: TCreateParams<P>): Promise<{ data: T; statusCode: number }> => {
            const { data, headers } = params;

            return apiService(axiosClient).create<T, P>(`/${entity}`, data, headers);
        },
        delete: <T, P>(params: TDeleteParams<P>): Promise<T> => {
            const { identifier, params: p } = params;

            const uri = `/${entity}/${identifier}`;
            return apiService(axiosClient).delete<T>(uri, p);
        },
        patch: <T, P>({ data, headers, identifier }: TUpdateParams<P>): Promise<T> => {
            const uri = `/${entity}/${identifier}`;
            return apiService(axiosClient).patch<T, P>(uri, data, headers);
        },
        read: <T, P = undefined>(params: TReadParams<P> = {}): Promise<T> => {
            const { baseURL, headers, params: p } = params;

            const query = p ? querify<P>(p) : '';
            const uri = `/${entity}${query}`;

            return apiService(axiosClient)
                .get<T>(uri, headers, baseURL)
                .then((response) => response.data);
        },
        readOne: <T, P = undefined>(params: TReadOneParams<P>): Promise<T> => {
            const { baseURL, headers, identifier, params: p } = params;

            const query = p ? querify<P>(p) : '';
            const uri = `/${entity}/${identifier}${query}`;
            return apiService(axiosClient)
                .get<T>(uri, headers, baseURL)
                .then((response) => response.data);
        },
        update: <T, P>(params: TUpdateParams<P>): Promise<T> => {
            const { data, headers, identifier } = params;

            const uri = `/${entity}/${identifier}`;
            return apiService(axiosClient).put<T, P>(uri, data, headers);
        },
    };
};
