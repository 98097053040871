import Image from 'next/image';
import React, { FC } from 'react';

import GadgetsImgGold from 'public/gold-anotation.png';
import GadgetsImgTechnic from 'public/main-ps-gadgets.png';

import { TDynamicPage } from '@/entities/pages';

import { useTranslate } from '@/shared/hooks/use-translate';

import { EPawnshopCategoryType } from '../../constants';

import * as Styled from './styled';

type TProps = {
    dynamicPage?: TDynamicPage;
    type: EPawnshopCategoryType;
};

export const GadgetsNotation: FC<TProps> = ({ dynamicPage, type }) => {
    const { t } = useTranslate();

    const defaultImg = type === EPawnshopCategoryType.APPLIANCE ? GadgetsImgTechnic : GadgetsImgGold;
    const image = dynamicPage?.calculator_image_url ?? defaultImg;

    const defaultCalculatorBigText =
        type == EPawnshopCategoryType.APPLIANCE
            ? t('mainCalcSectionNotationTitle', {
                  span: (chunks: string) => <span className="text-brand-green">{chunks}</span>,
              })
            : t('goldCalcSectionNotationTitle', {
                  span: (chunks: string) => <span className="text-green">{chunks}</span>,
              });

    const calculatorBigText =
        dynamicPage?.calculator_type === type ? (
            <span dangerouslySetInnerHTML={{ __html: dynamicPage.calculator_big_text }} />
        ) : (
            defaultCalculatorBigText
        );

    const defaultCalculatorSmallText = type == EPawnshopCategoryType.APPLIANCE ? t('mainCalcSectionNotationText') : '';

    const calculatorSmallText =
        dynamicPage?.calculator_type === type ? (
            <span dangerouslySetInnerHTML={{ __html: dynamicPage.calculator_small_text }} />
        ) : (
            defaultCalculatorSmallText
        );

    return (
        <Styled.Wrapper>
            <div className="gadget-image">
                <Image alt="Main ps gadgets" fill src={image} />
            </div>

            <div className="text">
                <div className="calc-notation-title">
                    <div className="spacing-xr-xxs">{calculatorBigText}</div>
                </div>

                <div className="calc-notation-text">{calculatorSmallText}</div>
            </div>
        </Styled.Wrapper>
    );
};
