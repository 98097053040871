import { styled } from 'styled-components';

import { rowXBetweenYCenter } from '@/styles/mixins.styled';

export const Wrapper = styled.div`
    ${rowXBetweenYCenter};

    span {
        color: ${({ theme }) => theme.color.neutral300};
    }
`;
