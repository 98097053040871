import { styled, css } from 'styled-components';

import { mq } from '@/styles/media.styled';
import { rowBothMiddle, rowXBetweenYCenter, rowYMiddle } from '@/styles/mixins.styled';

const priceStyles = css`
    position: relative;
    font-size: 20px;
    font-weight: 400;

    &::after {
        content: '';
        display: block;
        position: absolute;
        left: 0;
        top: 33%;
        width: 100%;
        height: 0;
        border-bottom: 2px solid #ffb2b2;
        transform: rotate(-17deg);
    }
`;

export const Wrapper = styled.div`
    ${rowXBetweenYCenter};
    gap: 24px;
    margin-top: 32px;
    background: #f6f8fa;
    padding: 16px 32px;
    border-radius: 12px;

    ${mq.lt.lg} {
        flex-direction: column;
        padding: 16px 10px;
    }

    .symbol-percent {
        font-size: 15px;
    }

    > div {
        &:first-child:not(.left-block) {
            p {
                &:last-child {
                    text-align: left;

                    ${mq.lt.lg} {
                        text-align: center;
                    }
                }
            }
        }
    }

    .left-block {
        ${rowYMiddle};
        gap: 32px;

        ${mq.lt.lg} {
            flex-direction: column;
        }

        .text {
            ${mq.lt.lg} {
                text-align: center;
            }

            p {
                &:first-child {
                    margin-bottom: 8px;
                    font-size: 16px;

                    ${mq.lt.lg} {
                        margin-bottom: 12px;
                        font-size: 16px;
                    }
                }

                &:last-child {
                    font-size: 24px;
                    font-weight: 500;
                    color: ${({ theme }) => theme.color.primary};
                    ${mq.lt.lg} {
                        font-size: 20px;
                    }
                }
            }
        }
    }

    .right-block {
        text-align: right;

        p:first-child {
            font-size: 16px;
            margin-bottom: 8px;
            display: flex;
            align-items: center;

            .term {
                font-weight: 700;

                ${mq.lt.lg} {
                    margin: 0;
                }
            }

            .price {
                ${priceStyles};
            }
        }

        .price-returned {
            font-weight: 500;
            font-size: 24px;
            line-height: 120%;
            color: ${({ theme }) => theme.color.primary};
            ${mq.lt.lg} {
                font-size: 32px;
                display: block;
            }
        }

        .new-client {
            margin-left: 5px;
            ${mq.lt.lg} {
                margin-left: 0;
                display: block;
                margin-top: 8px;
            }
        }
        ${mq.lt.lg} {
            .inf {
                display: block;
            }

            p:first-child {
                display: block;
                text-align: center;

                .price {
                    display: inline-block;
                    text-align: center;
                    padding-top: 5px;

                    > span {
                        display: none;
                    }

                    &::after {
                        top: 50%;
                    }
                }
            }

            p {
                &:last-child {
                    text-align: center;
                    margin-top: 24px;
                }
            }
        }
    }

    &.mobile {
        flex-direction: column;
        padding: 16px 10px;

        .left-block {
            flex-direction: column;

            .text {
                text-align: center;

                p {
                    &:first-child {
                        font-size: 16px;
                    }

                    &:last-child {
                        font-size: 20px;
                    }
                }
            }
        }

        .right-block {
            .inf {
                display: block;
            }

            p:first-child {
                display: block;
                text-align: center;

                .term {
                    margin: 0;
                }

                .price {
                    display: inline-block;
                    text-align: center;
                    padding-top: 5px;

                    > span {
                        display: none;
                    }

                    &::after {
                        top: 50%;
                    }
                }
            }

            p:last-child {
                text-align: center;
                margin-top: 24px;

                .price-returned {
                    font-size: 32px;
                    display: block;
                    text-align: center;
                }

                .new-client {
                    margin-left: 0;
                    display: block;
                    margin-top: 8px;
                }
            }
        }
    }

    .bottom-block {
        ${rowBothMiddle}
    }
`;
