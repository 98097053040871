import { CSSProperties } from 'react';

export const AMOUNT_FOR_SALE_RATIO = 1.05;

export const PURCHASE_MARGIN_NAME = 'purchase_margin';

export const FAKE_LOADING_LENGTH = 7000;

export const MAIN_PAGE_CALC_SECTION_ID = 'product-calculator';

export enum EPawnshopCategoryType {
    APPLIANCE = 'appliance',
    JEWEL = 'jewel',
}

export const textHelpCategoryType = {
    [EPawnshopCategoryType.JEWEL]: {
        checkboxTitleStr: 'Я хочу продать это изделие',
        notationCalcTextStr: 'Тогда мы не будем хранить ваше изделие у нас, а сразу выставим его на торги',
        selectMainTitleStr: 'Укажите изделие',
        submitTitleStr: 'Оценить изделие \uD83D\uDD25',
    },
    [EPawnshopCategoryType.APPLIANCE]: {
        checkboxTitleStr: 'Я хочу продать эту технику',
        notationCalcTextStr: 'notationCalcText1_p1',
        selectMainTitleStr: 'Укажите тип устройства',
        submitTitleStr: 'Оценить технику \uD83D\uDD25',
    },
};

export const textHelpResultEstimate = {
    [EPawnshopCategoryType.JEWEL]: {
        title: 'Мы оценили ваше изделие ✨',
        titleLoading: 'Мы оцениваем ваше изделие',
    },
    [EPawnshopCategoryType.APPLIANCE]: {
        title: 'Мы оценили вашу технику ✨',
        titleLoading: 'Мы оцениваем вашу технику',
    },
};

export const MARGIN_TOP = '64px';

export const SPACE_STYLE_COMMON: CSSProperties = {
    marginTop: MARGIN_TOP,
    width: '100%',
};

export enum ECalculationEstimateStatus {
    ZERO,
    ERROR,
    SUCCESS,
    EMPTY,
}

export enum ECategoryOptionFiledType {
    INPUT = 'input',
    SELECT = 'dropdown',
    SELECT_MULTIPLE = 'multidropdown',
}

export enum ESubmitStatusEstimateForm {
    await,
    in_progress,
    completed,
}
