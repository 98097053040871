import { styled } from 'styled-components';

import { mq } from '@/styles/media.styled';

export const ResultWrapper = styled.div`
    padding: 32px;
    box-shadow: 0 4px 12px 0 #00000014;
    background-color: white;
    border-radius: 12px;
    width: 100%;
    ${mq.lt.lg} {
        padding: 18px 16px;
    }
    ${mq.lt.sm} {
        padding: 18px 28px;
    }

    .loading-message,
    .success-message,
    .error-message {
        font-weight: 400;
        font-size: ${({ theme }) => theme.fontSize.md};
        line-height: 120%;
        text-align: center;
        white-space: pre-wrap;
    }

    .parameter {
        .label {
            font-weight: 400;
            font-size: 12px;
            line-height: 120%;
            margin-bottom: 4px;
        }

        .value {
            font-weight: 500;
            font-size: 20px;
            line-height: 120%;
        }

        ${mq.lt.sm} {
            .value {
                font-size: ${({ theme }) => theme.fontSize.md};
            }
        }
    }

    .for-sale {
        font-weight: 400;
        font-size: 14px;
        line-height: 120%;

        ${mq.lt.md} {
            margin-bottom: 0 !important;
        }
    }

    &.mobile .result-col-mobile-top {
        order: 2;
        padding-left: 0 !important;
        padding-right: 0 !important;
    }

    .result-col-mobile-top {
        ${mq.lt.md} {
            order: 2;
        }
        ${mq.lt.sm} {
            padding-left: 0 !important;
            padding-right: 0 !important;
        }
    }

    .result-col {
        display: flex;

        .result {
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;

            .result-space {
                align-items: center;
            }

            .amount {
                font-weight: 700;
                font-size: 32px;
                line-height: 120%;
                text-align: center;
                background: linear-gradient(218.13deg, #63d07b 18.93%, #0bb6ba 94.11%);
                background-clip: text;
            }

            .info {
                font-weight: 400;
                font-size: 16px;
                line-height: 120%;
                text-align: center;
            }

            .button-wrapper {
                width: 100%;

                .button {
                    width: 100%;
                }
            }
        }

        ${mq.lt.md} {
            order: 1;
            padding-left: 0 !important;
            padding-right: 0 !important;

            .result {
                width: 100%;

                .info {
                    margin-bottom: 0;
                }

                .space {
                    width: 100%;
                }
            }
        }

        ${mq.lt.sm} {
            .result {
                padding-left: 0 !important;
                padding-right: 0 !important;

                .info {
                    font-size: ${({ theme }) => theme.fontSize.sm};
                }

                .button-wrapper {
                    span {
                        font-size: ${({ theme }) => theme.fontSize.sm};
                    }
                }
            }
        }
    }

    .ant-space-item {
        > div {
            width: 100%;
        }
    }
`;
