const requireEnv = (variable: string | undefined, name: string) => {
    if (typeof variable === 'undefined') {
        throw new Error(`Env variable "${name}" is required`);
    }

    return variable;
};

export const isClient = () => typeof window === 'object';

export const isProduction = () => requireEnv(process.env.NEXT_PUBLIC_APP_ENV, 'APP_ENV') === 'production';

export const API_PUBLIC_SC_BASE_URL = requireEnv(
    process.env.NEXT_PUBLIC_API_PUBLIC_SC_BASE_URL,
    'API_PUBLIC_SC_BASE_URL',
);

export const DENGIMARKET_OLX_URL = requireEnv(process.env.NEXT_PUBLIC_DENGIMARKET_OLX_URL, 'DENGIMARKET_OLX_URL');

export const DENGIMARKET_PERSONAL_ROOM_URL = requireEnv(
    process.env.NEXT_PUBLIC_DENGIMARKET_PERSONAL_ROOM_URL,
    'DENGIMARKET_PERSONAL_ROOM_URL',
);

export const DENGIMARKET_LOMBARD_INSTAGRAM_URL = requireEnv(
    process.env.NEXT_PUBLIC_DENGIMARKET_LOMBARD_INSTAGRAM_URL,
    'DENGIMARKET_LOMBARD_INSTAGRAM_URL',
);

export const DENGIMARKET_ENABLED_LANG =
    requireEnv(process.env.NEXT_PUBLIC_ENABLED_LANG, 'DENGIMARKET_ENABLED_LANG') === '1';

export const APP_BASE_URL = requireEnv(process.env.NEXT_PUBLIC_APP_BASE_URL, 'APP_BASE_URL');

export const DENGIMARKET_LOGIN_URL = requireEnv(process.env.NEXT_PUBLIC_DENGIMARKET_LOGIN_URL, 'DENGIMARKET_LOGIN_URL');

export const API_PUBLIC_BASE_URL = requireEnv(process.env.NEXT_PUBLIC_API_PUBLIC_BASE_URL, 'API_PUBLIC_BASE_URL');

export const API_SC_BASE_URL = requireEnv(process.env.NEXT_PUBLIC_API_PUBLIC_SC_BASE_URL, 'API_SC_BASE_URL');

export const FACEBOOK_PIXEL_ID = requireEnv(process.env.NEXT_PUBLIC_FACEBOOK_PIXEL_ID, 'FACEBOOK_PIXEL_ID');

export const PATH_PUBLIC_BASE_URL = requireEnv(process.env.NEXT_PUBLIC_PATH_PUBLIC_BASE_URL, 'PATH_PUBLIC_BASE_URL');

export const PUBLIC_PHONE_NUMBER = requireEnv(process.env.NEXT_PUBLIC_PHONE_NUMBER, 'PUBLIC_PHONE_NUMBER');

export const SENTRY_DSN = requireEnv(process.env.NEXT_PUBLIC_SENTRY_DSN, 'SENTRY_DSN');

export const APP_DEBUG = requireEnv(process.env.NEXT_PUBLIC_APP_DEBUG, 'APP_DEBUG') === 'true';
