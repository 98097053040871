import { Row, Space, Typography } from 'antd';
import React, { FC, LegacyRef } from 'react';

import { useAppDispatch } from '@/store';

import { ResultWrapper } from '@/features/estimate-form';

import { useTranslate } from '@/shared/hooks/use-translate';
import { LinkBrandButton } from '@/shared/ui/link-brand-button';

import {
    MAIN_PAGE_CALC_SECTION_ID,
    SPACE_STYLE_COMMON,
    ECalculationEstimateStatus,
    ESubmitStatusEstimateForm,
} from '../../constants';
import { setStatusEstimateForm, setSubmitStatusEstimateForm } from '../../store/slice';

const { Paragraph } = Typography;

type TProps = {
    myRef: LegacyRef<HTMLDivElement>;
};

export const ResultZero: FC<TProps> = ({ myRef }) => {
    const { t } = useTranslate();
    const dispatch = useAppDispatch();

    const hideBlockClick = () => {
        dispatch(setSubmitStatusEstimateForm(ESubmitStatusEstimateForm.await));
        dispatch(setStatusEstimateForm(ECalculationEstimateStatus.EMPTY));
    };
    return (
        <div ref={myRef}>
            <Space direction="vertical" size="large" style={SPACE_STYLE_COMMON}>
                <ResultWrapper>
                    <Row className="CollateralValuationCalcResultZero" justify="center">
                        <Space direction="vertical" size="large">
                            <Space align="center" direction="vertical">
                                <Typography.Title level={4} style={{ margin: 0 }}>
                                    {t('Заявка принята!')}
                                </Typography.Title>

                                <Paragraph className="success-message">{t('Мы скоро свяжемся с Вами!')}</Paragraph>
                            </Space>

                            <div className="button">
                                <LinkBrandButton
                                    duration={500}
                                    offset={-100}
                                    onClick={hideBlockClick}
                                    smooth={true}
                                    spy={true}
                                    to={MAIN_PAGE_CALC_SECTION_ID}
                                >
                                    <span className="brand-button-link-title">{t('Спасибо 👍')}</span>
                                </LinkBrandButton>
                            </div>
                        </Space>
                    </Row>
                </ResultWrapper>
            </Space>
        </div>
    );
};
