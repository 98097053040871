import { useState } from 'react';

import { useAppDispatch, useAppSelector } from '@/store';

import { getCreditProducts } from '@/shared/api/sc/credit-products-service';
import { getPawnshopSettingByName } from '@/shared/api/site/pawnshop-settings-service';
import { useUtmParams } from '@/shared/hooks/use-utm-params';
import { customCaptureException } from '@/shared/lib/custom-capture-exception';

import { createLead, getCalculationEstimateForm } from '../../api';
import {
    FAKE_LOADING_LENGTH,
    PURCHASE_MARGIN_NAME,
    ECalculationEstimateStatus,
    EPawnshopCategoryType,
    ESubmitStatusEstimateForm,
} from '../../constants';
import { categoryOption } from '../../lib/category-option';
import {
    setDataEstimateForm,
    setInitOverpaymentCalculator,
    setSubmitStatusEstimateForm,
    setTypeEstimateForm,
} from '../../store/slice';
import { TPawnshopCategory, TPawnshopCategoryOption, TRequestCreateLeadParams } from '../../types';

export const useSubmitEstimateForm = () => {
    const categories = useAppSelector((state) => state.estimateForm.categories);
    const [isSubmitForm, setIsSubmitForm] = useState(false);
    const { getUtmParamsFromCookie } = useUtmParams();
    const dispatch = useAppDispatch();

    const getPurchaseMargin = async (): Promise<number> => {
        try {
            const response = await getPawnshopSettingByName(PURCHASE_MARGIN_NAME);
            return +response.value;
        } catch (e) {
            customCaptureException(e, 'getPurchaseMargin');
            return 1;
        }
    };

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const handleSubmitForm = async (
        values: any,
        categoryOptions: TPawnshopCategoryOption[],
        type: EPawnshopCategoryType,
    ) => {
        dispatch(setSubmitStatusEstimateForm(ESubmitStatusEstimateForm.in_progress));
        dispatch(setTypeEstimateForm(type));
        let status: ECalculationEstimateStatus = ECalculationEstimateStatus.ERROR;
        const utmStaff = getUtmParamsFromCookie();
        setIsSubmitForm(true);
        await new Promise((resolve) => {
            setTimeout(resolve, FAKE_LOADING_LENGTH);
        });
        const requestOptions = categoryOption.getRequestCalculationOptionsEstimate(values.options);
        const selectedCategory = categories.find((item) => item.id === values.category);

        const responseCalculationEstimate = await getCalculationEstimateForm(
            selectedCategory?.saascreditId as number,
            requestOptions,
        );
        const { data: _amount, statusEstimate } = responseCalculationEstimate;

        const _purchaseMargin = values.isExtraCharge ? await getPurchaseMargin() : 1;
        const amount = values.isExtraCharge ? Math.floor(_amount * _purchaseMargin) : _amount;

        const category = categories.find((item) => item.id === values.category);

        let requestLead: TRequestCreateLeadParams = {
            collateral: {
                category_id: (category as TPawnshopCategory).saascreditId,
                options: categoryOption.getRequestCollateralOptionsLead(values.options, categoryOptions),
            },
            collateral_estimate_amount: 0,
            phone_number: `${values.phone.replace(/[^\d+]/g, '')}`,
            referer: utmStaff.referal ?? '',
            source_type: 'DENGIMARKET',
            utm_source: !utmStaff.utm_source ? 'dengimarket' : utmStaff.utm_source,
        };

        if (statusEstimate === ECalculationEstimateStatus.SUCCESS) {
            requestLead = {
                ...requestLead,
                collateral_estimate_amount: amount,
            };
        }

        const isEstimateSuccess = statusEstimate === ECalculationEstimateStatus.SUCCESS;
        const isEstimateZero = statusEstimate === ECalculationEstimateStatus.ZERO;
        const isEstimateError = statusEstimate === ECalculationEstimateStatus.ERROR;

        const responseLead = await createLead(requestLead);
        const isLeadSuccess = !!responseLead.data;

        /*
      - - - - - - - - - - - - - - - - - - - - -

      Кейсы:

      Кейс 1: оценка ответ 200, amount>0, создание лида (collateral_estimate_amount=значение оценки) 201 - ок, показываем блок расчета.
      Кейс 2: оценка ответ 200, amount>0, создание лида (collateral_estimate_amount=значение оценки) !201 - лид не создался, но отобразили расчет.
      Кейс 3: оценка ответ 200, amount=0, создание лида (collateral_estimate_amount=0) 201 - ок, показываем "С вами свяжется менеджер".
      Кейс 4: оценка ответ 200, amount=0, создание лида (collateral_estimate_amount=0) !201 - показываем "С вами свяжется менеджер"
      Кейс 5: оценка ответ !200, amount - отсутствует, создание лида (collateral_estimate_amount=0) 201 - ок, показываем "С вами свяжется менеджер".
      Кейс 6: оценка ответ !200, amount - отсутствует, создание лида (collateral_estimate_amount=0) !201 - совсем все плохо, показываем "Сломались"

      - - - - - - - - - - - - - - - - - - - - -
    */

        /**
         * Кейс 1
         */
        if (isEstimateSuccess && isLeadSuccess) {
            status = ECalculationEstimateStatus.SUCCESS;

            /**
             * Кейс 2
             */
        } else if (isEstimateSuccess && !isLeadSuccess) {
            status = ECalculationEstimateStatus.SUCCESS;

            /**
             * Кейс 3
             */
        } else if (isEstimateZero && isLeadSuccess) {
            status = ECalculationEstimateStatus.ZERO;

            /**
             * Кейс 4
             */
        } else if (isEstimateZero && !isLeadSuccess) {
            status = ECalculationEstimateStatus.ZERO;

            /**
             * Кейс 5
             */
        } else if (isEstimateError && isLeadSuccess) {
            status = ECalculationEstimateStatus.ZERO;

            /**
             * Кейс 6
             */
        } else if (isEstimateError && !isLeadSuccess) {
            status = ECalculationEstimateStatus.ERROR;
        }

        dispatch(
            setDataEstimateForm({
                amountEstimate: amount,
                isSubmit: true,
                lead: responseLead.data,
                status,
                values: {
                    isExtraCharge: values.isExtraCharge,
                    options: values.options,
                },
            }),
        );

        try {
            const responseCreditProduct = await getCreditProducts((category as TPawnshopCategory).saascreditId);
            dispatch(setInitOverpaymentCalculator(responseCreditProduct));
        } catch (e) {
            customCaptureException(e, 'useSubmitEstimateForm.ts => handleSubmitForm');
        }

        setIsSubmitForm(false);
    };
    return {
        isSubmitForm,
        onSubmitEstimateForm: handleSubmitForm,
    };
};
