export const querify = <P>(params: P): string => {
    const url = new URLSearchParams();

    for (const key in params) {
        if (key === 'order') {
            Object.entries(params[key] as object).forEach(([name, direction]) => {
                if (typeof direction === 'string') {
                    url.append(`${key}[${name}]`, direction);
                }
            });
        } else if (Array.isArray(params[key])) {
            for (const index in params[key]) {
                url.append(`${key}[]`, String(params[key][index]));
            }
        } else {
            url.append(key, String(params[key]));
        }
    }
    return '?' + url;
};

export const getParameterByName = (name: string): string => {
    // eslint-disable-next-line no-useless-escape
    name = name.replace(/[\[]/, '\\[').replace(/[\]]/, '\\]');
    const regex = new RegExp('[\\?&]' + name + '=([^&#]*)'),
        // eslint-disable-next-line no-restricted-globals
        results = regex.exec(location.search);
    return results === null ? '' : decodeURIComponent(results[1].replace(/\+/g, ' '));
};

// eslint-disable-next-line max-params
export const insertParamIntoUrl = (
    currentPage: number,
    pageIndex: number,
    routPath: string,
    withoutLastParam?: boolean,
    // eslint-disable-next-line max-params
): string => {
    const paramsMap = routPath.split('/');
    if (withoutLastParam && paramsMap.includes(currentPage.toString())) {
        paramsMap.splice(paramsMap.length - 1, 1);
    }
    paramsMap.push(`${pageIndex}`);

    return `${paramsMap.length ? `${paramsMap.join('/')}` : `${routPath}`}`;
};

export const getQueryPagination = (page: number, routPath: string): string => {
    const hasParams = routPath.split('?').length > 1;
    return `${routPath}${hasParams ? '&' : '?'}page=${page}`;
};

export const removeParam = (url: string, parameter: string): string => {
    const urlParts = url.split('?');
    if (urlParts.length >= 2) {
        const prefix = encodeURIComponent(parameter) + '=';
        const pars = urlParts[1].split(/[&;]/g);
        for (let i = pars.length; i-- > 0; ) {
            if (pars[i].lastIndexOf(prefix, 0) !== -1) {
                pars.splice(i, 1);
            }
        }

        if (pars.length > 0) {
            url = urlParts[0] + '?' + pars.join('&');
        } else {
            url = urlParts[0];
        }

        return url;
    }
    return url;
};
