import https from 'https';

import axios, { AxiosInstance } from 'axios';

export const axiosInstance = (baseURL: string): AxiosInstance => {
    return axios.create({
        baseURL: baseURL,
        headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
        },
        httpsAgent: new https.Agent({ rejectUnauthorized: false }),
    });
};
