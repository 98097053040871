var _sentryCollisionFreeGlobalObject = typeof window != "undefined" ? window : typeof global != "undefined" ? global : typeof self != "undefined" ? self : {};
_sentryCollisionFreeGlobalObject["__sentryRewritesTunnelPath__"] = undefined;
_sentryCollisionFreeGlobalObject["SENTRY_RELEASE"] = {"id":"Ha490CBra3IGH-gFVY3Ua"};
_sentryCollisionFreeGlobalObject["__sentryBasePath"] = undefined;
_sentryCollisionFreeGlobalObject["__rewriteFramesAssetPrefixPath__"] = "";

import * as Sentry from '@sentry/nextjs';

import { SENTRY_DSN } from '@/shared/config/app';

Sentry.init({
    dsn: SENTRY_DSN,
    tracePropagationTargets: [
        'http://localhost:3000',
        'https://dengimarket.kz/',
        'https://test-dm-kz.dengi-market.kz/',
        /^\/api\//,
    ],
    ignoreErrors: ['Non-Error exception captured', 'Non-Error promise rejection captured'],
    integrations: [
        new Sentry.Integrations.FunctionToString(),
        new Sentry.Integrations.InboundFilters(),
        new Sentry.Integrations.Breadcrumbs(),
        new Sentry.Integrations.LinkedErrors(),
        new Sentry.Integrations.GlobalHandlers(),
        new Sentry.Integrations.HttpContext(),
    ],
    replaysOnErrorSampleRate: 1.0,
    tracesSampleRate: 1,
});
