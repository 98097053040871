import { customCaptureException } from '@/shared/lib/custom-capture-exception';
import { TCalculatorProducts } from '@/shared/types/calculator';

import { TReadParamsProperties, ResourceService } from '../baseServices/resource-service';

import { axiosScClient } from './axios';

export const CreditProductsService = ResourceService('credit-products', axiosScClient);

export const getCreditProducts = async (categoryId: number): Promise<TCalculatorProducts[]> => {
    try {
        const response = await CreditProductsService.read<{ data: TCalculatorProducts[] }, TReadParamsProperties>({
            params: {
                classifiers: [11],
                pawnshopGroupId: categoryId,
                type: 'site',
            },
        });
        return response.data;
    } catch (e) {
        customCaptureException(e, 'getCreditProducts');
        return [];
    }
};
