import { styled } from 'styled-components';

import { mq } from '@/styles/media.styled';

export const Wrapper = styled.div`
    height: 582px;
    width: 100%;
    overflow: auto;

    ${mq.lt.lg} {
        height: 400px !important;
    }
`;
