import cn from 'classnames';
import { useRouter } from 'next/router';
import React, { FC, useEffect } from 'react';

import { useAppDispatch, useAppSelector } from '@/store';

import { useTranslate } from '@/shared/hooks/use-translate';

import { EPawnshopCategoryType, ESubmitStatusEstimateForm } from '../../constants';
import { resetDataEstimateForm } from '../../store/slice';
import { CalculationInfo } from '../calculation-info';
import { SumInput } from '../sum-input';
import { TermInput } from '../term-input';

import {
    StyledOverpaymentCalculator,
    StyledOverpaymentCalculatorFields,
    StyledOverpaymentCalculatorTitle,
    StyledOverpaymentContainer,
} from './styled';

type TProps = {
    hasMobile?: boolean;
};

export const OverpaymentCalculator: FC<TProps> = ({ hasMobile }) => {
    const { submitStatus, type } = useAppSelector((state) => state.estimateForm.form);
    const { selectedKp } = useAppSelector((state) => state.estimateForm.overpaymentCalculator);
    const route = useRouter();

    const { t } = useTranslate();
    const dispatch = useAppDispatch();

    useEffect(() => {
        dispatch(resetDataEstimateForm());
    }, [route.asPath]);

    if (
        submitStatus === ESubmitStatusEstimateForm.await ||
        submitStatus === ESubmitStatusEstimateForm.in_progress ||
        !selectedKp?.id
    ) {
        return <></>;
    }

    return (
        <StyledOverpaymentCalculator>
            <StyledOverpaymentCalculatorTitle>
                {t('Узнайте, сколько вам нужно будет вернуть')} ↩️
            </StyledOverpaymentCalculatorTitle>

            <StyledOverpaymentContainer>
                <StyledOverpaymentCalculatorFields className={cn({ mobile: hasMobile })}>
                    <SumInput type={type as EPawnshopCategoryType} />

                    <TermInput type={type as EPawnshopCategoryType} />
                </StyledOverpaymentCalculatorFields>

                <CalculationInfo hasMobile={hasMobile} />
            </StyledOverpaymentContainer>
        </StyledOverpaymentCalculator>
    );
};
