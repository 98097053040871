import { CollapseProps } from 'antd';
import React, { FC } from 'react';

import * as Styled from './styled';
import { ECollapseAntVariant } from './types';

type TProps = Omit<CollapseProps, 'items'> & {
    items: CollapseProps['items'];
    variant?: ECollapseAntVariant;
};

export const CollapseAnt: FC<TProps> = (props) => {
    const { items, variant = ECollapseAntVariant.DEFAULT, ...rest } = props;

    return <Styled.Wrapper {...rest} variant={variant} items={items} />;
};
