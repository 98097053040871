import { PlusOutlined } from '@ant-design/icons';
import { SelectProps, Select } from 'antd';
import { DefaultOptionType } from 'rc-select/es/Select';
import { BaseOptionType } from 'rc-select/lib/Select';
import React, { FC, useCallback, useMemo } from 'react';
import { useTheme } from 'styled-components';

import { useTranslate } from '@/shared/hooks/use-translate';
import ArrowDownIcon from '@/shared/icons/arrow-down-icon';
import { TAntdFormItemReturnProps } from '@/shared/types/antd';
import { Spinner } from '@/shared/ui/spinner';

import * as Styled from './styled';
import { TSizeHeightSelect } from './types';

const { Option } = Select;

export type TSelectAnt = SelectProps<unknown, BaseOptionType | DefaultOptionType> & {
    formItemProps?: TAntdFormItemReturnProps;
    height?: TSizeHeightSelect;
    labelInValue?: boolean;
    title?: string;
};

export const SelectAntd: FC<TSelectAnt> = (props) => {
    const {
        defaultValue: defaultValueProp,
        disabled,
        formItemProps,
        labelInValue = true,
        loading,
        options,
        placeholder,
        title,
        value,
        ...rest
    } = props;

    const { t } = useTranslate();

    const theme = useTheme();
    const isMultiple = rest.mode === 'multiple' || rest.mode === 'tags';

    const defaultValue: DefaultOptionType | DefaultOptionType[] | undefined = useMemo(() => {
        if (defaultValueProp) {
            return defaultValueProp as DefaultOptionType | DefaultOptionType[];
        }

        return undefined;
    }, [defaultValueProp]);

    const SuffixIcon = useCallback(() => {
        if (loading) {
            return <Spinner size="small" />;
        }

        if (disabled) {
            return null;
        }

        return <ArrowDownIcon color={theme.color.grey} height={30} width={30} />;
    }, [disabled, loading, theme.color.grey]);

    const val = formItemProps?.value || value;

    return (
        <>
            <Styled.SelectGlobal />

            <Styled.Wrapper>
                {title && <div className="title-select">{title}</div>}

                <Styled.SelectWrapper
                    {...formItemProps}
                    notFoundContent={<p style={{ textAlign: 'center' }}>{t('Нет доступных опций')}</p>}
                    {...rest}
                    defaultValue={defaultValue}
                    disabled={disabled}
                    filterOption={(input, option) => (option?.label ?? '').toLowerCase().includes(input.toLowerCase())}
                    labelInValue={labelInValue}
                    loading={loading}
                    onChange={(...e) => {
                        const v = e[0] as DefaultOptionType | DefaultOptionType[];

                        rest?.onChange?.(v, e[1]);
                        formItemProps?.onChange?.(v);

                        return e;
                    }}
                    placeholder={placeholder}
                    suffixIcon={<SuffixIcon />}
                    value={val}
                >
                    {options
                        ?.filter((opt) => {
                            if (!val || (Array.isArray(val) && !val.length)) {
                                return true;
                            }

                            if (val && Array.isArray(val)) {
                                return !val.find((item) => item.value === opt.value);
                            }

                            return val.value !== opt.value;
                        })
                        ?.map((opt) => {
                            let inner: JSX.Element | string = opt.label;

                            if (isMultiple) {
                                inner = (
                                    <>
                                        <PlusOutlined
                                            style={{
                                                color: theme.color.primary,
                                                fontSize: '1.25rem',
                                                marginRight: '8px',
                                            }}
                                        />

                                        {opt.label}
                                    </>
                                );
                            }

                            return (
                                <Option {...opt} key={`SelectAntd-Option-${opt.label}-${opt.value}`}>
                                    {inner}
                                </Option>
                            );
                        })}
                </Styled.SelectWrapper>
            </Styled.Wrapper>
        </>
    );
};
