import { AxiosRequestConfig } from 'axios';

import { TAxiosClient } from './types';

export type TErrorResponse = {
    error: {
        detail: string;
        message: string;
        status: number;
        title: string;
    };
};

const prepareData = <T>(response: T | TErrorResponse | string): T => {
    if (!response) {
        return response as T;
    }

    if (typeof response === 'string') {
        throw new Error(response);
    }
    if (typeof response === 'object' && 'error' in response && response.error) {
        throw new Error(response.error.detail);
    }

    return response as T;
};

export const apiService = (axiosClient: TAxiosClient) => ({
    create: async function <T, P>(
        url: string,
        data?: P,
        headers?: { [key: string]: string },
    ): Promise<{ data: T; statusCode: number }> {
        const response = await axiosClient.use().post<T | TErrorResponse>(url, data, headers);
        return {
            data: prepareData<T>(response.data),
            statusCode: response.status,
        };
    },

    delete: async function <T>(url: string, params?: AxiosRequestConfig): Promise<T> {
        const response = await axiosClient.use().delete<T | TErrorResponse>(url, params);
        return prepareData<T>(response.data);
    },

    get: async function <T>(
        url: string,
        headers?: { [key: string]: string },
        baseURL?: string,
    ): Promise<{ data: T; statusCode: number }> {
        const response = await axiosClient.use().get<T | TErrorResponse>(url, {
            baseURL,
            headers,
        });
        return {
            data: prepareData<T>(response.data),
            statusCode: response.status,
        };
    },

    patch: async function <T, P>(url: string, data: P, headers?: { [key: string]: string }): Promise<T> {
        const response = await axiosClient.use().patch<T | TErrorResponse>(url, data, headers);
        return prepareData<T>(response.data);
    },

    post: async function <T, P>(
        url: string,
        data?: P,
        headers?: { [key: string]: string },
    ): Promise<{ data: T; statusCode: number }> {
        const response = await axiosClient.use().post<T | TErrorResponse>(url, data, headers);
        return {
            data: prepareData<T>(response.data),
            statusCode: response.status,
        };
    },

    put: async function <T, P>(url: string, data: P, headers?: { [key: string]: string }): Promise<T> {
        const response = await axiosClient.use().put<T | TErrorResponse>(url, data, headers);
        return prepareData<T>(response.data);
    },
});
