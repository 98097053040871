export type TLangSite = 'ru' | 'kk';

export const MAIN_PAGE_MAP_BRANCH_SECTION_ID = 'office-map';

export const PHONE_LENGTH = 11;

export const NOT_FOUND_PAGE = 404;

export const BAD_REQUEST = 400;

export const SERVER_ERROR = 500;

export const UNAUTHORIZED = 401;

export const STATUS_CODE_OK = 200;

export const STATUS_CODE_CREATED = 201;

export const MARKET_ORGANIZATION_ID = 4;

export const DEFAULT_COORDINATES = {
    lat: '53.179716',
    lon: '63.608038',
};

export const localesTitle = {
    kk: 'Kz',
    ru: 'Ru',
};

export const LangSiteId = {
    kk: 1,
    ru: 2,
};

export const LangSiteApi = {
    kk: 'kz',
    ru: 'ru',
};

export const CATEGORY_PAGE_ARTICLE_ID = 2;

export const SIZE_LIST_PAGE_ARTICLE = 10;

// Ссылка на контактный whatsapp
export const WHATSAPP_LINK = 'http://wa.me';

// Ссылка на контактный whatsapp
export const TELEGRAM_LINK = `https://t.me/DM_info_bot`;
