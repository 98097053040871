import React from 'react';
import { useTheme } from 'styled-components';

import * as Styled from './styled';

type TLoader = {
    isDots?: boolean;

    svgProps?: React.SVGProps<SVGSVGElement>;
};

export const Loader: React.FC<TLoader> = (props) => {
    const { isDots, svgProps } = props;

    const theme = useTheme();

    if (isDots) {
        /**
         * Index by: https://github.com/SamHerbert/SVG-Loaders/blob/master/svg-loaders/three-dots.svg?short_path=6f7b43a
         */
        return (
            <svg
                fill="#fff"
                height="15"
                viewBox="0 0 120 30"
                width="95"
                xmlns="http://www.w3.org/2000/svg"
                {...svgProps}
            >
                <circle cx="15" cy="15" r="15">
                    <animate
                        attributeName="r"
                        begin="0s"
                        calcMode="linear"
                        dur="0.8s"
                        from="15"
                        repeatCount="indefinite"
                        to="15"
                        values="15;9;15"
                    />

                    <animate
                        attributeName="fill-opacity"
                        begin="0s"
                        calcMode="linear"
                        dur="0.8s"
                        from="1"
                        repeatCount="indefinite"
                        to="1"
                        values="1;.5;1"
                    />
                </circle>

                <circle cx="60" cy="15" fillOpacity="0.3" r="9">
                    <animate
                        attributeName="r"
                        begin="0s"
                        calcMode="linear"
                        dur="0.8s"
                        from="9"
                        repeatCount="indefinite"
                        to="9"
                        values="9;15;9"
                    />

                    <animate
                        attributeName="fill-opacity"
                        begin="0s"
                        calcMode="linear"
                        dur="0.8s"
                        from="0.5"
                        repeatCount="indefinite"
                        to="0.5"
                        values=".5;1;.5"
                    />
                </circle>

                <circle cx="105" cy="15" r="15">
                    <animate
                        attributeName="r"
                        begin="0s"
                        calcMode="linear"
                        dur="0.8s"
                        from="15"
                        repeatCount="indefinite"
                        to="15"
                        values="15;9;15"
                    />

                    <animate
                        attributeName="fill-opacity"
                        begin="0s"
                        calcMode="linear"
                        dur="0.8s"
                        from="1"
                        repeatCount="indefinite"
                        to="1"
                        values="1;.5;1"
                    />
                </circle>
            </svg>
        );
    }

    return (
        <Styled.Wrapper>
            <svg viewBox="0 0 100 100" {...svgProps}>
                <defs>
                    <filter id="shadow">
                        <feDropShadow dx="0" dy="0" floodColor="#eee" stdDeviation="1.5" />
                    </filter>
                </defs>

                <circle
                    cx="50"
                    cy="50"
                    id="spinner"
                    r="45"
                    style={{
                        fill: 'transparent',
                        filter: 'url(#shadow)',
                        stroke: theme.color.primary,
                        strokeLinecap: 'round',
                        strokeWidth: '7px',
                    }}
                />
            </svg>
        </Styled.Wrapper>
    );
};
