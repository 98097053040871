import { styled, css, keyframes } from 'styled-components';

import { TSizeModal, TTypeModal } from '@/store/reducers/modal/types';
import { mq } from '@/styles/media.styled';

export const OverlayWrapper = styled.div`
    height: 100%;
    width: 100%;
    position: fixed;
    top: 0;
    left: 0;
    background-color: rgb(0 0 0 / 50%);
    overflow: hidden;
    z-index: 998;
`;

const typeDefault = css`
    border-radius: 8px;
    box-shadow: 5px 5px 50px -12px #000;
`;

const typeUnboarded = css`
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 0;
    box-shadow: 0 4px 12px rgb(0 0 0 / 8%);
    border-radius: 12px;
`;

const unboardedTitleWrapper = css`
    position: fixed;
    top: 0;
    right: -30px;
`;

const titleWrapper = css`
    display: flex;
    justify-content: space-between;
    margin-bottom: 20px;
`;

const sizeModalRubber = css`
    ${mq.lt.md} {
        width: 95%;
        padding: 10px;
    }
`;

const sizeModalSmall = css`
    width: 377px;
    padding: 24px;

    ${mq.lt.sm} {
        width: 85%;
    }
`;
const sizeModalMedium = css`
    width: 500px;
    padding: 24px;

    ${mq.lt.md} {
        width: 320px;
    }

    ${mq.lt.sm} {
        width: 90%;
    }
`;
const sizeModalLarge = css`
    width: 700px;
    padding: 24px;

    ${mq.lt.md} {
        width: 460px;
    }

    ${mq.lt.sm} {
        width: 90%;
    }
`;
const sizeModalHuge = css`
    width: 850px;
    padding: 24px;

    ${mq.lt.lg} {
        width: 760px;
    }
    ${mq.lt.md} {
        width: 460px;
    }

    ${mq.lt.sm} {
        width: 95%;
        padding: 24px 8px;
    }
`;
const sizeModalMax = css`
    width: 1100px;
    padding: 24px;

    ${mq.lt.xl} {
        width: 980px;
    }
    ${mq.lt.lg} {
        width: 760px;
    }
    ${mq.lt.md} {
        width: 95%;
        padding: 10px;
    }
`;

const slider = keyframes`
    100% { left: 0; }
`;

export const SideBarWrapper = styled.div<{ sizeModal?: TSizeModal }>`
    width: 360px;
    ${mq.lt.sm} {
        width: 276px;
    }

    position: fixed;
    height: 100%;
    z-index: 999;
    top: 0;
    left: -150%;
    background-color: #fff !important;
    transition: ${slider} 0.5s ease-in-out;
    animation: ${slider} 0.5s forwards;
    box-shadow: 5px 2px 72px 0 rgb(0 0 0 / 75%);
    overflow-y: scroll;
`;

export const SideBarHeadWrapper = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 16px;
    border-bottom: 1px solid #e6ebed;
`;

export const SideBarContentWrapper = styled.div`
    height: calc(100% - 80px);
`;

export const ModalWrapper = styled.div<{
    $sizeModal: TSizeModal;
    $type: TTypeModal;
}>`
    position: fixed;
    z-index: 999;
    ${(props) => props.$type === 'Default' && typeDefault};
    ${(props) => props.$type === 'Unboarded' && typeUnboarded};
    ${(props) => props.$type !== 'LiteBox' && props.$sizeModal === 'Rubber' && sizeModalRubber};
    ${(props) => props.$type !== 'LiteBox' && props.$sizeModal === 'Small' && sizeModalSmall};
    ${(props) => props.$type !== 'LiteBox' && props.$sizeModal === 'Medium' && sizeModalMedium};
    ${(props) => props.$type !== 'LiteBox' && props.$sizeModal === 'Large' && sizeModalLarge};
    ${(props) => props.$type !== 'LiteBox' && props.$sizeModal === 'Max' && sizeModalMax};
    ${(props) => props.$type !== 'LiteBox' && props.$sizeModal === 'Huge' && sizeModalHuge};
    background-color: #fff;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    max-height: 100%;

    ${mq.lt.md} {
        overflow-y: scroll;
    }
`;

export const ModalTitleWrapper = styled.div<{
    type: TTypeModal;
}>`
    ${(props) => (props.type === 'Unboarded' ? unboardedTitleWrapper : titleWrapper)};

    > div {
        &:first-child {
            font-size: 20px;
            font-weight: 500;
            color: ${({ theme }) => theme.color.dark};
            line-height: 22px;
        }

        &:last-child {
            margin-left: 40px;

            div {
                cursor: pointer;

                svg {
                    fill: ${(props) => props.type === 'Unboarded' && props.theme.color.neutral400};
                }
            }
        }
    }
`;

export const ModalContentWrapper = styled.div`
    width: 100%;
`;
