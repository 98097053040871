import { styled } from 'styled-components';

import { mq } from '@/styles/media.styled';

export const Wrapper = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 24px 24px 32px;
    min-width: 40%;
    ${mq.lt.lg} {
        width: 100%;
    }

    background: linear-gradient(218.13deg, #63d07b 18.93%, #0bb6ba 94.11%);
    box-shadow: 0 4px 12px 0 #00000014;
    border-radius: 12px;
    font-weight: 500;
    font-size: ${({ theme }) => theme.fontSize.lg};
    line-height: 120%;
    color: ${({ theme }) => theme.color.secondary};

    .content {
        width: 100%;
        height: 100%;

        .dynamic-fields-block {
            margin-top: 16px;
        }

        .form-item-field {
            margin-bottom: 16px;

            label {
                color: #fff;
            }
        }

        .dynamic-fields-block,
        .form-item-field {
            input {
                font-size: inherit;
                font-weight: 500;
            }
        }

        .notation-text {
            color: #fff;
            display: flex;
            flex-direction: column;
            font-weight: 400;
            font-size: 14px;
            line-height: 116%;
            margin-top: 6.5px;
            gap: 6px;
        }

        .ant-tabs-nav .ant-tabs-nav-wrap .ant-tabs-nav-list {
            display: flex;
            justify-content: center;
            height: 50px;
        }

        .ant-tabs-tab {
            display: flex;
            flex-basis: 49%;
            text-align: center !important;
            justify-content: center;
        }

        .ant-tabs-nav {
            .ant-tabs-nav-list {
                width: 100%;
                gap: 10px;

                > .ant-tabs-tab {
                    width: 50%;
                    display: flex;
                    justify-content: center;
                    ${mq.lt.sm} {
                        > div {
                            font-size: 14px;
                        }

                        &.ant-tabs-tab-active {
                            > div {
                                font-size: 16px;
                            }
                        }
                    }
                    ${mq.lt.xs} {
                        > div {
                            font-size: 12px;
                        }

                        &.ant-tabs-tab-active {
                            > div {
                                font-size: 14px;
                            }
                        }
                    }
                }
            }

            .ant-tabs-tab + .ant-tabs-tab {
                margin: 0;
            }
        }

        .selector {
            margin-top: 15px;
        }

        .checkbox {
            margin-top: 19px;
        }

        .submit-btn {
            margin-top: 2rem;

            .button-text {
                max-width: 100%;
            }
        }
    }

    form {
        .ant-form-item-row {
            flex-direction: column;
            align-items: flex-start;

            .ant-form-item-label {
                label {
                    color: white;
                }
            }

            .ant-form-item-control {
                min-height: initial;
                width: 100%;
            }
        }
    }

    .ant-form-item {
        margin-bottom: 16px;
    }
`;
