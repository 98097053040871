import { captureException, setExtra } from '@sentry/nextjs';
import { CaptureContext } from '@sentry/types';
import { AxiosError } from 'axios';

import { APP_DEBUG } from '@/shared/config/app';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const customCaptureException = (error: any, name: string, captureContext?: CaptureContext) => {
    if (error instanceof AxiosError && error?.response?.data?.message) {
        const rebuildedError = new AxiosError(
            error?.response?.data?.message,
            error?.code,
            error?.config,
            error?.request,
            error?.response,
        );
        setExtra('error', error);
        captureException(rebuildedError, captureContext);
    } else {
        setExtra('error', error);
        captureException(error, captureContext);
    }

    // eslint-disable-next-line no-console
    console.groupCollapsed(`error in - %c${name}`, 'color: #FD1C03; font-size: 14px;');
    // eslint-disable-next-line no-console
    console.log('ERROR');
    // eslint-disable-next-line no-console
    if (error instanceof Error) {
        // eslint-disable-next-line no-console
        console.error(error.message);
        if (APP_DEBUG) {
            // eslint-disable-next-line no-console
            console.log('FUll ERROR');
            // eslint-disable-next-line no-console
            console.error(error);
        }
    } else {
        // eslint-disable-next-line no-console
        console.error('Unknown Error');
        // eslint-disable-next-line no-console
        console.error(error);
    }
    // eslint-disable-next-line no-console
    console.groupEnd();
};
