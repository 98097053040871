import cn from 'classnames';
import React, { ReactNode, useEffect, useState } from 'react';

import { useAppSelector } from '@/store';

import { isProduction } from '@/shared/config/app';
import { useTranslate } from '@/shared/hooks/use-translate';
import { formatCurrency } from '@/shared/lib/currency';
import { TCalculatorProducts } from '@/shared/types/calculator';

import { EPawnshopCategoryType } from '../../constants';
import { overpaymentCalculator } from '../../lib/overpayment-calculator';

type THookReturn = {
    titleLeft: string | ReactNode | null;
    titleLeftSub: string | ReactNode | null;
    titleRight: string | ReactNode | null;
    titleRightSub: string | ReactNode | null;
};

export const useCalculationResultOverpaymentCalculator = (): THookReturn => {
    const [resultCalculation, setResultCalculation] = useState<null | THookReturn>();
    const type = useAppSelector((state) => state.estimateForm.form.type);
    const { data, products, selectedKp } = useAppSelector((state) => state.estimateForm.overpaymentCalculator);

    const { t } = useTranslate();

    // Кол-во льготных дней
    const getCountGraceDays = (selectKp: TCalculatorProducts, availableNumberGraceDays: number) => {
        switch (true) {
            // Если Выбранный срок >= Завершающий день льготного периода тогда Доступное кол-во льготных дней
            case data.selectTerm >= selectKp.graceLoan.lastDay:
                return availableNumberGraceDays;

            // Если Начальный день льготного периода <= Выбранный срок < Завершающий день льготного периода
            case selectKp.graceLoan.firstDay <= data.selectTerm && data.selectTerm < selectKp.graceLoan.lastDay:
                return data.selectTerm + 1 - selectKp.graceLoan.firstDay;

            // Если Выбранный срок < Начальный день льготного периода
            case data.selectTerm < selectKp.graceLoan.firstDay:
                return 0;

            default:
                return 0;
        }
    };

    // Предыдущая ставка вознаграждения
    const getPreviousInterestRate = (): number => {
        const selectKp = selectedKp as TCalculatorProducts;
        const key = products.indexOf(selectKp);

        if (key === 0) {
            return 0;
        }
        return products[key - 1].percentage / 100;
    };

    const getInfoRiteBlock = (
        greenSum: number,
        darkSum: number,
        isNewClent = true,
    ): { subTitle: JSX.Element; title: JSX.Element } => {
        const title = (
            <>
                <span className="inf">
                    <span>
                        {t('К возврату через')}
                        {` `}
                    </span>

                    <span className="term">{`${data.selectTerm} ${overpaymentCalculator.numWordTerm(
                        data.selectTerm,
                    )}`}</span>
                </span>

                {darkSum > 0 ? (
                    <span className={cn('price new')}>
                        <span>&nbsp;—&nbsp;</span>
                        {formatCurrency(Math.ceil(darkSum))}&nbsp;₸
                    </span>
                ) : null}
            </>
        );
        const subTitle = (
            <>
                <span className="price-returned">{formatCurrency(Math.ceil(greenSum))} ₸</span>

                {isNewClent ? <span className="new-client">для новых клиентов 🥰</span> : ''}
            </>
        );

        return {
            subTitle,
            title,
        };
    };

    // Минимальная сумма является максимальным среди остальных КП по Максимальная сумма
    const isSelectKpMinAmountInterProductsMaxAmount = (): boolean => {
        const selectKp = selectedKp as TCalculatorProducts;
        const _products = products.filter(
            (item: TCalculatorProducts) => item.id !== selectKp.id && selectKp.minAmount > item.maxAmount,
        );
        return products.length - 1 === _products.length;
    };

    const calculation = () => {
        const selectKp = selectedKp as TCalculatorProducts;
        const isGraceLoan = !!selectKp.graceLoan;

        // Ставка вознаграждения
        const remunerationRate = selectKp.percentage / 100;
        // Льготная ставка
        const preferentialRate = isGraceLoan ? selectKp.graceLoan.percentage / 100 : 1;
        // Доступное кол-во льготных дней
        const availableNumberGraceDays = isGraceLoan ? selectKp.graceLoan.lastDay + 1 - selectKp.graceLoan.firstDay : 0;
        // Кол-во льготных дней
        const countGraceDays = isGraceLoan ? getCountGraceDays(selectKp, availableNumberGraceDays) : 0;
        // Выбранный срок без льготных дней
        const countWithoutGraceDays = data.selectTerm - countGraceDays;
        // Предыдущая ставка вознаграждения
        const previousInterestRate = getPreviousInterestRate();

        if (!isProduction()) {
            /* eslint-disable  no-console */
            console.log('Список кп -', products);
            console.log('Выбранный кп -', selectKp);
            console.log('Ставка вознаграждения -', remunerationRate);
            console.log('Льготная ставка -', preferentialRate);
            console.log('Доступное кол-во льготных дней -', availableNumberGraceDays);
            console.log('Кол-во льготных дней -', countGraceDays);
            console.log('Выбранный срок без льготных дней -', countWithoutGraceDays);
            console.log('Предыдущая ставка вознаграждения -', previousInterestRate);
        }

        let darkSum = 0;
        let greenSum = 0;

        let titleLeft = null;
        let titleLeftSub = null;
        let titleRight = null;
        let titleRightSub = null;

        if (type === EPawnshopCategoryType.APPLIANCE && isGraceLoan) {
            titleLeft = 'Для новых клиентов при залоге техники';
            titleLeftSub = (
                <>
                    Первые {selectKp.graceLoan.lastDay} дней под{' '}
                    {selectKp.graceLoan.percentage.toString().replace('.', ',')}
                    <span className="symbol-percent">%</span>
                </>
            );
        }

        if (type === EPawnshopCategoryType.JEWEL && isGraceLoan) {
            titleLeft = `Для новых клиентов при залоге золота на ${selectKp.maxTerm} дней`;
            titleLeftSub = (
                <>
                    Последние {availableNumberGraceDays} дней под{' '}
                    {selectKp.graceLoan.percentage.toString().replace('.', ',')}
                    <span className="symbol-percent">%</span>
                </>
            );
        }

        /* -------------- Кейс 1 ------------*/
        // Если КП где Данные по льготе не null и Кол-во льготных дней > 0
        if (isGraceLoan && countGraceDays > 0) {
            if (!isProduction()) {
                /* eslint-disable  no-console */
                console.log('Кейс 1 --- Если КП где Данные по льготе не null и Кол-во льготных дней > 0');
            }
            // Зеленная сумма - формула:
            // greenSum = Выбранная сумма + (Выбранная сумма х Кол-во льготных дней х Льготная ставка) + (Выбранная сумма х Выбранный срок без льготных дней х Ставка вознаграждения);
            greenSum =
                data.selectAmount +
                data.selectAmount * countGraceDays * preferentialRate +
                data.selectAmount * countWithoutGraceDays * remunerationRate;

            // Черная сумма - формула:
            // darkSum = Выбранная сумма + (Выбранная сумма х Выбранный срок х Ставка вознаграждения);
            darkSum = data.selectAmount + data.selectAmount * data.selectTerm * remunerationRate;
            const { subTitle, title } = getInfoRiteBlock(greenSum, darkSum);
            titleRight = title as ReactNode;
            titleRightSub = subTitle;
        }

        /* --------------  Кейс 2 -------------*/
        // Для золота, если Кол-во льготных дней = 0
        if (type === EPawnshopCategoryType.JEWEL && countGraceDays === 0) {
            if (!isProduction()) {
                /* eslint-disable  no-console */
                console.log('Кейс 2 --- Для золота, если Кол-во льготных дней = 0');
            }
            // Зеленная сумма - формула:
            // greenSum = Выбранная сумма + (Выбранная сумма х Выбранный срок без льготных дней х Ставка вознаграждения);

            greenSum = data.selectAmount + data.selectAmount * countWithoutGraceDays * remunerationRate;
            darkSum = 0;
            const { subTitle, title } = getInfoRiteBlock(greenSum, darkSum, false);
            titleRight = title;
            titleRightSub = subTitle;
        }

        /* --------------  Кейс 3 --------------*/
        // Если в КП Данные по льготе является null, Минимальная сумма является максимальным среди остальных КП по Максимальная сумма, имеется Предыдущая ставка вознаграждения
        if (!isGraceLoan && previousInterestRate > 0 && isSelectKpMinAmountInterProductsMaxAmount()) {
            if (!isProduction()) {
                /* eslint-disable  no-console */
                console.log(
                    'Кейс 3 --- Если в КП Данные по льготе является null, Минимальная сумма является максимальным среди остальных КП по Максимальная сумма, имеется Предыдущая ставка вознаграждения',
                );
            }
            // Зеленная сумма - формула:
            // greenSum = Выбранная сумма + (Выбранная сумма * Выбранный срок * Ставка вознаграждения)
            greenSum = data.selectAmount + data.selectAmount * data.selectTerm * remunerationRate;

            // Черная сумма - формула:
            // darkSum = Выбранная сумма + (Выбранная сумма * Выбранный срок * Предыдущая ставка вознаграждения)
            darkSum = data.selectAmount + data.selectAmount * data.selectTerm * previousInterestRate;

            const { subTitle, title } = getInfoRiteBlock(greenSum, darkSum, false);

            titleRight = title;
            titleRightSub = subTitle;

            titleLeft =
                type === EPawnshopCategoryType.APPLIANCE
                    ? `При залоге техники свыше ${formatCurrency(selectKp.minAmount)} ₸`
                    : `При залоге золота свыше ${formatCurrency(selectKp.minAmount)} ₸`;
            titleLeftSub = (
                <>
                    Ставка в день — {selectKp.percentage.toString().replace('.', ',')}
                    <span className="symbol-percent">%</span>
                </>
            );
        }

        /* -------------- Кейс 4 -------------------*/
        // Если в КП Данные по льготе является null, Минимальная сумма не является максимальной среди остальных КП по Максимальная сумма или нет Предыдущая ставка вознаграждения
        if (!isGraceLoan && (!isSelectKpMinAmountInterProductsMaxAmount() || previousInterestRate === 0)) {
            if (!isProduction()) {
                /* eslint-disable  no-console */
                console.log(
                    'Кейс 4 --- Если в КП Данные по льготе является null, Минимальная сумма не является максимальной среди остальных КП по Максимальная сумма или нет Предыдущая ставка вознаграждения',
                );
            }
            titleLeft = '';
            titleLeftSub = '';

            // Зеленная сумма - формула:
            // greenSum = Выбранная сумма + (Выбранная сумма * Выбранный срок * Ставка вознаграждения)
            greenSum = data.selectAmount + data.selectAmount * data.selectTerm * remunerationRate;
            darkSum = 0;

            const { subTitle, title } = getInfoRiteBlock(greenSum, darkSum, false);

            titleRight = title;
            titleRightSub = subTitle;
        }

        return {
            titleLeft,
            titleLeftSub,
            titleRight,
            titleRightSub,
        };
    };

    useEffect(() => {
        setResultCalculation(calculation());
    }, [data]);

    return { ...(resultCalculation as THookReturn) };
};
