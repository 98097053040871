import { Input } from 'antd';
import { InputProps, InputRef } from 'antd/es/input/Input';
import React, { useCallback } from 'react';

import { removePropsFromObject } from '@/shared/lib/object';
import { TAntdFormItemReturnProps } from '@/shared/types/antd';
import { Spinner } from '@/shared/ui/spinner';

import { REGEXP_TYPE_NUMERIC } from './constants';

export type TInputAntdProps = Omit<InputProps & React.RefAttributes<InputRef>, 'value' | 'onChange'> & {
    formItemProps?: TAntdFormItemReturnProps;
    loading?: boolean;
    onChange?: (value: string) => void;
    regexp?: RegExp;
    style?: React.CSSProperties;
    value?: string;
};

export const InputAntd = (props: TInputAntdProps) => {
    const { allowClear = true, formItemProps, loading, onChange, placeholder, regexp, value: valueProp } = props;

    const value = formItemProps?.value || valueProp || '';

    const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const { value: inputValue } = e.target;

        if (regexp) {
            if (regexp.test(inputValue) || inputValue === '') {
                let v = inputValue;

                /**
                 * Костыль для цифрового инпута:
                 * https://youtrack.dengigroup.kz/issue/MRKZ-17/Avtootsenka-Ispolzovanie-logiki-avtootsenki-na-kalkulyatore-8#focus=Comments-4-15161.0-0
                 *
                 * TODO: обработать данный кейс после рефакторинга API полей формы калькулятора
                 */
                if (regexp.toString() === REGEXP_TYPE_NUMERIC.toString()) {
                    v = v.replaceAll(',', '.');
                }

                onChange?.(v);
                formItemProps?.onChange?.(v);
            }
            return;
        }

        onChange?.(inputValue);
        formItemProps?.onChange?.(inputValue);
    };

    const SuffixIcon = useCallback(() => {
        if (loading) {
            return <Spinner />;
        }

        if (props.suffix) {
            return <>{props.suffix}</>;
        }

        return null;
    }, [loading, props.suffix]);

    return (
        <Input
            {...formItemProps}
            {...removePropsFromObject({
                obj: props,
                props: ['formItemProps'],
            })}
            allowClear={allowClear}
            maxLength={props.maxLength || 16}
            onChange={handleChange}
            placeholder={placeholder}
            suffix={<SuffixIcon />}
            value={value}
        />
    );
};
