import { Placemark } from '@pbe/react-yandex-maps';
import { AnyObject, WithInstanceRef } from '@pbe/react-yandex-maps/typings/util/typing';
import React, { FC, memo } from 'react';

type TProps = {
    coordinates: TCoordinates;
    openBallon?: boolean;
    properties?: AnyObject | WithInstanceRef;
};

const CustomPlacemark: FC<TProps> = (props) => {
    const { coordinates, openBallon = false, properties = {} } = props;

    const shownCoords = coordinates.lat ? [Number(coordinates.lat), Number(coordinates.lon)] : [];
    return (
        <Placemark
            geometry={shownCoords}
            instanceRef={(ref) => {
                if (openBallon && ref) {
                    setTimeout(() => {
                        ref.balloon.open();
                    }, 1000);
                }
            }}
            properties={properties}
        />
    );
};

export default memo(CustomPlacemark);
