import { ZoomControl } from '@pbe/react-yandex-maps';
import React, { FC } from 'react';

import { YandexMap } from '@/shared/ui/ya-map';
import CustomPlacemark from '@/shared/ui/ya-map/placemark';

import { TOffice } from '../../types';

import * as Styled from './styled';

const balloonContentFunc = (point: TOffice) => `
        <div>
            <div>
                ${`${point?.city}, ${point?.address}`}
            </div>
            <div>
                ${point?.workTime}
            </div>
        </div>`;

type TProps = {
    offices: TOffice[];
    selectPointMap: TCoordinates;
    zoom?: number;
};

export const OfficeMap: FC<TProps> = (props) => {
    const { offices, selectPointMap, zoom } = props;

    return (
        <Styled.Wrapper>
            <YandexMap
                center={selectPointMap}
                modules={[
                    'geoObject.addon.balloon',
                    'templateLayoutFactory',
                    'layout.ImageWithContent',
                    'geoObject.addon.hint',
                ]}
                zoom={zoom || 5}
            >
                <ZoomControl />

                {offices?.map((office, index) => (
                    <CustomPlacemark
                        coordinates={office.location}
                        key={index}
                        openBallon={zoom === 15 && office.location === selectPointMap}
                        properties={{
                            balloonContent: balloonContentFunc(office),
                            hintContent: `<b>${office.address}</b>`,
                        }}
                    />
                ))}
            </YandexMap>
        </Styled.Wrapper>
    );
};
