import { Collapse } from 'antd';
import { styled, css } from 'styled-components';

import { ECollapseAntVariant } from './types';
const { Panel } = Collapse;

const cssDefaultVariant = css`
    background-color: #fff;

    .ant-collapse-item {
        border-bottom: none !important;

        .ant-collapse-header {
            padding: 16px 0;
        }
    }
`;

export const Wrapper = styled(Collapse)<{ variant: ECollapseAntVariant }>`
    ${(props) => props.variant === ECollapseAntVariant.DEFAULT && cssDefaultVariant};
`;

export const PanelWrapper = styled(Panel)`
    .ant-collapse-item {
        color: initial;
    }
`;
