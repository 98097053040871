import { createSlice, current } from '@reduxjs/toolkit';

import { customCaptureException } from '@/shared/lib/custom-capture-exception';
import { TCalculatorProducts } from '@/shared/types/calculator';

import { ECalculationEstimateStatus, EPawnshopCategoryType, ESubmitStatusEstimateForm } from '../constants';
import { overpaymentCalculator } from '../lib/overpayment-calculator';
import { TEstimateFormState } from '../types';

import { loadPawnshopCategories } from './action-creators';

const initialState: TEstimateFormState = {
    categories: [],
    form: {
        amountEstimate: 0,
        lead: null,
        status: ECalculationEstimateStatus.EMPTY,
        submitStatus: ESubmitStatusEstimateForm.await,
        type: null,
        values: {
            isExtraCharge: false,
            options: {},
        },
    },
    loaded: false,
    overpaymentCalculator: {
        data: {
            maxAmount: 0,
            maxTerm: 0,
            minAmount: 0,
            minTerm: 0,
            selectAmount: 0,
            selectTerm: 0,
            stepAmount: 0,
            stepTerm: 0,
        },
        products: [],
        selectedKp: null,
    },
};

export const estimateFormSlice = createSlice({
    extraReducers: (builder) => {
        builder.addCase(loadPawnshopCategories.fulfilled, (state, action) => {
            state.loaded = true;
            state.categories = action.payload.data;
        });
    },
    initialState,
    name: 'estimateForm',
    reducers: {
        resetDataEstimateForm(state) {
            state.form.type = null;
            state.form.submitStatus = ESubmitStatusEstimateForm.await;
        },
        setDataEstimateForm(state, action) {
            state.form.submitStatus = ESubmitStatusEstimateForm.completed;
            state.form.amountEstimate = action.payload.amountEstimate ?? 0;
            state.form.lead = action.payload.lead ?? null;
            state.form.status = action.payload.status ?? ECalculationEstimateStatus.EMPTY;
            state.form.values = action.payload.values;
        },
        setInitOverpaymentCalculator(state, action) {
            try {
                state.overpaymentCalculator.products = (action.payload as TCalculatorProducts[]).sort(
                    (a, b) => b.percentage - a.percentage,
                );
                const data = overpaymentCalculator.init(
                    action.payload,
                    state.form.amountEstimate,
                    state.form.type as EPawnshopCategoryType,
                );
                state.overpaymentCalculator.data = { ...data };
                state.overpaymentCalculator.selectedKp = overpaymentCalculator.selectedKpBySum(
                    action.payload,
                    data.selectAmount,
                );
            } catch (e) {
                customCaptureException(e, 'estimateFormSlice');
            }
        },
        setSelectedAmountOverpaymentCalculator(state, action) {
            const data = overpaymentCalculator.selectedKpBySum(
                current(state.overpaymentCalculator.products),
                action.payload,
            );
            const selectTerm = state.overpaymentCalculator.data.selectTerm;
            state.overpaymentCalculator.data.selectTerm = selectTerm > data.maxTerm ? data.maxTerm : selectTerm;
            state.overpaymentCalculator.data.stepTerm = data.stepTerm;
            state.overpaymentCalculator.data.maxTerm = data.maxTerm;
            state.overpaymentCalculator.data.minTerm = 1;
            state.overpaymentCalculator.data.stepAmount = data.stepAmount;
            state.overpaymentCalculator.data.selectAmount = action.payload;
            state.overpaymentCalculator.selectedKp = data;
        },
        setSelectedTermOverpaymentCalculator(state, action) {
            state.overpaymentCalculator.data.selectTerm = action.payload;
        },
        setStatusEstimateForm(state, action) {
            state.form.status = action.payload;
        },
        setSubmitStatusEstimateForm(state, action) {
            state.form.submitStatus = action.payload;
        },
        setTypeEstimateForm(state, action) {
            state.form.type = action.payload;
        },
    },
});

export const {
    resetDataEstimateForm,
    setDataEstimateForm,
    setInitOverpaymentCalculator,
    setSelectedAmountOverpaymentCalculator,
    setSelectedTermOverpaymentCalculator,
    setStatusEstimateForm,
    setSubmitStatusEstimateForm,
    setTypeEstimateForm,
} = estimateFormSlice.actions;

export const estimateFormSliceReducer = estimateFormSlice.reducer;
