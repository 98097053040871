import { DefaultOptionType } from 'rc-select/es/Select';

import { ECategoryOptionFiledType } from '../constants';
import {
    TCategoryOptionChoices,
    TFieldOption,
    TPawnshopCategory,
    TPawnshopCategoryOption,
    TRequestCollateralOption,
    TRequestEstimateCondition,
} from '../types';

const getOptionsPawnshopCategory = (items: TPawnshopCategory[]): DefaultOptionType[] => {
    return items.map((item) => {
        return {
            label: item.name,
            value: item.id,
        };
    });
};

const getCategoryOptionChoices = (items: TCategoryOptionChoices[]) => {
    return items.map((item) => {
        return {
            label: item.value,
            value: item.value,
        };
    });
};

const getRequestCalculationOptionsEstimate = (options: TFieldOption): TRequestEstimateCondition[] => {
    return Object.keys(options).map((key) => {
        return {
            name: key,
            value: Array.isArray(options[key]) ? (options[key] as string[]).join(',') : (options[key] as string),
        };
    });
};

const getRequestCollateralOptionsLead = (
    options: TFieldOption,
    categoryOptions: TPawnshopCategoryOption[],
): TRequestCollateralOption[] => {
    return Object.keys(options).map((key) => {
        const findOption = categoryOptions.find((item) =>
            item.type === ECategoryOptionFiledType.INPUT
                ? item.name === key
                : item.choices.find((choice) =>
                      Array.isArray(options[key])
                          ? (options[key] as string[]).includes(choice.value)
                          : choice.value === options[key],
                  ),
        );

        return {
            choices: Array.isArray(options[key]) ? (options[key] as string[]) : [options[key] as string],
            option_id: (findOption as TPawnshopCategoryOption).saascreditId,
        };
    });
};

export const categoryOption = {
    getRequestCollateralOptionsLead,
    getRequestCalculationOptionsEstimate,
    getCategoryOptionChoices,
    getOptionsPawnshopCategory,
};
