import { useRouter } from 'next/router';
import React, { FC } from 'react';

import { useAppSelector } from '@/store';

import { useTranslate } from '@/shared/hooks/use-translate';
import { numWord } from '@/shared/lib/string';
import { CollapseAnt } from '@/shared/ui/collapse-ant';

import { processing } from '../../lib/processing';
import { TOffice, TRegion } from '../../types';
import { OfficeListItem } from '../office-list-item';

import * as Styled from './styled';

type TProps = {
    offices: TOffice[];
    onSelectPointMap: (coordinates: TCoordinates) => void;
};

export const OfficeList: FC<TProps> = ({ offices, onSelectPointMap }) => {
    const officeCount = useAppSelector((state) => state.settings.officeCount);
    const { t } = useTranslate();
    const regions = processing.groupRegion(offices);

    const items = regions?.map((item: TRegion, index: number) => {
        return {
            children: <OfficeListItem items={item.offices} onSelectPointMap={onSelectPointMap} />,
            label: item.region,
            key: index.toString(),
        };
    });

    const route = useRouter();
    const { locale } = route;
    const word = numWord(officeCount, ['Отделение', 'Отделения', 'Отделений']);

    const officeCountTitle =
        locale === 'ru' ? `${officeCount} ${word} ${t('headerTitle')}` : t('headerTitleKz', { count: officeCount });

    return (
        <Styled.Wrapper className="office-list-block">
            <div className="title">{officeCountTitle}</div>
            <CollapseAnt accordion bordered={false} expandIconPosition="end" items={items} />
        </Styled.Wrapper>
    );
};
