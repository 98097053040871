import { combineReducers } from '@reduxjs/toolkit';

import { siteModalSliceReducer } from '@/store/reducers/modal/slice';
import { settingsSiteSliceReducer } from '@/store/reducers/settings-site/slice';

import { estimateFormSliceReducer } from '@/features/estimate-form';

export const rootReducer = combineReducers({
    estimateForm: estimateFormSliceReducer,
    settings: settingsSiteSliceReducer,
    siteModal: siteModalSliceReducer,
});

export { closeModal, showModal } from './modal/slice';
