import { createAsyncThunk } from '@reduxjs/toolkit';
import { AxiosError } from 'axios';

import { customCaptureException } from '@/shared/lib/custom-capture-exception';

import { getPawnshopCategoriesCalc } from '../api';

const loadPawnshopCategories = createAsyncThunk(
    'settings/loadPawnshopCategories',
    async function (_, { rejectWithValue }) {
        try {
            const response = await getPawnshopCategoriesCalc();
            return { data: response.data };
        } catch (error) {
            customCaptureException(error, 'loadPawnshopCategories');
            return rejectWithValue((error as AxiosError)?.response?.data);
        }
    },
);

export { loadPawnshopCategories };
