import cn from 'classnames';
import { useFormik } from 'formik';
import { FC, useMemo, useState } from 'react';
import * as Yup from 'yup';

import { useAppSelector } from '@/store';

import { PHONE_LENGTH } from '@/shared/constants/settings';
import { useTranslate } from '@/shared/hooks/use-translate';
import { Button } from '@/shared/ui/button';
import { Checkbox } from '@/shared/ui/checkbox';
import { InputField } from '@/shared/ui/input/input-field';
import { SelectAntd } from '@/shared/ui/select-antd';

import { EPawnshopCategoryType, textHelpCategoryType } from '../../constants';
import { useOptionsPawnshopCategory } from '../../hooks/use-options-pawnshop-category';
import { useSubmitEstimateForm } from '../../hooks/use-submit-estimate-form';
import { categoryOption } from '../../lib/category-option';
import { LoaderBack } from '../loader-back';

type TProps = {
    idSubmitButton: string;
    type: EPawnshopCategoryType;
};

export const TabEstimateItem: FC<TProps> = ({ idSubmitButton, type }) => {
    const categories = useAppSelector((state) => state.estimateForm.categories).filter((item) => item.type === type);
    const categoryOptions = useMemo(() => categoryOption.getOptionsPawnshopCategory(categories), [categories]);
    const [selectedMainCategory, setSelectedMainCategory] = useState<string | null>(null);
    const { t } = useTranslate();
    const helper = useMemo(() => {
        return textHelpCategoryType[type];
    }, [type]);
    const { dynamicFields, fieldTemplate, isLoadingOptions, onFetchOptionsByCategory } = useOptionsPawnshopCategory();

    const { isSubmitForm, onSubmitEstimateForm } = useSubmitEstimateForm();

    const formik = useFormik({
        enableReinitialize: true,
        initialValues: {
            category: selectedMainCategory,
            isExtraCharge: false,
            options: dynamicFields.initial,
            phone: '',
        },
        isInitialValid: false,
        onSubmit: async (values) => {
            await onSubmitEstimateForm(values, dynamicFields.fields, type);
        },
        validateOnBlur: false,
        validateOnChange: true,
        validateOnMount: false,
        validationSchema: Yup.object().shape({
            category: Yup.string().nullable().required('Выберите категорию'),
            options: dynamicFields.validationSchema,
            phone: Yup.string().test('phone', 'Невалидный номер', (value) => {
                return value?.replace(/\D+/g, '').length === PHONE_LENGTH;
            }),
        }),
    });

    const handleSelectMainCategory = async (id: number) => {
        await onFetchOptionsByCategory(id);
    };

    return (
        <form onSubmit={formik.handleSubmit}>
            <SelectAntd
                disabled={isLoadingOptions}
                height="LG"
                labelInValue={false}
                loading={isLoadingOptions}
                onChange={(value) => {
                    formik.setFieldValue('category', value as string);
                    handleSelectMainCategory(value as number);
                    setSelectedMainCategory(value as string);
                }}
                options={categoryOptions}
                placeholder={t(helper.selectMainTitleStr)}
                title={t(helper.selectMainTitleStr)}
                value={formik.values.category}
            />

            {isLoadingOptions && <LoaderBack />}

            {!isLoadingOptions && (
                <>
                    <div className={cn('dynamic-fields-block')}>
                        {dynamicFields.fields.length > 0 && (
                            <>
                                {dynamicFields.fields.map((filed) => {
                                    const error = formik.errors?.options
                                        ? formik.errors?.options[filed.name] ?? ''
                                        : '';
                                    return fieldTemplate(
                                        filed,
                                        formik.setFieldValue,
                                        formik.values.options[filed.name],
                                        formik.dirty ? error : '',
                                    );
                                })}
                            </>
                        )}

                        {selectedMainCategory && (
                            <div className="form-item-field">
                                <InputField
                                    label={t('Укажите номер телефона:')}
                                    mask="+7 (000) 000-00-00"
                                    name="phone"
                                    onChange={formik.handleChange}
                                    placeholder="+7 (___) ___-__-__"
                                    size="lg"
                                    value={formik.values.phone}
                                />
                            </div>
                        )}
                    </div>
                    <div className="checkbox form-item-field">
                        <Checkbox
                            checked={formik.values.isExtraCharge}
                            label={t(helper.checkboxTitleStr)}
                            name={`${type}-isExtraCharge`}
                            onChange={() => {
                                formik.setFieldValue('isExtraCharge', !formik.values.isExtraCharge);
                            }}
                        />
                    </div>
                    <div className="notation-text">
                        <div>{t(helper.notationCalcTextStr)}</div>

                        <div className="sub-text">{t('notationCalcText_p2')}</div>
                    </div>

                    <div className="submit-btn">
                        <Button
                            className="button-text"
                            disabled={isLoadingOptions || !formik.isValid || isSubmitForm}
                            id={idSubmitButton}
                            isLoading={isSubmitForm}
                            stretch
                            type="submit"
                            variant="calc-button"
                        >
                            {t(helper.submitTitleStr)}
                        </Button>
                    </div>
                </>
            )}
        </form>
    );
};
