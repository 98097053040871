import { styled } from 'styled-components';

export const Wrapper = styled.div`
    display: flex;
    width: 100%;
    align-items: center;
    justify-content: center;
    padding: 64px 32px;
    border-radius: 12px;
    background-color: RGBA(${({ theme }) => theme.color.dark}, 20%);
`;
