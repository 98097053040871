import React, { FC } from 'react';

import { EstimateForm } from '../estimate-form';

import * as Styled from './styled';

type TProps = {
    hasMobile?: boolean;
};

export const ArticleEstimateForm: FC<TProps> = ({ hasMobile = false }) => {
    return (
        <Styled.Wrapper>
            <div className="estimate-layout">
                <EstimateForm hasMobile={hasMobile} />
            </div>
        </Styled.Wrapper>
    );
};
