import cn from 'classnames';
import React, { FC, RefObject } from 'react';

import { Spinner } from '../spinner';

import * as Styled from './styled';

export type TVariantButton = 'unborded' | 'brand-green-unborded' | 'gradient-brand-green' | 'calc-button';

type TProps = React.ComponentProps<'button'> & {
    disabled?: boolean;
    isLoading?: boolean;
    stretch?: boolean;
    variant?: TVariantButton;
};

export const Button: FC<TProps> = (props) => {
    const {
        children,
        disabled = false,
        isLoading = false,
        onClick = () => null,
        ref,
        stretch = false,
        variant = 'brand-green-unborded',
        ...rest
    } = props;

    return (
        <Styled.Wrapper
            {...rest}
            className={cn(rest.className, { disabled: disabled || isLoading })}
            $disabled={disabled || isLoading}
            onClick={onClick}
            ref={ref as RefObject<HTMLButtonElement>}
            $stretch={stretch}
            $variant={variant}
        >
            {isLoading && <Spinner size="small" />}
            {children}
        </Styled.Wrapper>
    );
};
