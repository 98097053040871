import { styled } from 'styled-components';

import { mq } from '@/styles/media.styled';

export const Wrapper = styled.div`
    min-width: 40%;
    height: 100%;
    ${mq.lt.lg} {
        width: 100%;
    }

    .spacing-xr-xxs {
        margin-right: 0.37rem;
    }

    .gadget-image {
        width: 100%;
        position: relative;
        height: 250px;
        ${mq.lt.md} {
            height: 200px;
        }

        img {
            object-fit: contain;
        }
    }

    .text {
        height: fit-content;
    }
`;
