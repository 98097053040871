import React from 'react';

import { TSvgIcon } from '@/shared/types/types';

const TengeIcon: TSvgIcon = (props) => {
    const { height = 29, width = 28 } = props;

    return (
        <svg fill="none" height={height} viewBox="0 0 29 28" width={width} xmlns="http://www.w3.org/2000/svg">
            <rect fill="#42CE99" fillOpacity="0.1" height={height} rx="4" width={width} x="0.5" />

            <path
                d="M7.5 6.28571C7.5 6.99583 8.12676 7.57143 8.9 7.57143H20.1C20.8732 7.57143 21.5 6.99583 21.5 6.28571C21.5 5.5756 20.8732 5 20.1 5H8.9C8.12685 5 7.5 5.57568 7.5 6.28571Z"
                fill="#14AE51"
            />

            <path
                d="M8.9 12.0714H13.1V21.7143C13.1 22.4244 13.7268 23 14.5 23C15.2732 23 15.9 22.4244 15.9 21.7143V12.0714H20.1C20.8732 12.0714 21.5 11.4958 21.5 10.7857C21.5 10.0756 20.8732 9.5 20.1 9.5H8.9C8.12676 9.5 7.5 10.0756 7.5 10.7857C7.5 11.4958 8.12685 12.0714 8.9 12.0714Z"
                fill="#14AE51"
            />
        </svg>
    );
};

export default TengeIcon;
