import { useEffect } from 'react';
import { createPortal } from 'react-dom';

import { Spinner } from '@/shared/ui/spinner';

import { useRouteChange } from '../hooks';

import * as Styled from './styled';

export const RouteChangeSpinner = () => {
    const { isRouteChanging } = useRouteChange();

    useEffect(() => {
        document.body.style.overflowY = isRouteChanging ? 'hidden' : 'auto';

        return () => {
            document.body.style.overflowY = 'auto';
        };
    }, [isRouteChanging]);

    if (!isRouteChanging) {
        return null;
    }

    return createPortal(
        <Styled.RouteSpinner>
            <Styled.Loader>
                <Spinner size="large" />
            </Styled.Loader>
        </Styled.RouteSpinner>,
        document.getElementById('route-spinner') as HTMLDivElement,
    );
};
