import { createSlice } from '@reduxjs/toolkit';

import { loadSettings } from '@/store/reducers/settings-site/action-creators';
import { TSettingsSiteState } from '@/store/reducers/settings-site/types';

const initialState: TSettingsSiteState = {
    loaded: false,
    officeCount: 0,
    offices: [],
};

export const settingsSiteSlice = createSlice({
    extraReducers: (builder) => {
        builder.addCase(loadSettings.fulfilled, (state, action) => {
            state.loaded = true;
            state.offices = action.payload.offices;
            state.officeCount = action.payload.offices.length;
        });
    },
    initialState,
    name: 'settingsSite',
    reducers: {},
});

export const settingsSiteSliceReducer = settingsSiteSlice.reducer;
