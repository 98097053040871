export type TUtmParams = {
    referal?: string;
    utm_campaign: string;
    utm_content: string;
    utm_medium: string;
    utm_source: string;
    utm_term: string;
    click_hash: string;
};

export type TResultUtmParams = Omit<
    TUtmParams,
    'utm_campaign' | 'utm_content' | 'utm_medium' | 'utm_term' | 'click_hash'
>;

export enum ECookiesKeys {
    UTM_PARAMS = 'utm_params',
}
