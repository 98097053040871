import { useCallback } from 'react';

import { APP_BASE_URL } from '@/shared/config/app';
import { Cookies } from '@/shared/lib/cookie';
import { getParameterByName } from '@/shared/lib/querify';

import { ECookiesKeys, TResultUtmParams, TUtmParams } from './types';

type TUtmParamsReturned = {
    getUtmParamsFromCookie: () => TResultUtmParams;
    setUtmParams: () => void;
};

export const useUtmParams = (): TUtmParamsReturned => {
    function getUtmStaff(): TUtmParams {
        return {
            utm_campaign: getParameterByName('utm_campaign'),
            utm_content: getParameterByName('utm_content'),
            utm_medium: getParameterByName('utm_medium'),
            utm_source: getParameterByName('utm_source'),
            utm_term: getParameterByName('utm_term'),
            click_hash: getParameterByName('click_hash'),
        };
    }

    const setUtmParams = useCallback(() => {
        const utmStaff = getUtmStaff();
        if (utmStaff.utm_source) {
            const keysUtm: Array<keyof TUtmParams> = Object.keys(utmStaff) as Array<keyof TUtmParams>;
            const timeCookieSetting = 60 * 60 * 24 * 30;
            let result = '';

            keysUtm.forEach(function (utmKey) {
                if (utmStaff[utmKey] !== '') {
                    result += `${utmKey}=${utmStaff[utmKey]}&`;
                }
            });

            result = result.slice(0, -1);
            Cookies.set(ECookiesKeys.UTM_PARAMS, result, { maxAge: timeCookieSetting });
        }
    }, []);

    const getUtmParamsFromCookie = (): TResultUtmParams => {
        const utmParams = (Cookies.get(ECookiesKeys.UTM_PARAMS) as string) ?? '';
        const utmSourceArray = utmParams.match(/(utm_source=\w+)/) as string[];
        const utmSource = utmSourceArray?.length > 0 ? utmSourceArray[0].split('=')[1] : '';
        const referal = APP_BASE_URL.includes('localhost')
            ? ''
            : `${APP_BASE_URL}${utmParams ? `/?${utmParams}` : '/'}`;
        return {
            referal,
            utm_source: utmSource,
        };
    };

    return {
        getUtmParamsFromCookie,
        setUtmParams,
    };
};
