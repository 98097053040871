import { AxiosError, AxiosInstance, AxiosResponse } from 'axios';

import { UNAUTHORIZED } from '@/shared/constants/settings';
import { STORAGE_KEYS } from '@/shared/constants/storage-keys';
import { Cookies } from '@/shared/lib/cookie';
import { customCaptureException } from '@/shared/lib/custom-capture-exception';

import { TResponseAndRequest } from '../types';

type TTokenService = {
    getAccessToken: (props?: TResponseAndRequest) => string;
    getRefreshToken: (props?: TResponseAndRequest) => string;
    removeAccessToken: (props?: TResponseAndRequest) => void;
    removeRefreshToken: (props?: TResponseAndRequest) => void;
    removeTokens: () => void;
    saveAccessToken: (token: string, props?: TResponseAndRequest) => void;
    saveRefreshToken: (token: string, props?: TResponseAndRequest) => void;
};

export const TokenService: TTokenService = {
    getAccessToken: (props) => Cookies.get<string>(STORAGE_KEYS.accessToken, props),
    getRefreshToken: (props) => Cookies.get<string>(STORAGE_KEYS.refreshToken, props),
    removeAccessToken: (props) => Cookies.remove(STORAGE_KEYS.accessToken, props),
    removeRefreshToken: (props) => Cookies.remove(STORAGE_KEYS.refreshToken, props),
    removeTokens: () => {
        Cookies.remove([STORAGE_KEYS.accessToken, STORAGE_KEYS.refreshToken]);
    },
    saveAccessToken: (token, props) => Cookies.set(STORAGE_KEYS.accessToken, token, props),
    saveRefreshToken: (token, props) => Cookies.set(STORAGE_KEYS.refreshToken, token, props),
};

// Настройки для токена axios

const failedRequestStack: ((param: string) => void)[] = [];

let refreshingToken = false;

const retryRequests = (accessToken: string) => {
    while (failedRequestStack.length) {
        failedRequestStack.pop()?.(accessToken);
    }
};

export const onResponseSuccess = (response: AxiosResponse): AxiosResponse => {
    return response;
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any, @typescript-eslint/explicit-module-boundary-types
export const onResponseError =
    (axiosClient: AxiosInstance, context?: TResponseAndRequest) =>
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    async (err: AxiosError): Promise<any> => {
        const originalConfig = err.config;
        // Intended to show 500 error when server fails
        // const status = err.response?.status;

        const contextCookie = context;
        // Return early if there was no token provided in the first place(meaning that user is not authenticated or did not have stale token)
        if (!originalConfig?.headers?.Authorization) {
            return Promise.reject(err);
        }
        if (originalConfig?.url !== '/auth/refresh' && originalConfig?.url !== '/sign-in' && err.response) {
            // Access Token was expired
            if (err.response.status === UNAUTHORIZED) {
                const retryOrigReq = new Promise((resolve) => {
                    failedRequestStack.push((token) => {
                        originalConfig.headers!['Authorization'] = 'Bearer ' + token;
                        resolve(axiosClient(originalConfig));
                    });
                });
                if (!refreshingToken) {
                    TokenService.removeAccessToken(contextCookie);

                    try {
                        refreshingToken = true;

                        const rs = await axiosClient.post('/auth/refresh', {
                            refresh_token: TokenService.getRefreshToken(contextCookie),
                        });
                        const { refresh_token: refreshToken, token } = rs.data;
                        TokenService.saveAccessToken(token, contextCookie);
                        TokenService.saveRefreshToken(refreshToken, contextCookie);

                        retryRequests(token);
                    } catch (_error) {
                        customCaptureException(_error, 'TokenService.onResponseError');
                        return Promise.reject(_error);
                    } finally {
                        refreshingToken = false;
                    }
                }
                return await retryOrigReq;
            }
        }
        return Promise.reject(err);
    };
