import { createSlice } from '@reduxjs/toolkit';

import { TModalState } from '@/store/reducers/modal/types';

const initialState: TModalState = {
    content: null,
    hasShow: false,
    onClose: null,
    size: 'Medium',
    title: null,
    type: 'Default',
};

export const siteModalSlice = createSlice({
    //FIXME maybe should be stored in constants
    initialState,
    name: 'modal',
    reducers: {
        closeModal(state) {
            state.hasShow = false;
        },

        showModal(state, action) {
            state.content = action.payload.content;
            state.type = action.payload.type || initialState.type;
            state.size = action.payload.size || initialState.size;
            state.title = action.payload.title;
            state.hasShow = true;
        },
    },
});

export const { closeModal, showModal } = siteModalSlice.actions;

export const siteModalSliceReducer = siteModalSlice.reducer;
