import { Grid } from 'antd';
import React from 'react';

const { useBreakpoint } = Grid;

type TProps = {
    children: React.ReactNode;
    showIf: 'sm' | 'md' | 'lg' | 'xl' | 'xxl';
};

export const HideContentByBreakPoint: React.FC<TProps> = ({ children, showIf }) => {
    const screen = useBreakpoint();
    if (screen?.lg === undefined) {
        return <></>;
    }
    return screen[showIf] ? <>{children}</> : <></>;
};
