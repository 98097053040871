import { Spin } from 'antd';
import { SpinSize } from 'antd/lib/spin';
import { styled } from 'styled-components';

import { theme } from '@/styles/theme';

const sizeStyles = {
    small: `
        .ant-spin-dot {
            font-size: 20px;
        }

        .ant-spin-dot-item {
            width: 10px;
            height: 10px;
        }

        .ant-spin-dot-holder {
            width: 20px;
            height: 20px;
        }
    `,
    default: `
        .ant-spin-dot {
            font-size: 60px;
        }

        .ant-spin-dot-item {
            width: 20px;
            height: 20px;
        }

        .ant-spin-dot-holder {
            width: 60px;
            height: 60px;
        }
    `,
    large: `
        .ant-spin-dot {
            font-size: 105px;
        }

        .ant-spin-dot-item {
            width: 35px;
            height: 35px;
        }

        .ant-spin-dot-holder {
            width: 105px;
            height: 105px;
        }
    `,
};

export const Wrapper = styled(Spin)<{ $size: SpinSize; $color?: string }>`
    ${({ $size }) => $size && sizeStyles[$size]};

    .ant-spin-dot-item {
        background-color: ${({ $color }) => ($color ? $color : theme.color.primary)};
    }
`;
