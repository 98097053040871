import { Typography } from 'antd';
import React, { ReactNode, useState } from 'react';
import * as Yup from 'yup';

import { useTranslate } from '@/shared/hooks/use-translate';
import {
    REGEXP_TYPE_NUMERIC,
    REGEXP_TYPE_NUMERIC_AND_WORDS,
    REGEXP_TYPE_NUMERIC_AND_WORDS_AND_WORDS_SPEC,
    REGEXP_TYPE_NUMERIC_AND_WORDS_SPEC,
    REGEXP_TYPE_WORDS,
    REGEXP_TYPE_WORDS_AND_WORDS_SPEC,
    REGEXP_TYPE_WORDS_SPEC,
} from '@/shared/ui/input/constants';
import { InputField } from '@/shared/ui/input/input-field';
import { SelectAntd } from '@/shared/ui/select-antd';

import { getPawnshopCategoryOptions } from '../../api';
import { ECategoryOptionFiledType } from '../../constants';
import { categoryOption } from '../../lib/category-option';
import { ECategoryOptionFieldExtrasType, TPawnshopCategoryOption } from '../../types';

const { Paragraph } = Typography;

type TInitialFieldOption = {
    [key: string]: string | number | number[] | null;
};

type TFieldOptionValidationSchema = {
    [key: string]: Yup.AnySchema;
};

type TReturnHook = {
    dynamicFields: {
        fields: TPawnshopCategoryOption[];
        initial: TInitialFieldOption;
        validationSchema: Yup.AnySchema;
    };
    /* eslint-disable @typescript-eslint/no-explicit-any */
    fieldTemplate: (field: TPawnshopCategoryOption, onChange: any, fieldValue: any, errorText: string) => ReactNode;
    isLoadingOptions: boolean;
    onFetchOptionsByCategory: (id: number) => Promise<void>;
};

export const useOptionsPawnshopCategory = (): TReturnHook => {
    const [isLoading, setIsLOading] = useState(false);
    const [originalOptions, setOriginalOptions] = useState<TPawnshopCategoryOption[]>([]);
    const { t } = useTranslate();

    const onFetchOptionsByCategory = async (id: number) => {
        setIsLOading(true);
        const options = await getPawnshopCategoryOptions(id);
        setOriginalOptions(options);
        setIsLOading(false);
    };

    const getDynamicFields = () => {
        return {
            fields: getFields(),
            initial: getInitialFields(),
            validationSchema: getValidationSchema(),
        };
    };

    const getValidationSchema = (): Yup.AnySchema => {
        const schema: TFieldOptionValidationSchema = {};
        if (originalOptions.length === 0) {
            return Yup.object().shape({});
        }
        originalOptions.map((field) => {
            switch (field.type) {
                case ECategoryOptionFiledType.SELECT:
                    schema[field.name] = Yup.string().required('Поле обязательно');
                    break;
                case ECategoryOptionFiledType.SELECT_MULTIPLE:
                    schema[field.name] = Yup.array().min(1, 'Поле обязательно');
                    break;
                case ECategoryOptionFiledType.INPUT:
                    schema[field.name] = Yup.string().required('Поле обязательно');
                    break;
            }
        });
        return Yup.object().shape({ ...schema });
    };

    const getInitialFields = (): TInitialFieldOption => {
        const initial: TInitialFieldOption = {};
        if (originalOptions.length === 0) {
            return initial;
        }

        originalOptions.map((field) => {
            switch (field.type) {
                case ECategoryOptionFiledType.SELECT:
                    initial[field.name] = '';
                    break;
                case ECategoryOptionFiledType.SELECT_MULTIPLE:
                    initial[field.name] = [];
                    break;
                case ECategoryOptionFiledType.INPUT:
                    initial[field.name] = '';
                    break;
            }
        });

        return initial;
    };

    const getFieldTemplate = (
        field: TPawnshopCategoryOption,
        onChange: any,
        fieldValue: any,
        errorText: string,
    ): ReactNode => {
        const isMultiple = field.type === ECategoryOptionFiledType.SELECT_MULTIPLE;
        const options = categoryOption.getCategoryOptionChoices(field.choices);
        let regexp: RegExp | undefined = undefined;
        let placeholder = '';
        switch (field.type) {
            case ECategoryOptionFiledType.SELECT:
            case ECategoryOptionFiledType.SELECT_MULTIPLE:
                return (
                    <div className="form-item-field" key={field.id}>
                        <SelectAntd
                            allowClear={isMultiple}
                            height="LG"
                            labelInValue={false}
                            mode={isMultiple ? 'multiple' : undefined}
                            onChange={(value) => {
                                onChange(`options[${field.name}]`, value);
                            }}
                            options={options}
                            placeholder={t(field.name)}
                            showSearch
                            title={t(field.name)}
                        />
                    </div>
                );
            case ECategoryOptionFiledType.INPUT:
                if (field.extras.fieldType) {
                    const isTypeNumeric = !!field.extras.fieldType?.find(
                        (item) => item === ECategoryOptionFieldExtrasType.DIGIT,
                    );
                    const isTypeWords = !!field.extras.fieldType?.find(
                        (item) => item === ECategoryOptionFieldExtrasType.WORDS,
                    );
                    const isTypeWordsSpec = !!field.extras.fieldType?.find(
                        (item) => item === ECategoryOptionFieldExtrasType.SPEC_WORDS,
                    );

                    if (field.extras.fieldType?.length === 1) {
                        if (isTypeNumeric) {
                            placeholder = t('Введите число');
                            regexp = REGEXP_TYPE_NUMERIC;
                        }

                        if (isTypeWords) {
                            placeholder = t('Введите текст');
                            regexp = REGEXP_TYPE_WORDS;
                        }

                        if (isTypeWordsSpec) {
                            placeholder = t('Введите символы');
                            regexp = REGEXP_TYPE_WORDS_SPEC;
                        }
                    } else {
                        placeholder = t('Введите значение');

                        if (isTypeNumeric && isTypeWords && isTypeWordsSpec) {
                            regexp = REGEXP_TYPE_NUMERIC_AND_WORDS_AND_WORDS_SPEC;
                        } else if (isTypeNumeric && isTypeWords) {
                            regexp = REGEXP_TYPE_NUMERIC_AND_WORDS;
                        } else if (isTypeNumeric && isTypeWordsSpec) {
                            regexp = REGEXP_TYPE_NUMERIC_AND_WORDS_SPEC;
                        } else if (isTypeWords && isTypeWordsSpec) {
                            regexp = REGEXP_TYPE_WORDS_AND_WORDS_SPEC;
                        }
                    }
                }

                return (
                    <div key={field.id}>
                        <div className="form-item-field">
                            <InputField
                                allowClear
                                borderFocus={false}
                                error={errorText ?? ''}
                                label={t(field.name)}
                                onChange={(e) => {
                                    onChange(`options[${field.name}]`, e.target.value);
                                }}
                                placeholder={placeholder}
                                regexp={regexp}
                                size="lg"
                                value={fieldValue as string}
                            />
                        </div>

                        {field.extras.notice && <Paragraph className="notice">{field.extras.notice}</Paragraph>}
                    </div>
                );
            default:
                return <></>;
        }
    };
    const getFields = () => {
        return originalOptions;
    };

    return {
        dynamicFields: getDynamicFields(),
        fieldTemplate: getFieldTemplate,
        isLoadingOptions: isLoading,
        onFetchOptionsByCategory,
    };
};
