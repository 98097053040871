import { TCalculatorData, TCalculatorProducts } from '@/shared/types/calculator';

import {
    ECalculationEstimateStatus,
    ECategoryOptionFiledType,
    EPawnshopCategoryType,
    ESubmitStatusEstimateForm,
} from './constants';

export type TPawnshopCategory = {
    id: number;
    name: string;
    position: number;
    saascreditId: number;
    type: EPawnshopCategoryType;
};

export type TCategoryOptionChoices = {
    id: number;
    position: number;
    value: string;
};

export type TCategoryOptionFieldExtras = {
    fieldType: ECategoryOptionFieldExtrasType[];
    notice: string;
};

export enum ECategoryOptionFieldExtrasType {
    DIGIT = 'typeDigit',
    SPEC_WORDS = 'typeSpecWords',
    WORDS = 'typeWords',
}

export type TPawnshopCategoryOption = {
    choices: TCategoryOptionChoices[];
    defaults: string[];
    extras: TCategoryOptionFieldExtras;
    id: number;
    name: string;
    position: number;
    saascreditId: number;
    type: ECategoryOptionFiledType;
};

export type TResponsePawnshopCategories = {
    data: TPawnshopCategory[];
    statusCode: number;
};

export type TRequestEstimateCondition = {
    name: string;
    value: string;
};

export type TRequestCollateralOption = {
    choices: string[];
    option_id: number;
};

export type TEstimateFormState = {
    categories: TPawnshopCategory[];
    form: {
        amountEstimate: number;
        lead: TLead | null;
        status: ECalculationEstimateStatus;
        submitStatus: ESubmitStatusEstimateForm;
        type: EPawnshopCategoryType | null;
        values: {
            isExtraCharge: boolean;
            options: TFieldOption;
        };
    };
    loaded: boolean;
    overpaymentCalculator: {
        data: TCalculatorData;
        products: TCalculatorProducts[];
        selectedKp: TCalculatorProducts | null;
    };
};

export type TRequestCreateLeadParams = {
    collateral: {
        category_id: number;
        options: TRequestCollateralOption[];
    };
    collateral_estimate_amount: number;
    phone_number: string;
    referer: string;
    source_type: string;
    utm_source: string;
};

export type TFieldOption = {
    [key: string]: string | number | string[];
};

/**
 * Тип объекта лида, возвращаемый запросом создания лида
 */
export type TLead = {
    child_count: number;
    create_date: string;
    create_user_id: number;
    customer_id: number;
    email: string;
    first_name: string;
    id: number;
    is_gender_valid: true;
    last_name: string;
    marital_status: string;
    middle_name: string;
    questionnaires_id: number;
    registration_address_id: number;
    requested_amount: number;
    residential_address_id: number;
    user_id: number;
};
