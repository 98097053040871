import { styled } from 'styled-components';

import { mq } from '@/styles/media.styled';
import { rowXBetweenYCenter } from '@/styles/mixins.styled';

export const StyledOverpaymentCalculator = styled.section`
    margin-top: 52px;

    ${mq.lt.lg} {
        margin-top: 40px;
    }
`;

export const StyledOverpaymentContainer = styled.div`
    margin-top: 32px;
    background: #fff;
    box-shadow: 0 4px 12px rgb(0 0 0 / 8%);
    border-radius: 12px;
    padding: 32px 24px;
    ${mq.lt.lg} {
        margin-top: 24px;
        padding: 18px 12px;
    }
`;

export const StyledOverpaymentCalculatorFields = styled.div`
    ${rowXBetweenYCenter};
    gap: 24px;

    & > div {
        width: 100%;
    }

    label {
        margin-bottom: 8px;
        display: inline-block;
        font-weight: 500;
        font-size: ${({ theme }) => theme.fontSize.sm};
    }

    .ant-input-affix-wrapper {
        border-radius: 8px 8px 0 0;
        background: ${({ theme }) => theme.color.neutral200};
    }

    input {
        height: 64px;
        font-size: ${({ theme }) => theme.fontSize.xl} !important;
        background: ${({ theme }) => theme.color.neutral200};
    }

    &.mobile {
        flex-direction: column;
    }
    ${mq.lt.md} {
        flex-direction: column;
    }
`;

export const StyledOverpaymentCalculatorTitle = styled.h3`
    font-size: ${({ theme }) => theme.fontSize.lg};
    font-weight: 500;
    line-height: 24px;

    ${mq.lt.md} {
        text-align: center;
    }
`;
