import React from 'react';

import { TSvgIcon } from '@/shared/types/types';

const ArrowDownIcon: TSvgIcon = (props) => {
    const { color = 'currentColor', height = 17, width = 16 } = props;

    return (
        <svg fill={color} height={height} viewBox="0 0 16 17" width={width} xmlns="http://www.w3.org/2000/svg">
            <path
                d="M5.80666 8.30669L7.53333 10.0334C7.79333 10.2934 8.21333 10.2934 8.47333 10.0334L10.2 8.30669C10.62 7.88669 10.32 7.16669 9.72666 7.16669H6.27333C5.67999 7.16669 5.38666 7.88669 5.80666 8.30669Z"
                fill={color}
            />
        </svg>
    );
};

export default ArrowDownIcon;
