import { AxiosError } from 'axios';

import { ResourceService } from '@/shared/api';
import { axiosScClient } from '@/shared/api/sc';
import { LeadService } from '@/shared/api/sc/lead-service';
import { axiosSiteClient } from '@/shared/api/site';
import { BAD_REQUEST, NOT_FOUND_PAGE, SERVER_ERROR, STATUS_CODE_OK } from '@/shared/constants/settings';
import { customCaptureException } from '@/shared/lib/custom-capture-exception';

import { ECalculationEstimateStatus } from '../constants';
import {
    TLead,
    TPawnshopCategory,
    TPawnshopCategoryOption,
    TRequestCreateLeadParams,
    TRequestEstimateCondition,
    TResponsePawnshopCategories,
} from '../types';

const PawnshopCategoriesService = ResourceService('pawnshop-categories', axiosSiteClient);
const PawnshopService = ResourceService('pawnshop', axiosScClient);

export const getPawnshopCategoriesCalc = async (): Promise<TResponsePawnshopCategories> => {
    let statusCode = STATUS_CODE_OK;
    try {
        const response = await PawnshopCategoriesService.read<{ data: TPawnshopCategory[] }>();
        return {
            data: response.data,
            statusCode,
        };
    } catch (err) {
        customCaptureException(err, 'getPawnshopCategoriesCalc');
        const error = err as AxiosError;
        statusCode = error.response?.status === NOT_FOUND_PAGE ? NOT_FOUND_PAGE : SERVER_ERROR;
    }

    return {
        data: [],
        statusCode,
    };
};

export const getPawnshopCategoryOptions = async (id: number): Promise<TPawnshopCategoryOption[]> => {
    try {
        const response = await PawnshopCategoriesService.readOne<{ data: TPawnshopCategoryOption[] }>({
            identifier: `${id}/options`,
        });
        return response.data ?? [];
    } catch (err) {
        customCaptureException(err, 'getPawnshopCategoryOptions');
    }

    return [];
};

/**
 * Функция запроса предварительной оценки залога
 */
export const getCalculationEstimateForm = async (categoryId: number, options: TRequestEstimateCondition[]) => {
    let statusEstimate = ECalculationEstimateStatus.ERROR;

    try {
        const response = await PawnshopService.command<
            { amount: number },
            { category_id: number; conditions: TRequestEstimateCondition[] }
        >({
            command: 'collateral/estimate',
            data: {
                category_id: categoryId,
                conditions: options,
            },
        });

        if (response) {
            return {
                data: response.data.amount,
                statusEstimate:
                    response.data.amount > 0 ? ECalculationEstimateStatus.SUCCESS : ECalculationEstimateStatus.ZERO,
            };
        }
    } catch (err) {
        customCaptureException(err, 'getCalculationEstimateForm');
        statusEstimate = ECalculationEstimateStatus.ERROR;
    }

    return {
        data: 0,
        statusEstimate,
    };
};

/**
 * Функция создания лида
 *
 * https://youtrack.dengigroup.kz/issue/MRKZ-17/Avtootsenka-Ispolzovanie-logiki-avtootsenki-na-kalkulyatore-8#focus=Comments-4-15119.0-0
 */
export const createLead = async (params: TRequestCreateLeadParams) => {
    let statusCode = STATUS_CODE_OK;

    try {
        const responseData = await LeadService.command<TLead, TRequestCreateLeadParams>({
            command: `create-by-partners`,
            data: params,
        });

        if (responseData) {
            return {
                data: responseData.data,
                statusCode: responseData.statusCode,
            };
        }

        statusCode = NOT_FOUND_PAGE;
    } catch (err) {
        customCaptureException(err, 'createLead');
        const error = err as AxiosError;
        statusCode = error.response?.status === BAD_REQUEST ? BAD_REQUEST : SERVER_ERROR;
    }

    return {
        data: null,
        statusCode,
    };
};
