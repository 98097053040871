import { styled, css } from 'styled-components';

import { TStyledSizeElement } from './input-field';

const sizeLarge = css`
    .ant-input-affix-wrapper {
        padding: 0 11px;
    }

    .ant-input {
        background-color: ${({ theme }) => theme.color.neutral200} !important;
        border: none;
        border-radius: 8px;
        height: 62px;
        font-weight: 400;
        font-size: 16px;
        line-height: 120%;
        width: 100%;
    }

    .addonAfter {
        top: 0;
        right: 20px;
        height: 64px;
    }
`;
const sizeMedium = css`
    .ant-input {
        height: 44px;
        padding-left: 20px;
        font-size: 16px;
        border-radius: 8px;
    }
`;
const sizeSmall = css`
    color: transparent;
`;

export const Wrapper = styled.div<{ $borderFocus: boolean; $size: TStyledSizeElement }>`
    position: relative;

    label {
        display: block;
        font-size: 14px;
        margin-bottom: 8px;
    }

    .error {
        color: #ff4d4f;
        font-size: 12px;
        padding-top: 4px;
    }

    .ant-input {
        outline: none;
        border: 0.5px solid rgb(0 0 0 / 12%);
        background-color: #f2f3f5;
        color: #5f6670;

        &:focus {
            ${(props) => props.$borderFocus && 'border: 0.5px solid #3f8ae0'};
        }

        &:-webkit-autofill,
        &:-webkit-autofill:hover,
        &:-webkit-autofill:focus,
        &:-webkit-autofill:active {
            background-color: #f2f3f5;
        }
    }

    .addonAfter {
        font-size: 30px;
        position: absolute;
        color: #6f7582;
        align-items: center;
        display: flex;
    }
    ${(props) => props.$size === 'lg' && sizeLarge};
    ${(props) => props.$size === 'md' && sizeMedium};
    ${(props) => props.$size === 'sm' && sizeSmall};

    &.error {
        .ant-input-affix-wrapper {
            border: 0.5px solid #ff4d4f !important;

            .ant-input {
                border: none;
            }
        }
    }
`;
