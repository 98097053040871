import React, { FC, useMemo, useState } from 'react';

import { useTranslate } from '@/shared/hooks/use-translate';
import { TabsAntd, TTabItems } from '@/shared/ui/tabs-antd';

import { EPawnshopCategoryType } from '../../constants';
import { TabEstimateItem } from '../tab-estimate-item';

import * as Styled from './styled';

type TProps = {
    activeTabCalc?: EPawnshopCategoryType;
};

export const TabsEstimate: FC<TProps> = ({ activeTabCalc }) => {
    const [tab, setTab] = useState(activeTabCalc ?? EPawnshopCategoryType.APPLIANCE);
    const { t } = useTranslate();

    const itemsTab: TTabItems = useMemo(
        () => [
            {
                children: (
                    <TabEstimateItem idSubmitButton="calculation_button_2" type={EPawnshopCategoryType.APPLIANCE} />
                ),
                key: EPawnshopCategoryType.APPLIANCE,
                label: t('Под залог техники'),
            },
            {
                children: <TabEstimateItem idSubmitButton="calculation_button_3" type={EPawnshopCategoryType.JEWEL} />,
                key: EPawnshopCategoryType.JEWEL,
                label: t('Под залог золота'),
            },
        ],
        [t],
    );

    const handleChange = (keyTab: string) => {
        setTab(keyTab as EPawnshopCategoryType);
    };

    return (
        <Styled.Wrapper>
            <div className="content">
                <TabsAntd activeKey={tab} items={itemsTab} onChange={handleChange} />
            </div>
        </Styled.Wrapper>
    );
};
