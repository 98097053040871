import { styled, css } from 'styled-components';

import { TVariantButton } from './index';

const brandGradientStyle = css`
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 12px 16px;
    gap: 8px;
    background: linear-gradient(218.13deg, #63d07b 18.93%, #0bb6ba 94.11%);
    border-radius: 8px;
    border: none;
    color: ${({ theme }) => theme.color.secondary};
    font-weight: 500;
    font-size: ${({ theme }) => theme.fontSize.md};
    line-height: 120%;
    cursor: pointer;
    height: 100%;

    &:hover {
        color: ${({ theme }) => theme.color.secondary};
    }
`;

const unbordedStyle = css`
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 8px;
    padding: 0;
    border: none;
    background: none;
    color: ${({ theme }) => theme.color.dark};
    font-size: ${({ theme }) => theme.fontSize.sm};
    line-height: 120%;
    cursor: pointer;
`;

const calcButtonCss = css`
    align-items: center;
    padding: 26px 24px;
    width: 100%;
    max-width: 442px;
    height: 100%;
    max-height: 64px;
    border-radius: 8px;
    border: none;
    font-weight: 500;
    font-size: 16px;
    line-height: 120%;
    color: #333;
    background: white;
    cursor: pointer;
    opacity: 1;

    &.disabled {
        background: #ececf0;
        cursor: not-allowed;
        opacity: 0.7;
        color: #a9adbc;
    }

    .ant-spin-dot {
        font-size: 25px !important;
    }
`;

const brandGreenUnbordedStyle = css`
    display: flex;
    flex-direction: row;
    align-items: center;
    height: 19px;
    border: none;
    color: ${({ theme }) => theme.color.primary};
    font-weight: 500;
    font-size: ${({ theme }) => theme.fontSize.sm};
    line-height: 120%;
    white-space: nowrap;
    cursor: pointer;

    &:hover {
        color: ${({ theme }) => theme.color.primary};
    }

    background: none;
`;

const disabledCss = css`
    pointer-events: none;
    background: #e5e5e5 !important;
    cursor: default;
`;

const getButtonStyles = (variant: TVariantButton) => {
    switch (variant) {
        case 'unborded':
            return unbordedStyle;
        case 'gradient-brand-green':
            return brandGradientStyle;
        case 'brand-green-unborded':
            return brandGreenUnbordedStyle;
        case 'calc-button':
            return calcButtonCss;
        default:
            return '';
    }
};

export const Wrapper = styled.button<{
    $disabled: boolean;
    $stretch: boolean;
    $variant: TVariantButton;
}>`
    display: flex;
    flex-direction: row;
    column-gap: 10px;
    justify-content: center;
    width: ${(props) => (props.$stretch ? '100%' : 'auto')};
    ${({ $disabled }) => $disabled && disabledCss};
    ${({ $variant }) => getButtonStyles($variant)};
`;
