import { TOffice, TRegion } from '../types';

const offices = (items: TOffice[]): TRegion[] => {
    const map: Map<string, TRegion> = new Map();

    items.forEach((item) => {
        const hash = `${item.region}/${item.city}`;
        let office = [];
        if (map.has(hash)) {
            const region = map.get(hash);
            region?.offices.push(item);
            office = region?.offices as TOffice[];
        } else {
            office = [item];
        }

        map.set(hash, {
            name: item.city,
            offices: [...office],
            region: item.region,
        });
    });
    return Array.from(map.entries()).map((item) => {
        return { ...item[1] };
    });
};

const groupRegion = (items: TOffice[]): TRegion[] => {
    const map: Map<string, TRegion> = new Map();

    items.forEach((item) => {
        const hash = `${item.region}`;
        let office = [];
        if (map.has(hash)) {
            const region = map.get(hash);
            region?.offices.push(item);
            office = region?.offices as TOffice[];
        } else {
            office = [item];
        }

        map.set(hash, {
            name: item.city,
            offices: [...office],
            region: item.region,
        });
    });
    return Array.from(map.entries())
        .map((item) => {
            return { ...item[1] };
        })
        .sort((a, b) => a.region.localeCompare(b.region));
};

export const processing = {
    groupRegion,
    offices,
};
