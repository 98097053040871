import { Col, Row, Space, Typography } from 'antd';
import cn from 'classnames';
import { useRouter } from 'next/router';
import React, { FC, useEffect, useRef } from 'react';

import { useAppSelector } from '@/store';

import { ResultWrapper } from '@/features/estimate-form';

import { MAIN_PAGE_MAP_BRANCH_SECTION_ID } from '@/shared/constants/settings';
import { useTranslate } from '@/shared/hooks/use-translate';
import { formatCurrency } from '@/shared/lib/currency';
import { LinkBrandButton } from '@/shared/ui/link-brand-button';

import {
    SPACE_STYLE_COMMON,
    textHelpResultEstimate,
    ESubmitStatusEstimateForm,
    ECalculationEstimateStatus,
    EPawnshopCategoryType,
} from '../../constants';
import { ResultError } from '../result-error';
import { ResultLoading } from '../result-loading';
import { ResultZero } from '../result-zero';

const { Paragraph } = Typography;

type TProps = {
    hasMobile?: boolean;
};

export const EstimateResult: FC<TProps> = ({ hasMobile }) => {
    const { amountEstimate, status, submitStatus, type, values } = useAppSelector((state) => state.estimateForm.form);

    const ref = useRef<HTMLDivElement>(null);
    const { t } = useTranslate();
    const router = useRouter();

    useEffect(() => {
        if (submitStatus === ESubmitStatusEstimateForm.in_progress) {
            ref?.current?.scrollIntoView({ behavior: 'smooth' });
        }
    }, [submitStatus]);

    if (submitStatus === ESubmitStatusEstimateForm.await) {
        return <></>;
    }

    if (submitStatus === ESubmitStatusEstimateForm.in_progress) {
        return <ResultLoading myRef={ref} type={type as EPawnshopCategoryType} />;
    }

    if (submitStatus === ESubmitStatusEstimateForm.completed && status === ECalculationEstimateStatus.ERROR) {
        return <ResultError myRef={ref} />;
    }

    if (submitStatus === ESubmitStatusEstimateForm.completed && status === ECalculationEstimateStatus.ZERO) {
        return <ResultZero myRef={ref} />;
    }

    const forSaleText =
        type === EPawnshopCategoryType.APPLIANCE
            ? t('textTechnicResultEstimateIsExtraCharge')
            : t('textGoldResultEstimateIsExtraCharge');

    return (
        <div ref={ref}>
            <Space direction="vertical" size="large" style={SPACE_STYLE_COMMON}>
                <Typography.Title
                    level={3}
                    style={{
                        fontSize: '24px',
                        fontWeight: 500,
                        margin: 0,
                    }}
                >
                    {t(textHelpResultEstimate[type as EPawnshopCategoryType]?.title)}
                </Typography.Title>

                <ResultWrapper className={cn({ mobile: hasMobile })}>
                    <Row gutter={[32, 32]} wrap>
                        <Col className="result-col-mobile-top" md={hasMobile ? 24 : 12} span={24}>
                            <Space direction="vertical" size="middle" style={{ width: '100%' }}>
                                {Object.keys(values.options).map((key, i) => (
                                    <div className="parameter" key={`${key}-${i}`}>
                                        <Paragraph className="label">{key}</Paragraph>

                                        <Typography.Title className="value" level={4} style={{ margin: 0 }}>
                                            {values.options[key]}
                                        </Typography.Title>
                                    </div>
                                ))}

                                {values.isExtraCharge && <Paragraph className="for-sale">{forSaleText}</Paragraph>}
                            </Space>
                        </Col>

                        <Col className="result-col" md={hasMobile ? 24 : 12} span={24}>
                            <Col className="result">
                                <Space className="space" direction="vertical" size="large">
                                    <Space className="space" direction="vertical">
                                        <Typography.Title className="amount" level={3} style={{ margin: 0 }}>
                                            {formatCurrency(amountEstimate)} ₸
                                        </Typography.Title>

                                        <Paragraph className="info">
                                            {t('Получите эту сумму в любом отделении «Деньги Маркет» уже сегодня')}
                                        </Paragraph>
                                    </Space>

                                    <div className="button-wrapper">
                                        <LinkBrandButton
                                            className="button"
                                            duration={500}
                                            hashSpy={true}
                                            offset={-100}
                                            onClick={() =>
                                                router.pathname === '/' ? null : router.push('/#office-map')
                                            }
                                            smooth={true}
                                            spy={true}
                                            to={MAIN_PAGE_MAP_BRANCH_SECTION_ID}
                                        >
                                            <span className="brand-button-link-title">
                                                {t('Забрать деньги в отделении 💰')}
                                            </span>
                                        </LinkBrandButton>
                                    </div>
                                </Space>
                            </Col>
                        </Col>
                    </Row>
                </ResultWrapper>
            </Space>
        </div>
    );
};
