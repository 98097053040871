import { Input } from 'antd';
import { MaskedInput } from 'antd-mask-input';
import cn from 'classnames';
import React, { ComponentProps, FC, ReactElement } from 'react';

import { REGEXP_TYPE_NUMERIC } from './constants';
import * as Styled from './styled';

export type TStyledSizeElement = 'lg' | 'md' | 'sm';

type TProps = Omit<ComponentProps<'input'>, 'size' | 'onChange'> & {
    addonAfter?: string | ReactElement;
    allowClear?: boolean;
    borderFocus?: boolean;
    error?: string | ReactElement;
    hint?: string | ReactElement;
    icon?: JSX.Element;
    isAutocomplete?: boolean;
    label?: string;
    mask?: string;
    maxLength?: number;
    onChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
    regexp?: RegExp;
    size?: TStyledSizeElement;
};

export const InputField: FC<TProps> = ({
    addonAfter = '',
    allowClear = false,
    borderFocus = false,
    disabled = false,
    error = '',
    // hint,
    // icon,
    // id,
    inputMode,
    isAutocomplete = false,
    label,
    mask = '',
    maxLength,
    name,
    onBlur,
    onChange,
    placeholder,
    readOnly = false,
    regexp,
    size = 'md',
    style,
    tabIndex = 0,
    type = 'text',
    value,
}) => {
    const autocomplete = isAutocomplete ? 'on' : 'off';

    const handleChangeNotInputMask = (e: React.ChangeEvent<HTMLInputElement>) => {
        if (regexp) {
            if (regexp.test(e.target.value) || e.target.value === '') {
                if (regexp.toString() === REGEXP_TYPE_NUMERIC.toString()) {
                    e.target.value = e.target.value.replaceAll(',', '.');
                }
                onChange(e);
            } else {
                return;
            }
        } else {
            onChange(e);
        }
    };

    return (
        <Styled.Wrapper $borderFocus={borderFocus} className={cn({ error: error !== '' })} $size={size}>
            {label && <label htmlFor={name}>{label}</label>}

            {!mask ? (
                <Input
                    allowClear={allowClear}
                    autoComplete={autocomplete}
                    disabled={disabled}
                    id={name}
                    inputMode={inputMode}
                    maxLength={maxLength}
                    name={name}
                    onBlur={onBlur}
                    onChange={handleChangeNotInputMask}
                    placeholder={placeholder}
                    readOnly={readOnly}
                    style={style}
                    tabIndex={tabIndex}
                    type={type}
                    value={value}
                />
            ) : (
                <MaskedInput
                    autoComplete={autocomplete}
                    disabled={disabled}
                    id={name}
                    inputMode={inputMode}
                    mask={mask}
                    name={name}
                    onBlur={onBlur}
                    onChange={onChange}
                    placeholder={placeholder}
                    readOnly={readOnly}
                    style={style}
                    tabIndex={tabIndex}
                    value={value as string}
                />
            )}

            {error && <div className="error">{error}</div>}

            {addonAfter && <div className="addonAfter">{addonAfter}</div>}
        </Styled.Wrapper>
    );
};
