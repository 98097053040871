import { styled } from 'styled-components';

export const RouteSpinner = styled.div`
    position: fixed;
    z-index: 9;
    top: 0;
    height: 100%;
    width: 100%;
    background-color: rgb(0 0 0 / 15%);
    display: flex;
    justify-content: center;
    align-items: center;
`;

export const Loader = styled.div`
    width: 105px;
    height: 105px;
`;
