import React from 'react';

import { useTranslate } from '@/shared/hooks/use-translate';
import { formatCurrency } from '@/shared/lib/currency';
import { InputAntd } from '@/shared/ui/input';
import { Slider } from '@/shared/ui/slider';

import * as Styled from './styled';

type TProps = {
    addonMaxValue?: string;
    addonMinValue?: string;
    icon: React.ReactNode;
    inputValue: string;
    label: string;
    maxValue: number;
    minValue: number;
    onChangeInput: (value: string) => void;
    onChangeSlider: (value: number) => void;
    sliderValue: number;
    step: number;
};

export const SliderInput: React.FC<TProps> = (props) => {
    const {
        addonMaxValue,
        addonMinValue,
        icon,
        inputValue,
        label,
        maxValue,
        minValue,
        onChangeInput,
        onChangeSlider,
        sliderValue,
        step = 1,
    } = props;

    const { t } = useTranslate();

    return (
        <div>
            <label>{t(label)}</label>
            <InputAntd allowClear={false} onChange={onChangeInput} readOnly suffix={icon} value={inputValue} />
            <Slider maxValue={maxValue} minValue={minValue} onChange={onChangeSlider} step={step} value={sliderValue} />
            <Styled.Wrapper>
                <span>
                    {formatCurrency(minValue)} {addonMinValue}
                </span>
                <span>
                    {formatCurrency(maxValue)} {addonMaxValue}
                </span>
            </Styled.Wrapper>
        </div>
    );
};
