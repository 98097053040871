import { FC } from 'react';

import * as Styled from './styled';

type TProps = {
    maxValue?: number;
    minValue?: number;
    step: number;
    value: number;
    onChange?: (value: number) => void;
};

export const Slider: FC<TProps> = ({ step, value, minValue, maxValue, onChange }) => {
    return (
        <Styled.Wrapper
            max={maxValue}
            min={minValue}
            onChange={onChange}
            step={step}
            tooltip={{ open: false }}
            value={value}
        />
    );
};
