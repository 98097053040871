import { LoadingOutlined } from '@ant-design/icons';
import { SpinSize } from 'antd/lib/spin';
import { FC } from 'react';

import * as Styled from './styled';

type TProps = {
    size?: SpinSize;
    type?: 'doted' | 'outlined';
    color?: string;
};

export const Spinner: FC<TProps> = ({ size = 'default', color, type = 'doted' }) => {
    return (
        <Styled.Wrapper
            $size={size}
            $color={color}
            {...(type === 'outlined' && { indicator: <LoadingOutlined spin /> })}
        />
    );
};
