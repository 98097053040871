import { FC, ReactNode } from 'react';

import * as Styled from './styled';

type TProps = {
    className?: string;
    duration: number;
    hashSpy?: boolean;
    id?: string;
    offset: number;
    smooth: boolean;
    spy: boolean;
    to: string;
    children: ReactNode;
    onClick?: () => void;
};

export const LinkBrandButton: FC<TProps> = (props) => {
    return <Styled.LinkWrapper {...props}>{props.children}</Styled.LinkWrapper>;
};
