import React, { FC } from 'react';

import { TDynamicPage } from '@/entities/pages';

import { useTranslate } from '@/shared/hooks/use-translate';

import { EPawnshopCategoryType, MAIN_PAGE_CALC_SECTION_ID } from '../../constants';
import { EstimateResult } from '../estimate-result';
import { GadgetsNotation } from '../gadgets-notation';
import { OverpaymentCalculator } from '../overpayment-calculator';
import { TabsEstimate } from '../tabs-estimate';

import * as Styled from './styled';

type TProps = {
    activeTabCalc?: EPawnshopCategoryType;
    dynamicPage?: TDynamicPage;
    hasMobile?: boolean;
};

export const EstimateForm: FC<TProps> = ({
    activeTabCalc = EPawnshopCategoryType.APPLIANCE,
    dynamicPage,
    hasMobile,
}) => {
    const { t } = useTranslate();

    return (
        <Styled.Wrapper>
            <div className="title-calc-section" id={MAIN_PAGE_CALC_SECTION_ID}>
                <span className="title-calc-bold">{t('mainCalcSectionTitle')}</span>
                <span className="title-calc-text">{t('mainCalcSectionText')}</span>
            </div>
            <div className="calc-main-section">
                <TabsEstimate activeTabCalc={activeTabCalc} />
                <GadgetsNotation dynamicPage={dynamicPage} type={activeTabCalc} />
            </div>
            <EstimateResult hasMobile={hasMobile} />
            <OverpaymentCalculator hasMobile={hasMobile} />
        </Styled.Wrapper>
    );
};
