import { Select } from 'antd';
import { SelectProps } from 'antd/es/select';
import { styled, createGlobalStyle, css } from 'styled-components';

import { mq } from '@/styles/media.styled';

import { TSizeHeightSelect } from './types';

export const Wrapper = styled.div`
    .title-select {
        font-size: 14px;
        margin-bottom: 8px;
        color: #fff;
    }

    .error {
        color: #fd000f;
        font-size: 1.4rem;
        padding: 1rem;
    }
`;

export const styledSelectText = css<Pick<SelectProps, 'disabled' | 'loading'>>`
    color: ${(props) =>
        props.disabled || props.loading ? props.theme.color.neutral300 : props.theme.color.neutral600};
    font-weight: 500;
    font-size: 14px;
    ${mq.lt.lg} {
        font-size: 16px;
    }
`;

export const SelectWrapper = styled(Select)<{ height?: TSizeHeightSelect }>`
    .ant-spin-dot {
        font-size: 25px !important;
    }

    .ant-select-selection-placeholder {
        font-weight: 400;
    }

    .ant-select-selection-item {
        ${styledSelectText};
    }

    &.ant-select-focused:not(.ant-select-disabled).ant-select:not(.ant-select-customize-input) .ant-select-selector {
        border-color: rgb(0 0 0 / 12%);
        box-shadow: none;
    }

    &.ant-select {
        width: 100%;
        height: auto;

        .ant-select-selection-search {
            font-weight: 400;

            input {
                ${styledSelectText};
                height: 100% !important;
            }
        }

        &.ant-select-multiple {
            .ant-select-selector {
                padding: 8px;

                .ant-select-selection-search {
                    max-width: 240px;
                    margin-inline-start: 4px;
                }

                .ant-select-selection-item {
                    margin-top: 4px;
                    margin-bottom: 4px;
                    margin-inline-end: 8px;
                }
            }
        }

        &:not(.ant-select-multiple) {
            .ant-select-selector {
                .ant-select-selection-item {
                    padding-inline-end: 22px;
                }
            }
        }

        .ant-select-selection-placeholder {
            ${mq.lt.lg} {
                font-size: 16px !important;
            }
        }

        .ant-select-selector {
            border-radius: 8px;
            min-height: ${(props) => (props.height === 'LG' ? '64px' : '44px')};
            outline: none;
            border: 0.5px solid rgb(0 0 0 / 12%);
            background-color: ${({ theme }) => theme.color.neutral200} !important;
            display: flex;
            align-items: center;
            height: auto;

            .ant-select-selection-item {
                font-size: 14px;
                display: flex;
                align-items: center;
                ${mq.lt.lg} {
                    font-size: 16px;
                }

                .anticon-plus {
                    display: none;
                }
            }

            > .ant-select-selection-item {
                display: inline-block !important;
            }
        }

        .ant-select-arrow {
            right: 5%;
            top: 50%;

            &:not(.ant-select-arrow-loading) {
                * {
                    fill: ${(props) => (props.placeholder || props.loading ? props.theme.color.neutral300 : undefined)};
                }

                svg {
                    margin-right: -8px;
                }
            }

            &.ant-select-arrow-loading {
                border-left: none;

                svg {
                    width: 20px;
                    height: 20px;
                    margin-right: -8px;
                }
            }
        }
    }

    .ant-select-clear {
        background-color: transparent;

        svg {
            color: ${(props) => (props.placeholder || props.loading ? props.theme.color.neutral300 : undefined)};
        }
    }
`;

export const SelectGlobal = createGlobalStyle`   
 .ant-select-dropdown {
        .ant-select-item-option-content {
            font-size: 14px !important;
          ${mq.lt.lg} {
            font-size: 16px !important;
          }
        }

        .not-found-block {
          font-size: 14px;
          ${mq.lt.lg} {
            font-size: 16px !important;
          }
        }
    }
`;
