import { ResourceService, TReadParamsProperties } from '@/shared/api';
import { axiosScClient } from '@/shared/api/sc';
import { MARKET_ORGANIZATION_ID } from '@/shared/constants/settings';

import { TOffice } from '../types';

export const SalePointsService = ResourceService('sale-points', axiosScClient);

export const getOffices = async (langId: number) => {
    const response = await SalePointsService.read<{ data: TOffice[] }, TReadParamsProperties>({
        params: {
            langId,
            organizationId: MARKET_ORGANIZATION_ID,
            'per-page': 500,
        },
    });

    return response.data;
};
