import { Slider } from 'antd';
import { styled } from 'styled-components';

export const Wrapper = styled(Slider)`
    margin-top: 14px;
    position: relative;
    z-index: 2;

    &.ant-slider {
        margin: 0;
        padding-block: 0;

        &:hover {
            .ant-slider-track {
                background-image: ${({ theme }) => theme.color.primary};
            }

            .ant-slider-rail {
                background-color: ${({ theme }) => theme.color.disabled};
            }
        }
    }

    .ant-slider-track {
        background-image: ${({ theme }) => theme.color.primary};
        height: 3px;
    }

    .ant-slider-rail {
        height: 3px;
        background-color: ${({ theme }) => theme.color.disabled};

        &:hover {
            background-color: ${({ theme }) => theme.color.disabled};
        }
    }

    .ant-slider-handle {
        width: 24px;
        height: 24px;
        border-radius: 50%;
        cursor: pointer;
        outline: none;
        margin-top: -13px;
        background: ${({ theme }) => theme.color.primary};

        &:hover {
            box-shadow: 0 0 0 20px rgb(255 255 255 / 10%);
        }

        &::after {
            display: none;
        }
    }
`;
