import { numWord } from '@/shared/lib/string';
import { TCalculatorData, TCalculatorProducts } from '@/shared/types/calculator';

import { EPawnshopCategoryType } from '../constants';

const init = (
    products: TCalculatorProducts[],
    amountEstimate: number,
    type: EPawnshopCategoryType,
): TCalculatorData => {
    let maxAmount = 0;
    let maxTerm = 0;
    let minAmount = 0;
    let stepAmount = 0;
    let stepTerm = 0;
    let minMaxAmount = 0;

    if (products.length) {
        minAmount = products[0].minAmount;
        maxAmount = products[0].maxAmount;
        maxTerm = products[0].maxTerm;
        stepAmount = products[0].stepAmount;
        stepTerm = products[0].stepTerm;
        minMaxAmount = products[0].maxAmount;
        for (let i = 1; i < products.length; i++) {
            minAmount = Math.min(minAmount, products[i].minAmount);
            maxAmount = Math.max(maxAmount, products[i].maxAmount);
            minMaxAmount = Math.min(minMaxAmount, products[i].maxAmount);
            maxTerm = Math.max(maxTerm, products[i].maxTerm);
            stepAmount = Math.min(stepAmount, products[i].stepAmount);
            stepTerm = Math.min(stepTerm, products[i].stepTerm);
        }
    }

    const selectAmount = +amountEstimate > 0 ? +amountEstimate : 50_000;
    const findKp = products.find((product) => selectAmount >= product.minAmount && product.maxAmount <= minMaxAmount);
    if (findKp) {
        maxTerm = findKp.maxTerm;
        stepAmount = findKp.stepAmount;
        stepTerm = findKp.stepTerm;
    }

    return {
        maxAmount,
        maxTerm,
        minAmount,
        minTerm: 1,
        selectAmount,
        selectTerm: getDefaultSelectTerm(findKp, type),
        stepAmount,
        stepTerm,
    };
};

const getDefaultSelectTerm = (selectKp: TCalculatorProducts | undefined, type: EPawnshopCategoryType) => {
    const defaultApplianceTerm = 10;
    const defaultJewelTerm = 45;
    let selectTerm = 0;
    if (type === EPawnshopCategoryType.APPLIANCE) {
        selectTerm = defaultApplianceTerm;
        if (selectKp) {
            selectTerm = selectKp.maxTerm >= defaultApplianceTerm ? defaultApplianceTerm : selectKp.maxTerm;
        }
    }

    if (type === EPawnshopCategoryType.JEWEL) {
        selectTerm = defaultJewelTerm;
        if (selectKp) {
            selectTerm = selectKp.maxTerm >= defaultApplianceTerm ? defaultJewelTerm : selectKp.maxTerm;
        }
    }
    return selectTerm;
};

const selectedKpBySum = (products: TCalculatorProducts[], amount: number): TCalculatorProducts => {
    const findKp = products.find((product) => amount >= product.minAmount && amount <= product.maxAmount);
    return findKp as TCalculatorProducts;
};

const numWordTerm = (term: number): string => numWord(term, ['день', 'дня', 'дней']);

const prepareSelectedAmount = (amount: number, products: TCalculatorProducts[]): number => {
    const findKp = selectedKpBySum(products, amount);
    return Math.floor(amount / findKp.stepAmount) * findKp.stepAmount;
};

export const overpaymentCalculator = {
    getDefaultSelectTerm,
    init,
    numWordTerm,
    prepareSelectedAmount,
    selectedKpBySum,
};
