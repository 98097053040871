import React from 'react';

import { useAppDispatch, useAppSelector, closeModal } from '@/store';

import { ModalContent } from './modal-content';

export const ModalsModule = (): JSX.Element => {
    const dispatch = useAppDispatch();

    const { content, hasShow, onClose, size, title, type } = useAppSelector((state) => state.siteModal);

    const handlerCloseModal = () => {
        if (typeof onClose === 'function') {
            onClose();
        }
        dispatch(closeModal());
    };

    if (hasShow) {
        return (
            <ModalContent
                content={content || <div />}
                onClose={handlerCloseModal}
                sizeModal={size}
                title={title}
                type={type}
            />
        );
    }

    return <div />;
};
