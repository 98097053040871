import { createAsyncThunk } from '@reduxjs/toolkit';
import { AxiosError } from 'axios';

import { getOffices } from '@/entities/department';

import { LangSiteId, TLangSite } from '@/shared/constants/settings';
import { customCaptureException } from '@/shared/lib/custom-capture-exception';

const loadSettings = createAsyncThunk('settings/loadSettings', async function (locale: TLangSite, { rejectWithValue }) {
    try {
        const langId = LangSiteId[locale as TLangSite];

        const response = await Promise.all([getOffices(langId)]);
        return { offices: response[0] };
    } catch (error) {
        customCaptureException(error, 'loadSettings');
        return rejectWithValue((error as AxiosError)?.response?.data);
    }
});

export { loadSettings };
