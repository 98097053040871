import { styled } from 'styled-components';

import { mq } from '@/styles/media.styled';

export const Wrapper = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;

    ${mq.lt.xl} {
        margin-top: 40px;
    }

    .title-calc-section {
        display: flex;
        flex-direction: column;

        .title-calc-bold {
            font-weight: 700;
            font-size: ${({ theme }) => theme.fontSize.xxl};
            line-height: 120%;

            ${mq.lt.md} {
                font-size: ${({ theme }) => theme.fontSize.lg};
            }
        }

        .title-calc-text {
            font-weight: 400;
            font-size: ${({ theme }) => theme.fontSize.xl};
            line-height: 120%;
            margin-top: 0.75rem;

            ${mq.lt.md} {
                font-size: ${({ theme }) => theme.fontSize.md};
            }
        }
    }

    .calc-main-section {
        display: flex;
        justify-content: space-between;
        width: 100%;
        gap: 120px;
        margin-top: 2.56rem;
        align-items: center;

        .calc-main-section {
            .ant-space-item {
                width: 100%;
            }
        }

        ${mq.lt.lg} {
            display: flex;
            flex-direction: column;
            align-items: center;
            height: 100%;
            margin-top: 1.5rem;
        }

        ${mq.lt.xl} {
            margin-top: 1.5rem;
            gap: 1rem;
        }
    }

    .calc-notation-title {
        font-weight: 400;
        font-size: ${({ theme }) => theme.fontSize.xl};
        line-height: 120%;
        margin-top: 1.5rem;
    }

    .calc-notation-text {
        font-weight: 400;
        font-size: ${({ theme }) => theme.fontSize.md};
        line-height: 120%;
        width: 100%;
        max-width: 537px;
        margin-top: 1.5rem;
    }

    .text-brand-green {
        color: ${({ theme }) => theme.color.primary};
        font-weight: 500;
    }

    .h-410 {
        height: 410px;
    }
`;
