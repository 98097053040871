/* - - - - ПРОСТЫЕ РЕГУЛЯРКИ - - - - */

/**
 * Регулярка для ввода числа
 */
export const REGEXP_TYPE_NUMERIC = new RegExp(/^\d+([.,])?(\d{0,2})?$/);

/**
 * Регулярка для ввода букв
 */
export const REGEXP_TYPE_WORDS = new RegExp(/[a-zA-Zа-яА-Я]+$/);

/**
 * Регулярка для ввода спецсимволов
 */
export const REGEXP_TYPE_WORDS_SPEC = new RegExp(/[\w\W]+$/);

/* - - - - КОМБИНИРОВАННЫЕ РЕГУЛЯРКИ - - - - */

/**
 * Регулярка для ввода числа и (или) букв
 */
export const REGEXP_TYPE_NUMERIC_AND_WORDS = new RegExp(/([a-zA-Zа-яА-Я]+)?(\s)?(\d+([.])?(\d{0,2})?)?/);

/**
 * Регулярка для ввода числа и (или) спецсимволов
 *
 * https://youtrack.dengigroup.kz/issue/MRKZ-17/Avtootsenka-Ispolzovanie-logiki-avtootsenki-na-kalkulyatore-8#focus=Comments-4-14669.0-0
 */
export const REGEXP_TYPE_NUMERIC_AND_WORDS_SPEC = REGEXP_TYPE_WORDS_SPEC;

/**
 * Регулярка для ввода букв и (или) спецсимволов
 *
 * https://youtrack.dengigroup.kz/issue/MRKZ-17/Avtootsenka-Ispolzovanie-logiki-avtootsenki-na-kalkulyatore-8#focus=Comments-4-14669.0-0
 */
export const REGEXP_TYPE_WORDS_AND_WORDS_SPEC = REGEXP_TYPE_WORDS_SPEC;

/**
 * Регулярка для ввода чисел и (или) букв и (или) спецсимволов
 *
 * https://youtrack.dengigroup.kz/issue/MRKZ-17/Avtootsenka-Ispolzovanie-logiki-avtootsenki-na-kalkulyatore-8#focus=Comments-4-14669.0-0
 */
export const REGEXP_TYPE_NUMERIC_AND_WORDS_AND_WORDS_SPEC = REGEXP_TYPE_WORDS_SPEC;
