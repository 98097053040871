import { Options as IntlMessageFormatOptions } from 'intl-messageformat/src/core';
import { useIntl } from 'react-intl';

export const useTranslate = () => {
    const { formatMessage } = useIntl();

    /* eslint-disable max-params */
    const t = (
        key: string,
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        values?: any,
        opts?: IntlMessageFormatOptions,
    ): string => {
        /* eslint-enable max-params */

        return formatMessage(
            {
                defaultMessage: key,
                id: key,
            },
            values,
            opts,
        );
    };

    return { t };
};
