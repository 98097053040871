import { Link } from 'react-scroll';
import { styled } from 'styled-components';

import { mq } from '@/styles/media.styled';

export const LinkWrapper = styled(Link)`
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    padding: 12px 16px;
    gap: 8px;
    min-height: 64px;
    background: linear-gradient(218.13deg, #63d07b 18.93%, #0bb6ba 94.11%);
    border-radius: 8px;
    border: none;
    color: ${({ theme }) => theme.color.secondary};
    font-weight: 500;
    font-size: ${({ theme }) => theme.fontSize.md};
    line-height: 120%;
    cursor: pointer;
    height: 100%;

    &:hover {
        color: ${({ theme }) => theme.color.secondary};
    }

    ${mq.lt.sm} {
        min-height: 48px;
    }
`;
