import { styled, css } from 'styled-components';

import { TStyledCheckboxSizeElement } from './types';

const sizeMedium = css`
    > div {
        &:first-child {
            margin-right: 10px;
            height: 16px;
            width: 16px;

            .checkmark {
                width: 16px;
                height: 16px;
                border-radius: 3px;

                &::after {
                    left: 5px;
                    top: 1px;
                    width: 5px;
                    height: 10px;
                    border-width: 0 2px 2px 0;
                }
            }
        }

        a,
        label {
            font-weight: 400;
            font-size: 16px;
            line-height: 120%;
            color: #fff !important;
            height: 100%;
        }
    }
`;

export const Wrapper = styled.div<{ size: TStyledCheckboxSizeElement }>`
    display: flex;
    align-items: center;

    > div {
        &:first-child {
            position: relative;

            input {
                position: absolute;
                opacity: 0;
                cursor: pointer;
                height: 0;
                width: 0;

                &:checked ~ .checkmark {
                    background-color: #fff;
                }

                &:checked ~ .checkmark::after {
                    display: block;
                }
            }

            .checkmark {
                position: absolute;
                top: 0;
                left: 0;
                background-color: #eee;

                &::after {
                    content: '';
                    position: absolute;
                    display: none;
                    border: solid #14ae51;
                    transform: rotate(45deg);
                }
            }
        }

        a,
        label {
            cursor: pointer;
        }
    }

    ${(props) => props.size === 'md' && sizeMedium};
`;
