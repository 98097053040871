import { styled } from 'styled-components';

import { mq } from '@/styles/media.styled';

export const Wrapper = styled.div`
    > div {
        .ant-space-item {
            > div {
                margin-top: 0;
            }
        }
    }

    .estimate-layout {
        padding-top: 32px;
    }

    .title-calc-section {
        text-align: center;
        width: 84%;
        margin: 0 auto;

        .title-calc-bold {
            font-size: 20px !important;
        }

        .title-calc-text {
            font-size: 16px !important;
            margin-bottom: 24px;
        }
    }

    .calc-main-section {
        flex-direction: column;
        margin-top: 0;

        .calc-main-section {
            margin-top: 0;
        }

        .ant-space-item {
            &:first-child {
                > div {
                    min-width: 100%;
                }
            }

            .gadget-image {
                display: flex !important;
                justify-content: center !important;

                img {
                    width: 100% !important;
                }
            }

            .calc-notation-title {
                font-size: 20px !important;
            }

            .calc-notation-title,
            .calc-notation-text {
                text-align: center;
                line-height: 120% !important;
                font-weight: 400 !important;
                ${mq.lt.lg} {
                    text-align: left;
                }
            }

            .calc-notation-text {
                font-size: 16px !important;
            }
        }
    }
`;
