import { css } from 'styled-components';

export const styledUtilityCss = css`
    .decor-underline {
        text-decoration: underline;
        text-underline-position: under;
    }

    .text-gray {
        color: grey;
    }

    .text-link {
        color: #0056b3 !important;
    }

    .text-red {
        color: #9f191f !important;
    }

    .text-primary {
        color: #73c795;
    }

    .text-yellow {
        color: #ffd975;
    }

    .text-success {
        color: #28a745 !important;
    }

    .text-center {
        text-align: center;
    }

    .gotham-med {
        font-weight: 500;
    }

    .gotham-bold {
        font-weight: 700;
    }

    .gilroy-light {
        font-weight: 300;
    }

    .gilroy-reg {
        font-weight: 400;
    }

    .gilroy-med {
        font-weight: 500;
    }

    .gilroy-semi {
        font-weight: 600;
    }

    .gilroy-extra {
        font-weight: 800;
    }

    .overflow-hidden {
        overflow: hidden;
    }

    .z-1 {
        z-index: 1;
    }

    .z-2 {
        z-index: 2;
    }

    .shadow-grey {
        box-shadow: 0.2px 3px 6px 0 rgb(147 148 148 / 27%);
    }

    .shadow-grey-top {
        box-shadow: 0 -4px 10px 1px rgb(0 0 0 / 20%);
    }

    .border-6 {
        border-radius: 6px;
    }

    .border-8 {
        border-radius: 8px;
    }

    .border-12 {
        border-radius: 12px;
    }

    .border-16 {
        border-radius: 16px;
    }

    .round-top-12 {
        border-radius: 12px 12px 0 0;
    }

    .border-lt {
        border-top-left-radius: 0;
    }

    .border-rt {
        border-top-right-radius: 0;
    }

    .border-lb {
        border-bottom-left-radius: 0;
    }

    .border-rb {
        border-bottom-right-radius: 0;
    }

    .bg-white {
        background-color: #fff !important;
    }

    .bg-disabled {
        background: #e5e5e5 !important;
    }

    .bg-light-grey {
        background-color: #f9f9fa !important;
    }

    .bg-blue {
        background-color: #3f7dea !important;
    }

    .opacity-5 {
        opacity: 0.5 !important;
    }

    .pointer {
        cursor: pointer;
    }

    .not-user-select {
        user-select: none;
    }

    .no-outline {
        outline: none;
    }

    .h-100 {
        height: 100%;
    }

    .w-100 {
        width: 100%;
    }

    .display-none {
        display: none;
    }

    .inline {
        display: inline;
    }

    .block {
        display: block;
    }

    .inline-block {
        display: inline-block;
    }

    .flex {
        display: flex;
    }

    .inline-flex {
        display: inline-flex;
    }

    .flex-column {
        flex-direction: column;
    }

    .flex-wrap {
        flex-wrap: wrap;
    }

    .flex-xs-column {
        flex-direction: column;
    }

    @media (width >= 480px) {
        .flex-xs-column {
            flex-direction: initial;
        }
    }

    .grow-0 {
        flex-grow: 0;
    }

    .grow-1 {
        flex-grow: 1;
    }

    .shrink-0 {
        flex-shrink: 0;
    }

    .shrink-1 {
        flex-shrink: 1;
    }

    .items-start {
        align-items: flex-start !important;
    }

    .items-end {
        align-items: flex-end !important;
    }

    .items-center {
        align-items: center !important;
    }

    .items-baseline {
        align-items: baseline !important;
    }

    .items-stretch {
        align-items: stretch !important;
    }

    .self-start {
        align-self: flex-start;
    }

    .self-end {
        align-self: flex-end;
    }

    .self-center {
        align-self: center;
    }

    .self-baseline {
        align-self: baseline;
    }

    .self-stretch {
        align-self: stretch;
    }

    .justify-start {
        justify-content: flex-start;
    }

    .justify-start-important {
        justify-content: flex-start !important;
    }

    .justify-end {
        justify-content: flex-end;
    }

    .justify-center {
        justify-content: center !important;
    }

    .justify-between {
        justify-content: space-between;
    }

    .justify-between-important {
        justify-content: space-between !important;
    }

    .justify-around {
        justify-content: space-around;
    }

    .justify-evenly {
        justify-content: space-evenly;
    }

    .content-start {
        align-content: flex-start;
    }

    .content-end {
        align-content: flex-end;
    }

    .content-center {
        align-content: center;
    }

    .content-between {
        align-content: space-between;
    }

    .content-around {
        align-content: space-around;
    }

    .content-stretch {
        align-content: stretch;
    }

    .relative {
        position: relative;
    }

    .absolute {
        position: absolute;
    }

    .fixed {
        position: fixed;
    }

    .sticky-top {
        position: sticky;
        top: 20px;
    }

    .left-align {
        text-align: left;
    }

    .center {
        text-align: center;
    }

    .right-align {
        text-align: right;
    }

    .justify {
        text-align: justify;
    }

    .text-10 {
        font-size: 1rem !important;
    }

    .text-11 {
        font-size: 1.1rem !important;
    }

    .text-12 {
        font-size: 1.2rem !important;
    }

    .text-13 {
        font-size: 1.3rem !important;
    }

    .text-14 {
        font-size: 1.4rem !important;
    }

    .text-15 {
        font-size: 1.5rem !important;
    }

    .text-16 {
        font-size: 1.6rem !important;
    }

    .text-17 {
        font-size: 1.7rem !important;
    }

    .text-18 {
        font-size: 1.8rem !important;
    }

    .text-20 {
        font-size: 2rem !important;
    }

    .text-22 {
        font-size: 2.2rem !important;
    }

    .text-24 {
        font-size: 2.4rem !important;
    }

    .text-25 {
        font-size: 2.5rem !important;
    }

    .text-26 {
        font-size: 2.6rem !important;
    }

    .text-27 {
        font-size: 2.7rem !important;
    }

    .text-28 {
        font-size: 2.8rem !important;
    }

    .text-33 {
        font-size: 3.3rem !important;
    }

    .text-38 {
        font-size: 3.8rem !important;
    }

    .text-35 {
        font-size: 3.5rem !important;
    }

    .text-50 {
        font-size: 5rem !important;
    }

    .line-12 {
        line-height: 1.2rem !important;
    }

    .line-14 {
        line-height: 1.4rem !important;
    }

    .line-16 {
        line-height: 1.6rem !important;
    }

    .line-18 {
        line-height: 1.8rem !important;
    }

    .line-20 {
        line-height: 2rem !important;
    }

    .line-22 {
        line-height: 2.2rem !important;
    }

    .line-23 {
        line-height: 2.3rem !important;
    }

    .line-24 {
        line-height: 2.4rem !important;
    }

    .line-26 {
        line-height: 2.6rem !important;
    }

    .line-27 {
        line-height: 2.7rem !important;
    }

    .line-28 {
        line-height: 2.8rem !important;
    }

    .line-30 {
        line-height: 3rem !important;
    }

    .line-50 {
        line-height: 5rem !important;
    }

    .p-0 {
        padding: 0 !important;
    }

    .p-1 {
        padding: 0.25rem !important;
    }

    .p-2 {
        padding: 0.5rem !important;
    }

    .p-3 {
        padding: 0.75rem !important;
    }

    .p-4 {
        padding: 1rem !important;
    }

    .p-5 {
        padding: 1.25rem !important;
    }

    .p-6 {
        padding: 1.5rem !important;
    }

    .p-7 {
        padding: 1.75rem !important;
    }

    .p-8 {
        padding: 2rem !important;
    }

    .p-9 {
        padding: 2.25rem !important;
    }

    .p-10 {
        padding: 2.5rem !important;
    }

    .p-11 {
        padding: 2.75rem !important;
    }

    .p-12 {
        padding: 3rem !important;
    }

    .p-13 {
        padding: 3.25rem !important;
    }

    .p-14 {
        padding: 3.5rem !important;
    }

    .p-15 {
        padding: 3.75rem !important;
    }

    .p-16 {
        padding: 4rem !important;
    }

    .p-17 {
        padding: 4.25rem !important;
    }

    .p-18 {
        padding: 4.5rem !important;
    }

    .p-19 {
        padding: 4.75rem !important;
    }

    .p-20 {
        padding: 5rem !important;
    }

    .m-0 {
        margin: 0 !important;
    }

    .m-1 {
        margin: 0.25rem !important;
    }

    .m-2 {
        margin: 0.5rem !important;
    }

    .m-3 {
        margin: 0.75rem !important;
    }

    .m-4 {
        margin: 1rem !important;
    }

    .m-5 {
        margin: 1.25rem !important;
    }

    .m-6 {
        margin: 1.5rem !important;
    }

    .m-7 {
        margin: 1.75rem !important;
    }

    .m-8 {
        margin: 2rem !important;
    }

    .m-10 {
        margin: 2.5rem !important;
    }

    .m-11 {
        margin: 2.75rem !important;
    }

    .m-12 {
        margin: 3rem !important;
    }

    .m-13 {
        margin: 3.25rem !important;
    }

    .m-14 {
        margin: 3.5rem !important;
    }

    .m-15 {
        margin: 3.75rem !important;
    }

    .m-16 {
        margin: 4rem !important;
    }

    .m-17 {
        margin: 4.25rem !important;
    }

    .m-18 {
        margin: 4.5rem !important;
    }

    .m-19 {
        margin: 4.75rem !important;
    }

    .m-20 {
        margin: 5rem !important;
    }

    .px-0 {
        padding-left: 0 !important;
        padding-right: 0 !important;
    }

    .px-1 {
        padding-left: 0.25rem !important;
        padding-right: 0.25rem !important;
    }

    .px-2 {
        padding-left: 0.5rem !important;
        padding-right: 0.5rem !important;
    }

    .px-3 {
        padding-left: 0.75rem !important;
        padding-right: 0.75rem !important;
    }

    .px-4 {
        padding-left: 1rem !important;
        padding-right: 1rem !important;
    }

    .px-5 {
        padding-left: 1.25rem !important;
        padding-right: 1.25rem !important;
    }

    .px-6 {
        padding-left: 1.5rem !important;
        padding-right: 1.5rem !important;
    }

    .px-7 {
        padding-left: 1.75rem !important;
        padding-right: 1.75rem !important;
    }

    .px-8 {
        padding-left: 2rem !important;
        padding-right: 2rem !important;
    }

    .px-9 {
        padding-left: 2.25rem !important;
        padding-right: 2.25rem !important;
    }

    .px-10 {
        padding-left: 2.5rem !important;
        padding-right: 2.5rem !important;
    }

    .px-11 {
        padding-left: 2.75rem !important;
        padding-right: 2.75rem !important;
    }

    .px-12 {
        padding-left: 3rem !important;
        padding-right: 3rem !important;
    }

    .px-13 {
        padding-left: 3.25rem !important;
        padding-right: 3.25rem !important;
    }

    .px-14 {
        padding-left: 3.5rem !important;
        padding-right: 3.5rem !important;
    }

    .px-15 {
        padding-left: 3.75rem !important;
        padding-right: 3.75rem !important;
    }

    .px-16 {
        padding-left: 4rem !important;
        padding-right: 4rem !important;
    }

    .px-17 {
        padding-left: 4.25rem !important;
        padding-right: 4.25rem !important;
    }

    .px-18 {
        padding-left: 4.5rem !important;
        padding-right: 4.5rem !important;
    }

    .px-19 {
        padding-left: 4.75rem !important;
        padding-right: 4.75rem !important;
    }

    .px-20 {
        padding-left: 5rem !important;
        padding-right: 5rem !important;
    }

    .py-0 {
        padding: 0 !important;
    }

    .py-1 {
        padding-top: 0.25rem !important;
        padding-bottom: 0.25rem !important;
    }

    .py-2 {
        padding-top: 0.5rem !important;
        padding-bottom: 0.5rem !important;
    }

    .py-3 {
        padding-top: 0.75rem !important;
        padding-bottom: 0.75rem !important;
    }

    .py-4 {
        padding-top: 1rem !important;
        padding-bottom: 1rem !important;
    }

    .py-5 {
        padding-top: 1.25rem !important;
        padding-bottom: 1.25rem !important;
    }

    .py-6 {
        padding-top: 1.5rem !important;
        padding-bottom: 1.5rem !important;
    }

    .py-7 {
        padding-top: 1.75rem !important;
        padding-bottom: 1.75rem !important;
    }

    .py-8 {
        padding-top: 2rem !important;
        padding-bottom: 2rem !important;
    }

    .py-9 {
        padding-top: 2.25rem !important;
        padding-bottom: 2.25rem !important;
    }

    .py-10 {
        padding-top: 2.5rem !important;
        padding-bottom: 2.5rem !important;
    }

    .py-11 {
        padding-top: 2.75rem !important;
        padding-bottom: 2.75rem !important;
    }

    .py-12 {
        padding-top: 3rem !important;
        padding-bottom: 3rem !important;
    }

    .py-13 {
        padding-top: 3.25rem !important;
        padding-bottom: 3.25rem !important;
    }

    .py-14 {
        padding-top: 3.5rem !important;
        padding-bottom: 3.5rem !important;
    }

    .py-15 {
        padding-top: 3.75rem !important;
        padding-bottom: 3.75rem !important;
    }

    .py-16 {
        padding-top: 4rem !important;
        padding-bottom: 4rem !important;
    }

    .py-17 {
        padding-top: 4.25rem !important;
        padding-bottom: 4.25rem !important;
    }

    .py-18 {
        padding-top: 4.5rem !important;
        padding-bottom: 4.5rem !important;
    }

    .py-19 {
        padding-top: 4.75rem !important;
        padding-bottom: 4.75rem !important;
    }

    .py-20 {
        padding-top: 5rem !important;
        padding-bottom: 5rem !important;
    }

    .pt-0 {
        padding-top: 0 !important;
    }

    .pt-1 {
        padding-top: 0.25rem !important;
    }

    .pt-2 {
        padding-top: 0.5rem !important;
    }

    .pt-3 {
        padding-top: 0.75rem !important;
    }

    .pt-4 {
        padding-top: 1rem !important;
    }

    .pt-5 {
        padding-top: 1.25rem !important;
    }

    .pt-6 {
        padding-top: 1.5rem !important;
    }

    .pt-7 {
        padding-top: 1.75rem !important;
    }

    .pt-8 {
        padding-top: 2rem !important;
    }

    .pt-9 {
        padding-top: 2.25rem !important;
    }

    .pt-10 {
        padding-top: 2.5rem !important;
    }

    .pt-11 {
        padding-top: 2.75rem !important;
    }

    .pt-12 {
        padding-top: 3rem !important;
    }

    .pt-13 {
        padding-top: 3.25rem !important;
    }

    .pt-14 {
        padding-top: 3.5rem !important;
    }

    .pt-15 {
        padding-top: 3.75rem !important;
    }

    .pt-16 {
        padding-top: 4rem !important;
    }

    .pt-17 {
        padding-top: 4.25rem !important;
    }

    .pt-18 {
        padding-top: 4.5rem !important;
    }

    .pt-19 {
        padding-top: 4.75rem !important;
    }

    .pt-20 {
        padding-top: 5rem !important;
    }

    .pb-0 {
        padding-bottom: 0 !important;
    }

    .pb-1 {
        padding-bottom: 0.25rem !important;
    }

    .pb-2 {
        padding-bottom: 0.5rem !important;
    }

    .pb-3 {
        padding-bottom: 0.75rem !important;
    }

    .pb-4 {
        padding-bottom: 1rem !important;
    }

    .pb-5 {
        padding-bottom: 1.25rem !important;
    }

    .pb-6 {
        padding-bottom: 1.5rem !important;
    }

    .pb-7 {
        padding-bottom: 1.75rem !important;
    }

    .pb-8 {
        padding-bottom: 2rem !important;
    }

    .pb-9 {
        padding-bottom: 2.25rem !important;
    }

    .pb-10 {
        padding-bottom: 2.5rem !important;
    }

    .pb-11 {
        padding-bottom: 2.75rem !important;
    }

    .pb-12 {
        padding-bottom: 3rem !important;
    }

    .pb-13 {
        padding-bottom: 3.25rem !important;
    }

    .pb-14 {
        padding-bottom: 3.5rem !important;
    }

    .pb-15 {
        padding-bottom: 3.75rem !important;
    }

    .pb-16 {
        padding-bottom: 4rem !important;
    }

    .pb-17 {
        padding-bottom: 4.25rem !important;
    }

    .pb-18 {
        padding-bottom: 4.5rem !important;
    }

    .pb-19 {
        padding-bottom: 4.75rem !important;
    }

    .pb-20 {
        padding-bottom: 5rem !important;
    }

    .pl-0 {
        padding-left: 0 !important;
    }

    .pl-1 {
        padding-left: 0.25rem !important;
    }

    .pl-2 {
        padding-left: 0.5rem !important;
    }

    .pl-3 {
        padding-left: 0.75rem !important;
    }

    .pl-4 {
        padding-left: 1rem !important;
    }

    .pl-5 {
        padding-left: 1.25rem !important;
    }

    .pl-6 {
        padding-left: 1.5rem !important;
    }

    .pl-7 {
        padding-left: 1.75rem !important;
    }

    .pl-8 {
        padding-left: 2rem !important;
    }

    .pl-9 {
        padding-left: 2.25rem !important;
    }

    .pl-10 {
        padding-left: 2.5rem !important;
    }

    .pl-11 {
        padding-left: 2.75rem !important;
    }

    .pl-12 {
        padding-left: 3rem !important;
    }

    .pl-13 {
        padding-left: 3.25rem !important;
    }

    .pl-14 {
        padding-left: 3.5rem !important;
    }

    .pl-15 {
        padding-left: 3.75rem !important;
    }

    .pl-16 {
        padding-left: 4rem !important;
    }

    .pl-17 {
        padding-left: 4.25rem !important;
    }

    .pl-18 {
        padding-left: 4.5rem !important;
    }

    .pl-19 {
        padding-left: 4.75rem !important;
    }

    .pl-20 {
        padding-left: 5rem !important;
    }

    .pr-0 {
        padding-right: 0 !important;
    }

    .pr-1 {
        padding-right: 0.25rem !important;
    }

    .pr-2 {
        padding-right: 0.5rem !important;
    }

    .pr-3 {
        padding-right: 0.75rem !important;
    }

    .pr-4 {
        padding-right: 1rem !important;
    }

    .pr-5 {
        padding-right: 1.25rem !important;
    }

    .pr-6 {
        padding-right: 1.5rem !important;
    }

    .pr-7 {
        padding-right: 1.75rem !important;
    }

    .pr-8 {
        padding-right: 2rem !important;
    }

    .pr-9 {
        padding-right: 2.25rem !important;
    }

    .pr-10 {
        padding-right: 2.5rem !important;
    }

    .pr-11 {
        padding-right: 2.75rem !important;
    }

    .pr-12 {
        padding-right: 3rem !important;
    }

    .pr-13 {
        padding-right: 3.25rem !important;
    }

    .pr-14 {
        padding-right: 3.5rem !important;
    }

    .pr-15 {
        padding-right: 3.75rem !important;
    }

    .pr-16 {
        padding-right: 4rem !important;
    }

    .pr-17 {
        padding-right: 4.25rem !important;
    }

    .pr-18 {
        padding-right: 4.5rem !important;
    }

    .pr-19 {
        padding-right: 4.75rem !important;
    }

    .pr-20 {
        padding-right: 5rem !important;
    }

    .mx-auto {
        margin: 0 auto;
    }

    .ml-auto {
        margin-left: auto;
    }

    .mr-auto {
        margin-right: auto;
    }

    .mt-auto {
        margin-top: auto;
    }

    .mb-auto {
        margin-bottom: auto;
    }

    .mt-0 {
        margin-top: 0 !important;
    }

    .mt-1 {
        margin-top: 0.25rem !important;
    }

    .mt-2 {
        margin-top: 0.5rem !important;
    }

    .mt-3 {
        margin-top: 0.75rem !important;
    }

    .mt-4 {
        margin-top: 1rem !important;
    }

    .mt-5 {
        margin-top: 1.25rem !important;
    }

    .mt-6 {
        margin-top: 1.5rem !important;
    }

    .mt-7 {
        margin-top: 1.75rem !important;
    }

    .mt-8 {
        margin-top: 2rem !important;
    }

    .mt-9 {
        margin-top: 2.25rem !important;
    }

    .mt-10 {
        margin-top: 2.5rem !important;
    }

    .mt-11 {
        margin-top: 2.75rem !important;
    }

    .mt-12 {
        margin-top: 3rem !important;
    }

    .mt-13 {
        margin-top: 3.25rem !important;
    }

    .mt-14 {
        margin-top: 3.5rem !important;
    }

    .mt-15 {
        margin-top: 3.75rem !important;
    }

    .mt-16 {
        margin-top: 4rem !important;
    }

    .mt-17 {
        margin-top: 4.25rem !important;
    }

    .mt-18 {
        margin-top: 4.5rem !important;
    }

    .mt-19 {
        margin-top: 4.75rem !important;
    }

    .mt-20 {
        margin-top: 5rem !important;
    }

    .my-0 {
        margin-top: 0 !important;
        margin-bottom: 0 !important;
    }

    .my-1 {
        margin-top: 0.25rem !important;
        margin-bottom: 0.25rem !important;
    }

    .my-2 {
        margin-top: 0.5rem !important;
        margin-bottom: 0.5rem !important;
    }

    .my-3 {
        margin-top: 0.75rem !important;
        margin-bottom: 0.75rem !important;
    }

    .my-4 {
        margin-top: 1rem !important;
        margin-bottom: 1rem !important;
    }

    .my-5 {
        margin-top: 1.25rem !important;
        margin-bottom: 1.25rem !important;
    }

    .my-6 {
        margin-top: 1.5rem !important;
        margin-bottom: 1.5rem !important;
    }

    .my-7 {
        margin-top: 1.75rem !important;
        margin-bottom: 1.75rem !important;
    }

    .my-8 {
        margin-top: 2rem !important;
        margin-bottom: 2rem !important;
    }

    .my-9 {
        margin-top: 2.25rem !important;
        margin-bottom: 2.25rem !important;
    }

    .my-10 {
        margin-top: 2.5rem !important;
        margin-bottom: 2.5rem !important;
    }

    .my-11 {
        margin-top: 2.75rem !important;
        margin-bottom: 2.75rem !important;
    }

    .my-12 {
        margin-top: 3rem !important;
        margin-bottom: 3rem !important;
    }

    .my-13 {
        margin-top: 3.25rem !important;
        margin-bottom: 3.25rem !important;
    }

    .my-14 {
        margin-top: 3.5rem !important;
        margin-bottom: 3.5rem !important;
    }

    .my-15 {
        margin-top: 3.75rem !important;
        margin-bottom: 3.75rem !important;
    }

    .my-16 {
        margin-top: 4rem !important;
        margin-bottom: 4rem !important;
    }

    .my-17 {
        margin-top: 4.25rem !important;
        margin-bottom: 4.25rem !important;
    }

    .my-18 {
        margin-top: 4.5rem !important;
        margin-bottom: 4.5rem !important;
    }

    .my-19 {
        margin-top: 4.75rem !important;
    }

    .my-20 {
        margin-top: 5rem !important;
        margin-bottom: 5rem !important;
    }

    .mx-0 {
        margin-left: 0 !important;
        margin-right: 0 !important;
    }

    .mx-1 {
        margin-left: 0.25rem !important;
        margin-right: 0.25rem !important;
    }

    .mx-2 {
        margin-left: 0.5rem !important;
        margin-right: 0.5rem !important;
    }

    .mx-3 {
        margin-left: 0.75rem !important;
        margin-right: 0.75rem !important;
    }

    .mx-4 {
        margin-left: 1rem !important;
        margin-right: 1rem !important;
    }

    .mx-5 {
        margin-left: 1.25rem !important;
        margin-right: 1.25rem !important;
    }

    .mx-6 {
        margin-left: 1.5rem !important;
        margin-right: 1.5rem !important;
    }

    .mx-7 {
        margin-left: 1.75rem !important;
        margin-right: 1.75rem !important;
    }

    .mx-8 {
        margin-left: 2rem !important;
        margin-right: 2rem !important;
    }

    .mx-9 {
        margin-left: 2.25rem !important;
        margin-right: 2.25rem !important;
    }

    .mx-10 {
        margin-left: 2.5rem !important;
        margin-right: 2.5rem !important;
    }

    .mx-11 {
        margin-left: 2.75rem !important;
        margin-right: 2.75rem !important;
    }

    .mx-12 {
        margin-left: 3rem !important;
        margin-right: 3rem !important;
    }

    .mx-13 {
        margin-left: 3.25rem !important;
        margin-right: 3.25rem !important;
    }

    .mx-14 {
        margin-left: 3.5rem !important;
        margin-right: 3.5rem !important;
    }

    .mx-15 {
        margin-left: 3.75rem !important;
        margin-right: 3.75rem !important;
    }

    .mx-16 {
        margin-left: 4rem !important;
        margin-right: 4rem !important;
    }

    .mx-17 {
        margin-left: 4.25rem !important;
        margin-right: 4.25rem !important;
    }

    .mx-18 {
        margin-left: 4.5rem !important;
        margin-right: 4.5rem !important;
    }

    .mx-19 {
        margin-left: 4.75rem !important;
        margin-right: 4.75rem !important;
    }

    .mx-20 {
        margin-left: 5rem !important;
        margin-right: 5rem !important;
    }

    .mb-0 {
        margin-bottom: 0 !important ;
    }

    .mb-1 {
        margin-bottom: 0.25rem !important;
    }

    .mb-2 {
        margin-bottom: 0.5rem !important;
    }

    .mb-3 {
        margin-bottom: 0.75rem !important;
    }

    .mb-4 {
        margin-bottom: 1rem !important;
    }

    .mb-5 {
        margin-bottom: 1.25rem !important;
    }

    .mb-6 {
        margin-bottom: 1.5rem !important;
    }

    .mb-7 {
        margin-bottom: 1.75rem !important;
    }

    .mb-8 {
        margin-bottom: 2rem !important;
    }

    .mb-9 {
        margin-bottom: 2.25rem !important;
    }

    .mb-10 {
        margin-bottom: 2.5rem !important;
    }

    .mb-11 {
        margin-bottom: 2.75rem !important;
    }

    .mb-12 {
        margin-bottom: 3rem !important;
    }

    .mb-13 {
        margin-bottom: 3.25rem !important;
    }

    .mb-14 {
        margin-bottom: 3.5rem !important;
    }

    .mb-15 {
        margin-bottom: 3.75rem !important;
    }

    .mb-16 {
        margin-bottom: 4rem !important;
    }

    .mb-17 {
        margin-bottom: 4.25rem !important;
    }

    .mb-18 {
        margin-bottom: 4.5rem !important;
    }

    .mb-19 {
        margin-bottom: 4.75rem !important;
    }

    .mb-20 {
        margin-bottom: 5rem !important;
    }

    .mb-30 {
        margin-bottom: 6.9rem;
    }

    .mt-xs-5 {
        margin-top: 1.25rem;
    }

    @media (width >= 480px) {
        .mt-xs-5 {
            margin-top: 0;
        }
    }

    .ml-0 {
        margin-left: 0 !important;
    }

    .ml-1 {
        margin-left: 0.25rem !important;
    }

    .ml-2 {
        margin-left: 0.5rem !important;
    }

    .ml-3 {
        margin-left: 0.75rem !important;
    }

    .ml-4 {
        margin-left: 1rem !important;
    }

    .ml-5 {
        margin-left: 1.25rem !important;
    }

    .ml-6 {
        margin-left: 1.5rem !important;
    }

    .ml-7 {
        margin-left: 1.75rem !important;
    }

    .ml-8 {
        margin-left: 2rem !important;
    }

    .ml-9 {
        margin-left: 2.25rem !important;
    }

    .ml-10 {
        margin-left: 2.5rem !important;
    }

    .ml-11 {
        margin-left: 2.75rem !important;
    }

    .ml-12 {
        margin-left: 3rem !important;
    }

    .ml-13 {
        margin-left: 3.25rem !important;
    }

    .ml-14 {
        margin-left: 3.5rem !important;
    }

    .ml-15 {
        margin-left: 3.75rem !important;
    }

    .ml-16 {
        margin-left: 4rem !important;
    }

    .ml-17 {
        margin-left: 4.25rem !important;
    }

    .ml-18 {
        margin-left: 4.5rem !important;
    }

    .ml-19 {
        margin-left: 4.75rem !important;
    }

    .ml-20 {
        margin-left: 5rem !important;
    }

    .mr-0 {
        margin-right: 0 !important;
    }

    .mr-1 {
        margin-right: 0.25rem !important;
    }

    .mr-2 {
        margin-right: 0.5rem !important;
    }

    .mr-3 {
        margin-right: 0.75rem !important;
    }

    .mr-4 {
        margin-right: 1rem !important;
    }

    .mr-5 {
        margin-right: 1.25rem !important;
    }

    .mr-6 {
        margin-right: 1.5rem !important;
    }

    .mr-7 {
        margin-right: 1.75rem !important;
    }

    .mr-8 {
        margin-right: 2rem !important;
    }

    .mr-9 {
        margin-right: 2.25rem !important;
    }

    .mr-10 {
        margin-right: 2.5rem !important;
    }

    .mr-11 {
        margin-right: 2.75rem !important;
    }

    .mr-12 {
        margin-right: 3rem !important;
    }

    .mr-13 {
        margin-right: 3.25rem !important;
    }

    .mr-14 {
        margin-right: 3.5rem !important;
    }

    .mr-15 {
        margin-right: 3.75rem !important;
    }

    .mr-16 {
        margin-right: 4rem !important;
    }

    .mr-17 {
        margin-right: 4.25rem !important;
    }

    .mr-18 {
        margin-right: 4.5rem !important;
    }

    .mr-19 {
        margin-right: 4.75rem !important;
    }

    .mr-20 {
        margin-right: 5rem !important;
    }

    .mr-28 {
        margin-right: 6.3rem !important;
    }
`;
