import React, { FC } from 'react';

import { Loader } from '@/shared/ui/loader';

import * as Styled from './styled';

type TProps = {
    color?: string;
};

export const LoaderBack: FC<TProps> = ({ color }) => {
    return (
        <Styled.Wrapper>
            <Loader isDots svgProps={color ? { fill: color } : {}} />
        </Styled.Wrapper>
    );
};
