import { TPawnshopSetting } from '@/shared/types/pawnshop-settings';

import { ResourceService } from '../baseServices/resource-service';

import { axiosSiteClient } from './axios';

export const PawnshopSettingsService = ResourceService('pawnshop-settings', axiosSiteClient);

export const getPawnshopSettingByName = async (name: string): Promise<TPawnshopSetting> => {
    return await PawnshopSettingsService.readOne<TPawnshopSetting>({ identifier: name });
};
