import { YMaps, Map } from '@pbe/react-yandex-maps';
import { YMapsApi } from '@pbe/react-yandex-maps/typings/util/typing';
import React, { FC, ReactNode } from 'react';

type TProps = {
    center?: TCoordinates;
    children?: ReactNode;
    height?: string;
    modules?: string[];
    onLoad?: (ymaps: YMapsApi) => void;
    width?: string;
    zoom?: number;
};

export const YandexMap: FC<TProps> = (props) => {
    const {
        center = {
            lat: 43.238949,
            lon: 76.889709,
        },
        children,
        height = '100%',
        modules,
        onLoad,
        width = '100%',
        zoom = 13,
    } = props;

    const centerCoord = center?.lat ? [Number(center.lat), Number(center.lon)] : [];

    return (
        <YMaps>
            <Map
                height={height}
                modules={modules}
                onLoad={(ymaps) => onLoad && onLoad(ymaps)}
                state={{
                    // behaviors: [ "disable('scrollZoom')" ],
                    center: centerCoord,
                    zoom,
                }}
                width={width}
            >
                {children}
            </Map>
        </YMaps>
    );
};

export type { YMapsApi };
